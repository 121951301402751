import { Grid } from "@material-ui/core";
import { Alert } from "@mui/material";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import CustomInput from "components/CustomInput";
import DatePicker from "components/DatePicker";
import DropdownFilter from "components/DropdownFilter";
import { TimeField } from "components/TimeField/TimeField";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { reservationPickupTypeList, reservationStatusList } from "features/Reservation/reservationConsts";
import { IconDollar } from "icons";
import _ from "lodash";
import { useGetAllVendorsQuery } from "features/vendors/vendorSlice";
import { useState } from "react";
import ReservationCarrierInfo from "./ReservationCarrierInfo/ReservationCarrierInfo";
import DocumentForReservation from "./ReservationDocuments/DocumentForReservation";
import { ReservationFormStyles } from "./ReservationForm/ReservationFormStyles";
import moment from "moment";
import { arrayToObject } from "utils/object-util";

const ReservationDetails = ({
    reservationDetails,
    setReservationDetails,
    search,
    isLoading = false,
    errors, setErrors,
    setHasUnsavedChanges,
}) => {
    const classes = ReservationFormStyles();

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    clients = clients || [];

    let { data: vendors, error: vendorsError, isLoading: isLoadingVendors } = useGetAllVendorsQuery();
    vendors = vendors || [];

    const [nameDoc, setNameDoc] = useState({ achDoc: reservationDetails.achDocUrl, agreementDoc:  reservationDetails.agreementDocUrl, coiDoc:  reservationDetails.coiDocUrl })

    const [details, setDetails] = useState(reservationDetails)

    const clientAccounts = _.flatten(clients.map(c => c.clientAccounts));
    const clientAccountsLookup=arrayToObject(clientAccounts,'id')
    const uploadAttachment = async (type, file,backup) => {
        let url = URL.createObjectURL(file);
        let fileBase64 = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            fileBase64 = reader.result.toString().replace(/^data:(.*,)?/, '');
            reservationDetails[type] = {
                filedata: fileBase64,
                fileType: file?.type,
                fileName: file?.name
            }
            var object = nameDoc;
            object[type] = file.name;
    }
        const backupFlag=type+"Backup"
        reservationDetails[backupFlag]=backup
        setNameDoc({ ...nameDoc, [type]: url })
    };
    const onDelete = (type) => {
        reservationDetails[type] = null
        const deleteFlag=type+"Url"
        reservationDetails[deleteFlag] = null
        setNameDoc({ ...nameDoc, [type]: null })
    }
    
    const onChangeValue = (key, val, level = 'reservationDetails') => {
        if (level === 'reservationDetails') {
            setReservationDetails({ ...reservationDetails, [key]: val });
        } else if (level === 'carrierInfo') {
            setReservationDetails({ ...reservationDetails, carrierInfo: { ...reservationDetails?.carrierInfo, [key]: val }});
        }

        setErrors({
            ...errors,
            [key]: val ? undefined : errors[key],
            ['pickupDateInPast']: key === 'pickupDate' && val < moment().format("YYYY-MM-DD") 
                ? "The date selected is in the past" 
                : undefined
        });

        setHasUnsavedChanges(true);
    };
    
    const onChangePocField = (key, val, pocIndex) => {
        const pocArray = reservationDetails?.carrierInfo?.pocInfo?.length 
            ? [...reservationDetails.carrierInfo.pocInfo] : [];

        pocArray[pocIndex] = { ...pocArray[pocIndex], [key]: val, 'isPrimary': pocIndex === 0 };

        setReservationDetails({ ...reservationDetails, carrierInfo: { ...reservationDetails?.carrierInfo,
                pocInfo: pocArray?.length ? pocArray : [{ [key]: val, 'isPrimary': pocIndex === 0 }]
            }
        });
        
        setErrors({ ...errors, [`${key}${pocIndex}`]: val ? undefined : errors[`${key}${pocIndex}`] });
        setHasUnsavedChanges(true);
    };

    // const onSearch = async () => {
    //     setReservationDetails({ ...reservationDetails, ...details });
    //     await search(details);
    // }

    const isNew = !reservationDetails?.id;

    const idImg=isNew?"isNew":reservationDetails?.id;

    const displayError = (field, severity='error') => {
        return !!errors?.[field] && <Grid className={classes.alertWrapper}>
            <Alert severity={severity} >
                {errors?.[field]}
            </Alert>
        </Grid>
    }

    return (
        <>
            <div className={classes.sectionTitle}>
                Reservation Details
            </div>
            <Grid container alignItems="center" spacing={2}>
                <Grid container direction='row' spacing={2}>
                    <Grid item xs={6}>
                        <Grid container direction="column">
                            <Grid item className={classes.field}>
                                <div className={classes.label}>
                                    Client
                                </div>
                                {reservationDetails?.reservationVehicles?.length>0?<CustomInput
                                    id="clientAccountId"
                                    value={clientAccountsLookup[reservationDetails?.clientAccountId]?.name}
                                    
                                /> :<CustomInput
                                    id="clientAccountId"
                                    value={reservationDetails?.clientAccountId}
                                    elementType="dropdown"
                                    onChange={val => onChangeValue("clientAccountId", +val)}
                                    values={clientAccounts}
                                    showEmpty
                                    required
                                />}
                            </Grid>
                            {displayError('clientAccountId')}
                        </Grid>
                    </Grid>
                    <Grid item xs={6} className={classes.field}>
                        <div className={classes.label}>
                            Status
                        </div>
                        <DropdownFilter
                            id="status"
                            value={reservationDetails?.status}
                            onSelect={(val) => onChangeValue("status", val.id)}
                            elementType="dropdown"
                            values={reservationStatusList}
                            withTypeAhead
                            shrink={reservationDetails?.status}
                        />
                    </Grid>
                </Grid>

                <Grid container direction='row' spacing={2} style={{ padding: '.5em' }}>
                    <Grid item xs={4}>
                        <Grid container direction="column">
                            <Grid item className={classes.field}>
                                <div className={classes.label}>
                                    Estimated pickup date
                                </div>
                                <DatePicker
                                    id="pickupDate"
                                    value={reservationDetails?.pickupDate ? moment(reservationDetails?.pickupDate).format("YYYY-MM-DD") : null}
                                    onSelect={(val) => onChangeValue("pickupDate", val ? val : null)}
                                    required
                                    maxDate={reservationDetails?.deliveryDate ? moment(reservationDetails?.deliveryDate).format("YYYY-MM-DD") : null}
                                />
                            </Grid>
                            {displayError('pickupDate')}
                            {displayError('pickupDateInPast', 'warning')}
                        </Grid>
                    </Grid>
                    <Grid item xs={4} className={classes.field}>
                        <div className={classes.label}>
                            Estimated pickup time
                        </div>
                        <TimeField
                            id="pickupTime"
                            onChange={(val) => {
                                console.log(moment(new Date(val)).format('HH:mm:ss'));
                                console.log(val);
                                onChangeValue("pickupTime", moment(new Date(val)).format('HH:mm:ss'))
                            }}
                            //value={reservationDetails?.deliveryTime ?? null}
                            value={reservationDetails?.pickupTime ? new Date(new Date().setHours(...(reservationDetails?.pickupTime ?? '').split(':').map(Number))) : null}
                            required
                        />
                    </Grid>
                    <Grid item xs={4} className={classes.field}>
                        <div className={classes.label}>
                            Actual pick up date
                        </div>
                        <DatePicker
                            id="actualPickUpDate"
                            value={reservationDetails?.actualPickUpDate ? moment(reservationDetails?.actualPickUpDate).format("YYYY-MM-DD") : null}
                            onSelect={(val) => onChangeValue("actualPickUpDate", val ? val : null)}
                            minDate={moment(Date.now()).format("YYYY-MM-DD")}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={4} className={classes.field}>
                    <div className={classes.label}>
                        Estimated drop off date
                    </div>
                    <DatePicker
                        id="deliveryDate"
                        value={reservationDetails?.deliveryDate ? moment(reservationDetails?.deliveryDate).format("YYYY-MM-DD") : null}
                        onSelect={(val) => onChangeValue("deliveryDate", val ? val : null)}
                        required
                        minDate={reservationDetails?.pickupDate ? moment(reservationDetails?.pickupDate).format("YYYY-MM-DD") : moment(Date.now()).format("YYYY-MM-DD")}
                    />
                </Grid>
                <Grid item xs={4} className={classes.field}>
                    <div className={classes.label}>
                        Estimated drop off time
                    </div>
                    <TimeField
                        id="deliveryTime"
                        onChange={(val) => {
                            console.log(moment(new Date(val)).format('HH:mm:ss'));
                            console.log(val);
                            onChangeValue("deliveryTime", moment(new Date(val)).format('HH:mm:ss'))
                        }}
                        //value={reservationDetails?.deliveryTime ?? null}
                        value={reservationDetails?.deliveryTime ? new Date(new Date().setHours(...(reservationDetails?.deliveryTime ?? '').split(':').map(Number))) : null}
                        required
                    />
                </Grid>
                <Grid item xs={4} className={classes.field}>
                    <div className={classes.label}>
                        Actual drop off date
                    </div>
                    <DatePicker
                        id="actualDropOffDate"
                        value={reservationDetails?.actualDropOffDate ? moment(reservationDetails?.actualDropOffDate).format("YYYY-MM-DD") : null}
                        onSelect={(val) => onChangeValue("actualDropOffDate", val ? val : null)}
                        minDate={reservationDetails?.actualPickUpDate ? moment(reservationDetails?.actualPickUpDate).format("YYYY-MM-DD") : moment(Date.now()).format("YYYY-MM-DD")}
                    />
                </Grid>
                <Grid item xs={6} className={classes.field}>
                    <div className={classes.label}>
                        Pickup location
                    </div>
                    <DropdownFilter
                        id="pickupLocationId"
                        value={reservationDetails?.pickupLocationId}
                        onSelect={(val) => onChangeValue("pickupLocationId", val.id)}
                        elementType="dropdown"
                        values={lots}
                        withTypeAhead
                        shrink={reservationDetails?.pickupLocationId}
                    />
                </Grid>
                <Grid item xs={6} className={classes.field}>
                    <div className={classes.label}>
                        Dropoff location
                    </div>
                    <DropdownFilter
                        id="deliveryLocationId"
                        value={reservationDetails?.deliveryLocationId}
                        onSelect={(val) => onChangeValue("deliveryLocationId", val.id)}
                        elementType="dropdown"
                        values={lots}
                        withTypeAhead
                        shrink={reservationDetails?.deliveryLocationId}
                    />
                </Grid>

                <Grid container direction='row' spacing={2} style={{ padding: '.5em' }}>
                    <Grid item xs={4} className={classes.field}>
                        <Grid container direction='column'>
                            <div className={classes.label}>
                                Insurance expiry date
                            </div>
                            <DatePicker
                                id="insuranceExpiryDate"
                                value={reservationDetails?.insuranceExpiryDate ? moment(reservationDetails?.insuranceExpiryDate).format("YYYY-MM-DD") : null}
                                onSelect={(val) => onChangeValue("insuranceExpiryDate", val ? val : null)}
                            />
                            {displayError('insuranceExpiryDate')}
                        </Grid>
                    </Grid>
                    <Grid item xs={4} className={classes.field}>
                        <div className={classes.label}>
                            Pickup type
                        </div>
                        <DropdownFilter
                            id="pickupType"
                            value={reservationDetails?.pickupType}
                            onSelect={(val) => onChangeValue("pickupType", val.id)}
                            elementType="dropdown"
                            values={reservationPickupTypeList}
                            withTypeAhead
                            shrink={reservationDetails?.pickupType}
                        />
                    </Grid>
                    <Grid item xs={4} className={classes.field}>
                        <div className={classes.label}>
                            Rental rate
                        </div>
                            <CustomInput
                                value={reservationDetails?.rentalRate}
                                onChange={(val) => onChangeValue("rentalRate", val == "" ? null : val)}
                                icon={<IconDollar />}
                                elementType="input"
                                numberOnly
                            />
                    </Grid>
                </Grid>

                <ReservationCarrierInfo
                    reservationDetails={reservationDetails} 
                    setReservationDetails={setReservationDetails} 
                    onChangeValue={onChangeValue} 
                    onChangePocField={onChangePocField}
                    displayError={displayError}
                />
                
                <Grid item xs={12} className={classes.field}>
                    <DocumentForReservation 
                    onSave={(file,backup) => uploadAttachment("coiDoc", file,backup)} 
                    typeName="COI" 
                    img={{ url: nameDoc.coiDoc, id: idImg, "docName": "COI" ,type : 'pdf' }}
                    onDelete={(img) => onDelete("coiDoc")}
                    confirmBackup={true}
                    selectFile={nameDoc?.coiDoc}>
                    </DocumentForReservation>
                </Grid>
                <Authorize profile={permissionProfiles.RESERVATIONS.VIEW_BANKING_INFO}
                    readonlyComponent={<DocumentForReservation
                    onSave={(file) => uploadAttachment("achDoc", file)}
                    typeName="ACH Authorization Form"
                    permissionViewEditFile={false}
                    >
                </DocumentForReservation>}>
                <Grid item xs={12} className={classes.field}>
                    <DocumentForReservation
                        onSave={(file) => uploadAttachment("achDoc", file)}
                        typeName="ACH Authorization Form"
                        selectFile={nameDoc?.achDoc}
                        img={{ url: nameDoc.achDoc, id: idImg, "docName": "ACH Authorization Form" ,type : 'pdf'}} 
                        onDelete={(img) => onDelete("achDoc")}
                        confirmBackup={false}> 
                    </DocumentForReservation>
                </Grid>
                </Authorize>
                    <Grid item xs={12} className={classes.field}>
                        <DocumentForReservation onSave={(file,backup) => uploadAttachment("agreementDoc", file,backup)} typeName="Rental Agreement" 
                        selectFile={nameDoc?.agreementDoc }
                        img={{ url: nameDoc.agreementDoc, id: idImg, "docName": "Rental Agreement" ,type : 'pdf' }} 
                        onDelete={(img) => onDelete("agreementDoc")}
                        confirmBackup={true}
                        ></DocumentForReservation>
                    </Grid>
                    {displayError('docInvalid')}
            </Grid>
        </>
    )
};

export default ReservationDetails;
