import { createSlice } from "@reduxjs/toolkit";

export const vendorFiltersSlice = createSlice({
    name: 'vendorFilters',
    initialState: {
        searchVal: ''
    },
    reducers: {
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        }
    },
});

export const { setSearchVal } = vendorFiltersSlice.actions;
export default vendorFiltersSlice.reducer;