import { Grid } from "@material-ui/core";
import { ReservationEditAction } from "../NewReservation/ReservationEditAction";
import { DocumentationRequest } from "../ReservationActions/DocumentationRequest";
import { reservationColors } from "../ReservationStatusFilter/ReservationStatusFilter";
import { ReservationViewStyle } from "./style";
import { FlagAsset } from "features/vehicles/Flags/FlagAsset";
import { FlagEntityTypes } from 'features/flag/flagsConsts';
import { VendorContacts } from "../NewReservation/Components/ReservationCarrierInfo/VendorContacts";

export const ReservationSummary = ({ reservation }) => {
    const classes = ReservationViewStyle()

    const fields = [
        {
            title: '',
            fields: [
                {
                    value: `${reservation?.clientName} ${reservation?.subClientName ?? ''}`,
                    label: 'Client',
                },
                {
                    value: reservation?.carrierInfo?.name ?? '-',
                    label: 'DSP/Carrier',
                    component: !!reservation?.carrierInfo?.pocInfo?.length && <VendorContacts pocInfo={reservation?.carrierInfo?.pocInfo} smallIcon={true}/>
                },
                {
                    value: reservation?.formattedInsuranceExpiryDate ?? '-',
                    label: 'Insurance expiry date'
                },
                {
                    value: reservation?.totalQuantity ?? '-',
                    label: 'Quantity'
                },
                {
                    value: reservation?.formattedRentalRate ?? '-',
                    label: 'Rental rate'
                }
            ]
        },
        {  
            title: 'Pick-up',
            fields: [
                {
                    value: reservation?.pickupLotName ?? '-',
                    label: 'Pickup at'
                },
                {
                    value: reservation?.formattedPickupDate ?? '-',
                    label: 'Estimated pickup date'
                },
                {
                    value: reservation?.pickupTime ?? '-',
                    label: 'Estimated pickup time'
                },
                {
                    value: reservation?.formattedActualPickUpDate ?? '-',
                    label: 'Actual pick up date'
                },
                {
                    value: reservation?.pickupTypeName ?? '-',
                    label: 'Pickup Type'
                },
            ]
        },
        {
            title: 'Drop-off',
            fields: [
                {
                    value: reservation?.deliveryLotName ?? '-',
                    label: 'Drop off at'
                },
                {
                    value: reservation?.formattedDeliveryDate ?? '-',
                    label: 'Estimated drop off date'
                },
                {
                    value: reservation?.deliveryTime ?? '-',
                    label: 'Estimated drop off time'
                },
                {
                    value: reservation?.formattedActualDropOffDate ?? '-',
                    label: 'Actual drop off date'
                }
            ],
            noSeparator: true
        }
    ];

    return (
        <Grid container spacing={1} >
            <Grid item xs={12} alignItems="center" container spacing={1} justifyContent= 'flex-start' 
                className={classes.summaryStatus}
                style={{ borderColor: reservationColors?.[reservation.status], color: reservationColors?.[reservation.status] }}>
                <Grid item className={classes.summaryId}>{reservation?.resId}</Grid>
                <Grid item > {reservation?.statusName}</Grid>
                <Grid item style={{ marginLeft: 'auto' }}> 
                    <FlagAsset 
                        selectedRows={[reservation?.id]}
                        allData={[reservation]}
                        entityType={FlagEntityTypes.RESERVATION}
                    /></Grid>
            </Grid>
            {fields?.map((fieldGroup, index) => 
                <Grid container key={index} className={fieldGroup.noSeparator ? classes.section : classes.sectionBorder}>
                    <Grid container className={classes.sectionTitle}>
                        {fieldGroup.title}
                    </Grid>
                    {fieldGroup?.fields?.map((field, index) => 
                        <Grid container spacing={2} key={index} item xs={6} alignItems="center">
                            <Grid item className={classes.summaryLabel}>{field.label}</Grid>
                            <Grid item className={classes.summaryField}>{field.value}</Grid>
                            <Grid item className={classes.summaryComponent}>{field.component}</Grid>
                        </Grid>
                    )} 
                </Grid>
            )}
            <Grid container justifyContent="flex-end" alignItems="center">
                <DocumentationRequest reservation={reservation} />
                <Grid item justifyContent="flex-end">
                    <ReservationEditAction reservation={reservation} buttonVariant="outlined"  />
                </Grid>
            </Grid>
        </Grid>
    )
}