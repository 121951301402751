import { Grid, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Authorize from 'components/Authorize';
import { isAuthorized } from "components/Authorize/authorizeUtil";
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import DataTable from "components/DataTable/DataTable";
import { activityStatusById, activityStatuses, activityStatusesList } from "features/activity/activityConsts";
import { useGetAllActivityItemsQuery, useGetAllActivityTypesQuery, useGetMechanicalActivitiesQuery } from "features/activity/activitySlice";
import { useGetClientsWithConfigsQuery } from 'features/clients/clientConfigSlice';
import { useGetClientsQuery } from "features/clients/clientSlice";
import { FlagEntityTypes } from 'features/flag/flagsConsts';
import { useGetLotsQuery } from "features/lots/lotSlice";
import NotesTooltip from "features/notes/NotesTooltip";
import { NoteEntityTypes } from "features/notes/notesConsts";
import { useGetAllActivityLogNotesQuery } from "features/notes/notesSlice";
import { selectIsAccountManager, selectIsClient, selectIsOwner, selectUserClientId, userHasOneClient } from "features/user/userSlice";
import ApprovePendingApprovalActivity from "features/vehicles/Activities/ApproveActivity";
import ApprovePendingInvoiceActivity from "features/vehicles/Activities/ApproveActivity/ApprovePendingInvoiceActivity";
import UpdateActivityStatus from 'features/vehicles/Activities/ApproveActivity/UpdateActivityStatus';
import { FlagAsset } from 'features/vehicles/Flags/FlagAsset';
import EditActivityLog from 'features/vehicles/VehicleDetailsPage/EditActivityLog/EditActivityLog';
import useSortOptions from "hooks/useSortOptions";
import { IconEdit } from 'icons';
import { groupBy, orderBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import FilterService, { fieldTypes } from "services/filterService";
import { arrayToObject } from "utils/object-util";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import { ActivityPriceError } from "./ActivityPriceError";
import { ActivityStatus } from './ActivityStatus';
import { activityTableStyle } from './style';
import FormatService from 'services/formatService';
import { IconActivityDefault } from 'icons/mechanicals';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetServiceProvidersQuery } from 'features/serviceProvider/serviceProviderSlice';
import ActivityStatusAliasView from 'features/activityStatusAlias/ActivityStatusAliasView';
import { useGetAllAliasesQuery } from 'features/activity/activityStatusAliasSlice';
import _ from "lodash";

const ActivityListTable = ({
    status,
    clientId,
    lotId,
    allowActions = true,
    infoTextOnTable,
    additionalFilters = {}
}) => {
    const classes = activityTableStyle()

    const history = useHistory();

    const isClient = useSelector(selectIsClient);
    let isOwner = useSelector(selectIsOwner);
    let isAccountManager = useSelector(selectIsAccountManager);
    let userClientId = useSelector(selectUserClientId);
    let user = useSelector(state => state.oidc.user);
    let isUserLoaded = !!user;
    let hasOneClaim = useSelector(userHasOneClient);

    let { data: mechanicalActivities, error: mechanicalActivitiesError, isFetching: isLoadingMechanicalActivities } = useGetMechanicalActivitiesQuery();
    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let { data: activityTypes, error: activityTypesError, isFetching: isLoadingActivityTypes } = useGetAllActivityTypesQuery();
    let { data: activityNotes, error: activityNotesError, isFetching: isLoadingNotes, refetch: getNotes } = useGetAllActivityLogNotesQuery();
    let { data: clientsWithConfigs, error: clientsWithConfigsError, isFetching: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery(null, { skip: !isUserLoaded });
    let { data: serviceProviders, isLoading: isLoadingServiceProviders } = useGetServiceProvidersQuery();
    let { data: aliases, error: aliasError, isLoading: isLoadingAlias } = useGetAllAliasesQuery();

    clientsWithConfigs = clientsWithConfigs || [];

    const noteLookup = groupBy(activityNotes, 'entityId');

    const clientAccounts = _.flatten(clients?.map(c => c.clientAccounts));

    const defaultFilters = {
        activityStatus: { value: status },
        ...additionalFilters
    };
    clientId && (defaultFilters.clientId = { value: clientId })
    !!lotId?.length && (defaultFilters.lotId = { value: lotId })

    const [sortOptions, sortData] = useSortOptions();
    const [search, setSearch] = useState('');
    const [activityLogToEdit, setActivityLogToEdit] = useState();

    const [filters, setFilters] = useState({
        activityStatus: { value: status }
    });

    const clientConfigs = clientsWithConfigs?.find(c => c.id == userClientId);
    //const canSeeFlags = isOwner || isAccountManager || (isClient && clientConfigs?.config?.lotConfig?.seeFlags);

    const canSeeFlags = isAuthorized(permissionProfiles.DASHBOARD.MECHANICAL_DASHBOARD_CAN_SEE_FLAG) || (isClient && clientConfigs?.config?.lotConfig?.seeFlags);
    const canSeeNotes = isAuthorized(permissionProfiles.DASHBOARD.MECHANICAL_DASHBOARD_VIEW_NOTE);

    useEffect(() => {
        const searchedVin = new URLSearchParams(window.location.search).get('vin');
        if (searchedVin) {
            setSearch(searchedVin)
            setFilters(defaultFilters);
        }
    }, [window.location.search])

    let presentClientId = !!userClientId.length ? parseInt(userClientId) : defaultFilters?.clientId?.value ? parseInt(defaultFilters?.clientId?.value) : filters?.clientId?.value;

    useEffect(() => {
        presentClientId = !!userClientId.length ? parseInt(userClientId) : defaultFilters?.clientId?.value ? parseInt(defaultFilters?.clientId?.value) : filters?.clientId?.value;
    }, [userClientId, defaultFilters, filters])

    const filterConfig = [
        ...(hasOneClaim ? [] : [{ type: fieldTypes.TEXT, size: 2, title: 'Client', key: 'clientId', values: clients }]),
        { type: fieldTypes.LOT, size: 2, title: 'Lot', key: 'lotId', values: lots, multiple: true },
        { type: fieldTypes.DATERANGE, size: 2, title: 'Time Frame', key: 'activityDate', values: [] },
        { type: fieldTypes.ACTIVITY_TYPE, size: 2, title: 'Activity Type', key: 'activityItemId', values: activityItems },
        {
            type: fieldTypes.TEXT, size: 2, title: 'Status', key: 'activityStatus', values: activityStatusesList.map(status => ({
                id: status.id,
                name: aliases?.find(a => a.activityStatusId === status.id && (a.clientId === presentClientId))?.alias ?? status.name
            }))
        },
        ...(!serviceProviders?.length && isClient ? [] : [{ type: fieldTypes.TEXT, size: 2, title: 'Service Provider', key: 'serviceProviderId', values: serviceProviders }]),
        { type: fieldTypes.TEXT, size: 2, title: 'Created By External User', key: 'externalUser', values: [{ id: 'Yes', name: 'Yes' }, { id: 'No', name: 'No' }] },
    ];

    if (canSeeFlags) {
        filterConfig.push({
            type: fieldTypes.TEXT,
            size: 2,
            title: 'Flagged',
            key: 'flaggedStr',
            values: [{ id: 'Yes', name: 'Yes' }, { id: 'No', name: 'No' }]
        });
    }
    if (filters?.clientId?.value || userClientId) {
        let subClients = clients?.filter(c => ((Array.isArray(filters?.clientId?.value) ? filters?.clientId?.value : [filters?.clientId?.value]).includes(c.id)) || c.id == userClientId)?.filter(a => a.clientAccounts?.length > 1).flatMap(c => c.clientAccounts);

        if (subClients?.length == 1 && subClients[0]?.isDefault == true) {
            //do nothing
        } else if (subClients?.length > 0) {
            filterConfig.splice(1, 0, {
                type: fieldTypes.TEXT,
                size: 2,
                title: 'Sub Client',
                key: 'subClient',
                values: subClients
            });
        }
    }

    const getStatusName = (activity) => {
        let name = aliases?.find(a => a.activityStatusId === activity.activityStatus && a.clientId === activity.clientId)?.alias ?? activityStatusById?.[activity.activityStatus]?.name;
        if (activity.activityStatus === activityStatuses.PendingApproval && activity.abovePriceCap) {
            name = `${name} (above price cap)`
        }
        return name;
    }

    const lotsLookup = useMemo(() => arrayToObject(lots), [lots]);
    const clientLookup = useMemo(() => arrayToObject(clients), [clients]);
    const activityItemsLookup = useMemo(() => arrayToObject(activityItems), [activityItems]);
    const activityTypesLookup = useMemo(() => arrayToObject(activityTypes), [activityTypes]);
    const serviceProviderLookup = useMemo(() => arrayToObject(serviceProviders), [serviceProviders]);
    const clientAccountsLookup = useMemo(() => arrayToObject(clientAccounts), [clientAccounts]);
    const CALookupWithoutDefault = useMemo(() => arrayToObject(clientAccounts?.filter(c => c.isDefault == false)), [clientAccounts]);

    // const formatDates = (date, lotId) => {
    //     return For matService.formatDateNoConversion(lotsLookup?.[lotId]?.ianaTimeZone ? FormatService.formatDateByTimeZone(date, lotsLookup?.[lotId]?.ianaTimeZone) : date)
    //     return date;
    // }

    const getIfExternalUser = (createdBy) => {
        if (createdBy?.includes('@') && !createdBy?.endsWith("@parkmyfleet.com") && !createdBy?.startsWith("pmftablet")) {
            return "Yes";
        } else { return "No"; }
    }

    const mappedData = useMemo(() => mechanicalActivities?.map(activity => {
        return {
            ...activity,
            activityItem: activityItemsLookup?.[activity.activityItemId]?.name,
            activityStatusAndName: { activityStatus: activity.activityStatus, activityStatusName: getStatusName(activity) },
            vehicle: { id: activity.vehicleId, descriptor: activity?.descriptor, assetId: activity?.assetId },
            vehicleIdentifer: `${activity.descriptor}$$${activity.assetId}`,
            client: clientLookup?.[activity.clientId]?.name,
            subClient: clientAccountsLookup[activity.clientAccountId] ? activity.clientAccountId : null,
            lot: lotsLookup?.[activity.lotId]?.name,
            withinSlaStr: activity.withinSla ? 'Yes' : 'No',
            activityDateFormatted: FormatService.formatDateNoConversion(activity.activityDateFormatted),
            activityObj: activity,
            flaggedStr: activity?.latestFlag ? 'Yes' : 'No',
            serviceProvider: serviceProviderLookup?.[activity?.serviceProviderId]?.name,
            externalUser: getIfExternalUser(activity?.createdBy),
        }
    }), [mechanicalActivities]);

    const mapExportData = activity => {
        let allDataMap = {
            ...activity,
            Date: activity.activityDateFormatted,
            VIN: activity.descriptor,
            ["Asset Id"]: activity.assetId,
            Client: clientLookup?.[activity.clientId]?.name,
            ["Sub Client"]: CALookupWithoutDefault[activity.clientAccountId]?.name ?? "", 
            Lot: lotsLookup?.[activity.lotId]?.name,
            ["Type"]: activityTypesLookup?.[activity.activityTypeId]?.name,
            ["Activity"]: activityItemsLookup?.[activity.activityItemId]?.name,
            ["Status"]: getStatusName(activity),
            ["Within SLA"]: activity.withinSlaStr,
            "Charge": activity.revenue,
            ["Sub client"]: clientAccountsLookup[activity.clientAccountId]?.name ?? '',
        }
        if (serviceProviders?.length)
            allDataMap["Service Provider"] = serviceProviderLookup?.[activity?.serviceProviderId]?.name
        if (!isClient)
            allDataMap["Cost"] = activity.price;
        allDataMap['Notes'] = noteLookup[activity.id]?.map(n => n.content?.replace(/<[^>]+>/g, ''))?.join(", ") ?? '';
        allDataMap['Charge'] = activity.revenue;
        return allDataMap;
    };

    const dataLookup = arrayToObject(mappedData);

    let activityColumns = [
        // { name: "Days completed in", key: "daysCompletedIn" },
        // { name: "Days to complete", key: "daysToComplete" },
        { name: "Date", key: "activityDateFormatted" },
        { name: "Type", key: "activityTypeId", values: activityTypes, selectedByDefault: true, width: 100 },
        { name: "Activity", key: "activityItemId", values: activityItems, selectedByDefault: true, component: (row) => <Link target='_blank' to={`/workorder/${row?.workOrderId}`} className={classes.link}>{row.activityItem}</Link> },
        { name: "VIN", key: "descriptor", width: 200, component: (row) => <Link target='_blank' to={`/assets/detail/${row?.vehicleId}`} className={classes.link}>{row?.descriptor}</Link> },
        { name: "Asset Id", key: "assetId", width: 200, component: (row) => <Link target='_blank' to={`/assets/detail/${row?.vehicleId}`} className={classes.link}>{row?.assetId}</Link> },
        { name: "Status", key: "activityStatusAndName", component: (row) => <ActivityStatus activityStatus={row?.activityStatusAndName} clientId={!!userClientId.length ? parseInt(userClientId) : clientId ? clientId : row?.clientId} /> },
        ...(hasOneClaim ? [] : [{ name: "Client", key: "client" }]),
        { name: "Sub Client", key: "subClient", values: clientAccounts?.filter(a => a.isDefault == false), width: 200 },
        { name: "Lot", key: "lot" },
        ...(!serviceProviders?.length && isClient ? [] : [{ name: "Service Provider", key: "serviceProvider" }]),
        ...(isClient ? [] : [{ name: "Cost", key: "price", component: (row) => FormatService.formatCurrency(row?.price) }]),
        { name: "Charge", key: "revenue", component: (row) => FormatService.formatCurrency(row?.revenue) },
        { name: "Created By", key: "createdBy", width: 200 },
        { name: "Description", key: "customActivity", selectedByDefault: true },
        { name: "Within SLA", key: "withinSlaStr" },
        //...(isClient ? [] : [{ name: "Alert", key: "activityObj", component: (activityObj) => <ActivityPriceError activity={activityObj} />, width: 50 }]),
    ];

    if (canSeeNotes) {
        activityColumns.unshift({
            name: "Notes",
            key: "notes",
            component: (row) => {
            return <NotesTooltip 
                entityId={row?.id} 
                entityType={NoteEntityTypes.ACTIVITY} 
                readonly={!isAuthorized(permissionProfiles.DASHBOARD.MECHANICAL_DASHBOARD_EDIT_NOTE)}/>
            }
        });
    }

    if (canSeeFlags) {
        activityColumns.unshift({
            name: '',
            key: 'latestFlag',
            width: 50,
            selectedByDefault: true,
            customizable: false,
            component: (row) => {
                return <FlagAsset
                    iconOnly
                    selectedRows={[row?.id]}
                    flagOn={dataLookup?.[row?.id]?.latestFlag}
                    flagObj={dataLookup?.[row?.id]?.latestFlag}
                    getFlagFromApi={false}
                    allData={mappedData}
                    entityType={FlagEntityTypes.ACTIVITY}
                    readonly={!isAuthorized(permissionProfiles.DASHBOARD.MECHANICAL_DASHBOARD_EDIT_FLAG)}
                />
            }
        });
    }

    let filteredData = FilterService.filter(filters, search, mappedData, filterConfig);

    const onDropFilter = (filterValue, prop) => {
        setFilters({ ...filters, [prop]: { value: filterValue } });
    };

    const onResetFilters = () => {
        setFilters(defaultFilters);
    };

    const onEdit = (rowId) => {
        setActivityLogToEdit(dataLookup?.[rowId]);
    };

    const isLoading = isLoadingLots || isLoadingClients || isLoadingActivityItems || isLoadingMechanicalActivities;

    const rowActions = (rowId) => {
        const row = mappedData?.find(activity => activity.id === rowId);
        const actions = [
            {
                icon: <IconActivityDefault width={24} />,
                label: 'View',
                // profile: permissionProfiles.ASSETS.EDIT_ACTIVITY,
                onClick: () => history.push(`/workorder/${row.workOrderId}`)
            },
            {
                icon: <IconEdit width={24} />,
                label: 'Edit',
                profile: permissionProfiles.ASSETS.EDIT_ACTIVITY,
                onClick: () => onEdit(rowId)
            }
        ];
        if (!allowActions) return actions;
        if (row?.activityStatus == activityStatuses.PendingApproval) {
            actions.push(
                {
                    label: '',
                    component: <ApprovePendingApprovalActivity activityLog={row} />
                }
            );
        }
        if (row?.activityStatus == activityStatuses.PendingInvoice) {
            actions.push(
                {
                    label: '',
                    component: <ApprovePendingInvoiceActivity selectedRows={[row?.id]} activityLogs={[row]} />
                }
            );
        }
        // if (row?.activityStatus == activityStatuses.ItemRequestedByClient) {
        //     actions.push(
        //         {
        //             label: '',
        //             component: <UpdateActivityStatus selectedRows={[row?.id]} activityLogs={[row]} updateStatusTo={activityStatuses.ItemInProgress} fromStatus={activityStatuses.ItemRequestedByClient} />
        //         }
        //     );
        // }
        return actions;
    }

    const getRowClassName = (rowIndex) => {
        return (canSeeFlags && filteredData?.[rowIndex]?.latestFlag) ? classes.flaggedRow : '';
    }

    const statusForDisplay = Array.isArray(status) ? status?.[0] : status;
    const statusName = status && filters?.activityStatus?.value?.length ? <ActivityStatusAliasView activityIds={status} clientId={presentClientId} readonly={true} /> : 'Activities';

    return (
        <>
            <div className={classes.paper}>
                {
                    isLoading && <LoadingSpinner loading={isLoading} />}
                <Authorize profile={permissionProfiles.DASHBOARD.MECHANICAL_VIEW}>
                    <div>
                        <h3>
                            <Grid container alignItems='center' spacing={1}>
                                <Grid item ><div className={`${classes.dot} ${classes?.[`activityTableCount${statusForDisplay}`]}`}></div></Grid>
                                <Grid item>{statusName}</Grid>
                                <Grid item className={`${classes.activityTableCount} ${classes?.[`activityTableCount${statusForDisplay}`]}`}>
                                    {filteredData?.length}
                                </Grid>
                                {infoTextOnTable && <Grid item style={{ display: 'flex' }}> <Tooltip title={infoTextOnTable}><InfoOutlinedIcon /></Tooltip></Grid>}
                            </Grid>
                        </h3>
                    </div>
                    <div className={classes.table} >
                        <DataTable
                            onSearch={setSearch}
                            searchVal={search}
                            dropDownsConfig={filterConfig}
                            filters={filters}
                            onDropFilter={onDropFilter}
                            onResetFilters={onResetFilters}
                            withFilterPanel
                            getRowClassName={getRowClassName}
                            columns={activityColumns}
                            rows={orderBy(
                                filteredData,
                                sortOptions.columnToSort,
                                sortOptions.sortDirection
                            )}
                            rowIdentifier='id'
                            onSort={sortData}
                            sortDirection={sortOptions.sortDirection}
                            columnToSort={sortOptions.columnToSort}
                            noItemsMessage='There are no activities matching this criteria'
                            actions={isClient ? undefined : rowActions}
                            showActionsInMenu
                            bulkActions={allowActions ? [
                                ...(isAuthorized(permissionProfiles.ACTIVITY.PENDING_APPROVAL_ADD_PRICE) ? [<ApprovePendingInvoiceActivity bulk activityLogs={mappedData} />] : []),
                                //Moving from requested by client -> in progress is incorrect
                                //...((isAuthorized(permissionProfiles.ACTIVITY.ACTIVITY_MOVE_TO_NEXT_STATUS) && isAuthorized(permissionProfiles.ACTIVITY.PENDING_INVOICE_ADD_PRICE)) ? [<UpdateActivityStatus bulk activityLogs={mappedData} updateStatusTo={activityStatuses.ItemInProgress} fromStatus={activityStatuses.ItemRequestedByClient} />] : []),
                                ...(isAuthorized(permissionProfiles.ACTIVITY.APPROVE_PENDING_CLIENT) ? [<UpdateActivityStatus bulk activityLogs={mappedData} updateStatusTo={activityStatuses.PricingApprovedByClient} fromStatus={activityStatuses.PendingClientApproval} />] : [])
                            ] : undefined}
                            mapExportData={mapExportData}
                        />
                    </div>
                </Authorize>
                {
                    !!activityLogToEdit &&
                    <Authorize profile={permissionProfiles.ASSETS.EDIT_ACTIVITY}>
                        <EditActivityLog
                            VehicleTypeId={activityLogToEdit?.VehicleTypeId}
                            clientId={activityLogToEdit?.clientId}
                            activityLog={{
                                ...activityLogToEdit,
                                activityLogId: activityLogToEdit?.id,
                                activityId: activityLogToEdit?.activityStatus,
                                date: activityLogToEdit?.activityDate,
                                cost: activityLogToEdit?.price
                            }}
                            onCancel={() => setActivityLogToEdit(null)}
                            onConfirm={() => setActivityLogToEdit(null)}
                        />
                    </Authorize>
                }
            </div >
        </>
    )
}

export default ActivityListTable;
