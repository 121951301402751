import { createUserManager, loadUser } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client';
import StorageUtil from '../utils/storageUtil';
import store from './store/store';

const authority = process.env.REACT_APP_AUTH_URL || "https://dev-parqai-auth.azurewebsites.net";
const clientid = process.env.REACT_APP_AUTH_CLIENT_ID || "parqai-web-local";

// const authority = process.env.REACT_APP_AUTH_URL || "https://localhost:5001/";
// const clientid = process.env.REACT_APP_AUTH_CLIENT_ID || "parqai-web";
const userManagerConfig = {
    client_id: clientid,
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/`,
    response_type: 'code',
    scope: 'openid profile email role clientId pmf.api.read pmf.api.write lotId permission name offline_access',
    authority,
    filterProtocolClaims: true,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: StorageUtil }),
    automaticSilentRenew: false,
};

let userManager = createUserManager(userManagerConfig);
let isRefreshing = false;

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

async function refreshAccessToken(retries = 3, delayMs = 1000) {
    if (isRefreshing) return;
    isRefreshing = true;

    let user = await userManager.getUser();
     
    try {
        if (!user) {
            console.error("User not found, redirecting to login.");
            await userManager.signinRedirect();
            return;
        }

        const params = new URLSearchParams({
            grant_type: 'refresh_token',
            client_id: userManager.settings.client_id,
            refresh_token: user.refresh_token,
        });

        const tokenEndpoint = `${userManager.settings.authority}/connect/token?timestamp=${Date.now()}`;
        const response = await fetch(tokenEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: params.toString(),
        });
        if (!response.ok) throw new Error(`Token refresh failed: ${response.statusText}`);

        const tokenData = await response.json();
        if (tokenData.access_token) {
            user.access_token = tokenData.access_token;
            user.refresh_token = tokenData.refresh_token || user.refresh_token;
            user.expires_at = Math.floor(Date.now() / 1000) + tokenData.expires_in;

            await userManager.storeUser(user);
            try {
                loadUser(store, userManager);
            } catch (e) {
                console.error("Failed to update Redux store", e);
            }

            if (process.env.NODE_ENV === 'development') {
                console.log("Access token successfully refreshed.");
            }
        } else {
            throw new Error("Token refresh response does not contain a new access token.");
        }
    } catch (error) {
        if (retries > 0) {
            console.warn(`Error refreshing token. Retrying in ${delayMs}ms... (Attempts left: ${retries})`, error);
            await delay(delayMs);
            isRefreshing = false;
            return refreshAccessToken(retries - 1, delayMs * 2);
        } else {
            console.error("Token refresh failed after multiple attempts.");

            if (navigator.onLine) {
                //Redirecting to login
                await userManager.removeUser();
                await userManager.signinRedirect();
            } else {
                //Offline detected. Waiting for connection to retry token refresh
                window.addEventListener('online', async function handleOnline() {
                    window.removeEventListener('online', handleOnline);
                    await refreshAccessToken();
                });
            }
        }
    } finally {
        isRefreshing = false;
    }
}

userManager.events.addAccessTokenExpired(async () => {
    await refreshAccessToken();
});

const tokenRefreshInterval = 5 * 60 * 1000;  // Check every 5 minutes
setInterval(async () => {
    const user = await userManager.getUser();
    if (user && user.expires_in && user.expires_in < 10 * 60) {
        console.log("Access token is about to expire, refreshing...");
        await refreshAccessToken();
    }
}, tokenRefreshInterval);

export default userManager;