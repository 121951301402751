import { apiTags } from "app/store/apiTags";
import { baseQuery } from "../../app/baseQuery";
import { baseApi } from 'app/baseApi';

const baseUrl = 'vendor';

export const vendorApi = baseApi.injectEndpoints({
    reducerPath: 'vendorApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAllVendors: builder.query({
            query: (vendorId) => `${baseUrl}/full?${vendorId ? `vendorId=${vendorId}` : '' }`,
            providesTags: [apiTags.ACCESS, apiTags.VENDOR],
            transformResponse: (response) => {
                return response?.map(vendor => ({
                    ...vendor,
                    pocName: vendor?.pocInfo?.[0]?.name,
                    pocEmail: vendor?.pocInfo?.[0]?.email,
                    pocPhone: vendor?.pocInfo?.[0]?.phone,
                    address: vendor?.pocInfo?.[0]?.address,
                    city: vendor?.pocInfo?.[0]?.city,
                    state: vendor?.pocInfo?.[0]?.state,
                    zipcode: vendor?.pocInfo?.[0]?.zipCode,
                    secPocName: vendor?.pocInfo?.[1]?.name,
                    secPocEmail: vendor?.pocInfo?.[1]?.email,
                    secPocPhone: vendor?.pocInfo?.[1]?.phone,
                    secAddress: vendor?.pocInfo?.[1]?.address,
                    secCity: vendor?.pocInfo?.[1]?.city,
                    secState: vendor?.pocInfo?.[1]?.state,
                    secZipcode: vendor?.pocInfo?.[1]?.zipCode,
                })) ?? []
            }
      
        }),
        getVendorsByClaims: builder.query({
            query: () => `${baseUrl}/byClaims`,
        }),
        getVendorsByLotId: builder.query({
            query: (lotId) => `${baseUrl}/getVendorsByLotId?lotId=${lotId}`,
        }),
        getVendorsLotsByLotId: builder.query({
            query: (lotId) => `${baseUrl}/getVendorsLotByLotId?lotId=${lotId}`,
        }),
        createVendor: builder.mutation({
            query: vendor => ({
                url: `${baseUrl}`,
                method: 'POST',
                body: vendor
            }),
            invalidatesTags: [apiTags.VENDOR]
        }),
        updateVendor: builder.mutation({
            query: vendor => ({
                url: `${baseUrl}`,
                method: 'PUT',
                body: vendor
            }),
            invalidatesTags: [apiTags.VENDOR, apiTags.RESERVATION]
        })
    }),
});

export const { useGetAllVendorsQuery, 
    useGetVendorsByClaimsQuery, 
    useGetVendorsByLotIdQuery,  
    useGetVendorsLotsByLotIdQuery,
    useCreateVendorMutation,
    useUpdateVendorMutation
} = vendorApi;
