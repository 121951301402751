import { Button, Grid, Popover, Tooltip, useMediaQuery } from '@material-ui/core';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import EditIcon from "@material-ui/icons/EditSharp";
import { FlagEntityTypes } from 'features/flag/flagsConsts';
import { useGetLotsQuery } from 'features/lots/lotSlice';
import { FlagAsset } from 'features/vehicles/Flags/FlagAsset';
import AssetReadinessStatuses from 'features/vehicles/components/AssetReadinessStatuses';
import { useGetFuelTypesQuery } from 'features/vehicles/vehicleSlice';
import { IconPmfLogo } from 'icons';
import { orderBy } from 'lodash';
import moment from "moment";
import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import mobileBreakpoint from 'utils/mobileBreakpoint';
import { arrayToObject } from 'utils/object-util';
import Authorize from '../../../../components/Authorize';
import { permissionProfiles } from '../../../../components/Authorize/permissionProfiles';
import ConfirmationPopup from "../../../../components/ConfirmationPopup";
import DatePicker from '../../../../components/DatePicker';
import NotificationProvider from '../../../../components/NotificationProvider';
import FormatService from '../../../../services/formatService';
import { groupLegTypes, moveTypes, trailerTypes } from '../../enums';
import statuses from '../../loadStatusesWithIcons';
import { useUpdateActualDateMutation } from "../../loadsSlice";
import LoadGroupJourney from '../CreateLoadGroup/LoadGroupJourney';
import EditLoadLeg from '../EditLoadLeg/EditLoadLeg';
import LoadStatus from '../LoadStatus';
import { loadCardStyle } from './LoadCardStyle';
import { useSelector } from 'react-redux';
import { userHasOneClient } from 'features/user/userSlice';

const displayAddressWrapper = (data) => {
    return <div>
        <div>{data?.addressLine1}</div>
        <div>{data?.city}, {data?.state} {data?.zipCode}</div>
    </div>
}

function LoadCard({ isGroup, loadGroup, load, onViewClick, readiness, contacts, showJourney, showFlag = false }) {

    const isMobile = useMediaQuery(mobileBreakpoint);
    const [openUpdateDatePopup, setOpenUpdateDatePopup] = useState(false);
    const [isDeliveryDate, setIsDeliveryDate] = useState(false);
    let [updateActualDate, { isLoading, isError, isSuccess }] = useUpdateActualDateMutation();
    let { data: fuelTypes, error: fuelTypesError, isLoading: isLoadingFuelTypes, isSuccess: isSuccessFuelTypes } = useGetFuelTypesQuery();
    let { data: lots, error: lostError, isLoading: isLoadingLots, isSuccess: isSuccessLots } = useGetLotsQuery(null, { skip: !load || (load?.pickupLocation && load?.deliveryLocation) });
    const hasOneClaim = useSelector(userHasOneClient);

    const lotsLookup = arrayToObject(lots, 'id');

    const pickupLocation = load?.pickupLocation ?? lotsLookup?.[load?.pickupLocationId];
    const deliveryLocation = load?.deliveryLocation ?? lotsLookup?.[load?.deliveryLocationId];

    const classes = loadCardStyle()

    const getDate = (date, format = "yyyy-MM-DD, hh:mm") => {
        return date ? moment(date).format(format) : "";
    }

    const onEdit = (event, isDelivery) => {
        setOpenUpdateDatePopup(true);
        setIsDeliveryDate(isDelivery);
        return false;
    }

    const latestStatus = load?.latestStatusObject ?? {};
    const loadStatus = {
        label: statuses[latestStatus.status]?.label || latestStatus.status,
        icon: statuses[latestStatus.status]?.icon,
        timestamp: latestStatus.timestamp
    }
    const carrier = load?.carrier?.transporterName;
    const updateschedule = async (value) => {
        let loadToUpdate = load;
        if (isGroup) {
            loadToUpdate = isDeliveryDate ? load?.loads?.[load?.loads?.length - 1] : load?.loads?.[0]
        }
        let result = await updateActualDate({
            groupId: loadGroup?.id,
            loadId: loadToUpdate?.id,
            confirmedDeliveryDate: isDeliveryDate ? value.toString() : loadToUpdate.confirmedDeliveryDate,
            confirmedPickupDate: isDeliveryDate ? loadToUpdate.confirmedPickupDate : value.toString()
        });

        if (result?.error) {
            NotificationProvider.error(`Failed to update schedule`)
            setOpenUpdateDatePopup(false)
        } else {
            NotificationProvider.success(`Successfully updated schedule`);
            setOpenUpdateDatePopup(false);
        }
    }

    const [openEditLoad, setOpenEditLoad] = useState(false);

    const editLoad = () => {
        setOpenEditLoad(true)
    }

    const loadIndex = loadGroup?.loads?.map(l => l.id).indexOf(load?.id);

    const isMultiLeg = load?.loads?.length > 1;

    return (
        <div className={classes.loadBox}>
            <Grid container direction='row' >
                <Grid item xs={isMobile ? 12 : 3} className={classes.cardItem} >
                    <ArrowUpwardSharpIcon className={classes.upwardArrow} />
                    <Grid className={classes.locationWrapper} >
                        <Grid item className={classes.displayAddressWrapper} >
                            {pickupLocation?.nodeName} {pickupLocation?.name}
                            <Tooltip title="Park My Fleet Lot" placement="top" >
                                <div className={classes.pmfIcon}>
                                    {pickupLocation?.pmfLot &&
                                        <IconPmfLogo />
                                    }
                                </div>
                            </Tooltip>
                            {!!contacts?.pickup?.length && <Grid>
                                <LoadContacts contacts={contacts?.pickup} />
                            </Grid>}
                        </Grid>

                        {!isMobile && <Grid item className={classes.displayAddressWrapper} >
                            {displayAddressWrapper(pickupLocation)}
                        </Grid>}
                        <Grid item>
                            <div className={classes.externalID}>
                                <label>Earliest Pickup: </label> {`${getDate(load?.pickupStartDate, "yyyy-MM-DD")}`}
                            </div>
                        </Grid>

                        <Grid item>
                            <div className={classes.dateWrapper} >
                                <div><b className={classes.confirmedDateTextWrapper}>Estimated Pickup:</b></div>
                                <div className={classes.dateValue}>{load?.confirmedPickupDate ? `${getDate(load?.confirmedPickupDate, "yyyy-MM-DD")}` : 'Not set'}</div>
                                {
                                    <Authorize profile={permissionProfiles.LOAD.LOAD_EDIT_DATES}><button
                                        title={'add pickup date'}
                                        edge='end'
                                        aria-label='add date'
                                        onClick={(event) => onEdit(event, false)}
                                        className={classes.editDatesButton}>
                                        <EditIcon className={classes.pencilIcon} fontSize='small' />
                                    </button>
                                    </Authorize>
                                }
                            </div>
                        </Grid>
                        {
                            load?.actualPickupDate && <Grid item>
                                <div className={classes.locationDateWrapper}>
                                    <b className={classes.confirmedDateTextWrapper}>Actual Pickup: </b>
                                    {FormatService.formatDateTime(load?.actualPickupDate)}
                                </div>
                            </Grid>
                        }
                    </Grid>
                </Grid>

                <Grid item xs={isMobile ? 12 : 3} className={classes.cardItem}>
                    <ArrowDownwardSharpIcon className={classes.downwardArrow} />
                    <Grid className={classes.locationWrapper} >
                        <Grid item className={classes.displayAddressWrapper} >
                            {deliveryLocation?.nodeName} {deliveryLocation?.name}
                            <Tooltip title="Park My Fleet Lot" placement="top" >
                                <div className={classes.pmfIcon}>
                                    {deliveryLocation?.pmfLot &&
                                        <IconPmfLogo />
                                    }
                                </div>
                            </Tooltip>
                            {!!contacts?.delivery?.length && <Grid>
                                <LoadContacts contacts={contacts?.delivery} />
                            </Grid>}
                        </Grid>

                        {!isMobile && <Grid item className={classes.displayAddressWrapper}>
                            {displayAddressWrapper(deliveryLocation)}
                        </Grid>}
                        <Grid item>
                            <div className={classes.externalID}>
                                <label>Latest Delivery: </label> {`${getDate(load?.deliveryStartDate, "yyyy-MM-DD")}`}
                            </div>
                        </Grid>

                        <Grid item>
                            <div className={classes.dateWrapper}>
                                <div><b className={classes.confirmedDateTextWrapper}>Estimated Delivery: </b></div>
                                <div className={classes.dateValue}>{load?.confirmedDeliveryDate ? `${getDate(load?.confirmedDeliveryDate, "yyyy-MM-DD, ")}` : 'Not set'}
                                    {

                                        <Authorize profile={permissionProfiles.LOAD.LOAD_EDIT_DATES}><button
                                            title={'add delivery date'}
                                            edge='end'
                                            aria-label='add date'
                                            onClick={(event) => onEdit(event, true)}
                                            className={classes.editDatesButton}>
                                            <EditIcon className={classes.pencilIcon} fontSize='inherit' />
                                        </button> </Authorize>
                                    }
                                </div>
                            </div>
                        </Grid>
                        {
                            load?.actualDeliveryDate && <Grid item>
                                <div className={classes.locationDateWrapper}>
                                    <b className={classes.confirmedDateTextWrapper}>Actual Delivery: </b>
                                    {FormatService.formatDateTime(load?.actualDeliveryDate)}
                                </div>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={isMobile ? 12 : 3} direction='column' className={classes.cardItem}>
                    <Grid item className={classes.statusCardItem}>
                        <div>{loadStatus.icon}</div>
                        <Grid className={classes.locationWrapper} >
                            <Grid item className={classes.status}>
                                <LoadStatus load={load} status={load?.latestStatusObject} />
                            </Grid>
                            {isMultiLeg &&
                                <Grid item className={classes.statusDate}>
                                    <div>{" Leg " + load?.order + " of " + load?.loads?.length}</div>
                                </Grid>}
                            <Grid item>
                                <div className={classes.statusDate}>{load?.latestStatusDate?.length ? FormatService.formatDateTime(load?.latestStatusDate) : ""}</div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid>
                        <div className={classes.externalID}><label>Request Date: </label>{load?.createdDate?.length ? FormatService.formatDateTime(load?.createdDate) : ""}</div>
                    </Grid>
                    {!isMobile && <>
                        {load?.businessUnitName && <Grid item>
                            <div className={classes.externalID}>
                                <label>Business Unit: </label> {load?.businessUnitName}
                            </div>
                        </Grid>}
                        {load?.neededByWeek && <Grid item>
                            <div className={classes.externalID}>
                                <label>Needed By Week: </label> {load?.neededByWeek}
                            </div>
                        </Grid>}
                        {load?.handoffWeek && <Grid item>
                            <div className={classes.externalID}>
                                <label>Handoff Week: </label> {load?.handoffWeek}
                            </div>
                        </Grid>}
                        {!!load?.miles && <Grid item>
                            <div className={classes.externalID}>
                                <label>Miles: </label> {load?.miles}
                            </div>
                        </Grid>}
                        {!!load?.trailerType && <Grid item>
                            <div className={classes.externalID}>
                                <label>Trailer Type: </label> {trailerTypes.find(t => t.id == load?.trailerType)?.name}
                            </div>
                        </Grid>}
                    </>}
                </Grid>
                <Grid item xs={isMobile ? 12 : 3} direction='column' className={classes.lastCol}>
                    <Grid item>
                        <div className={classes.vin}>{load?.assets?.map(asset =>
                            <>
                                <div style={{ display: 'flex', alignItems: 'center' }}><b>VIN:</b>&nbsp;<NavLink exact
                                    to={`/assets/detail/${asset.id}`}>
                                    {asset.vin}
                                </NavLink>
                                    &nbsp;
                                    {readiness &&
                                        <AssetReadinessStatuses readinessStatus={readiness} />
                                    }
                                </div>
                                {!!asset.fuelType && <div className={classes.externalID}><label>Fuel Type:</label> {fuelTypes?.find(f => f.id == asset.fuelType)?.name}</div>}
                            </>
                        )}
                        </div>
                    </Grid>
                    {!isMobile && <>
                        {carrier && <Grid item>
                            <div className={classes.externalID}><label>Carrier: </label>{carrier}</div>
                        </Grid>}
                        {load?.moveType && <Grid item>
                            <div className={classes.externalID}><label>Move Type:</label> {moveTypes.find(t => t.id == load.moveType)?.name}</div>
                        </Grid>}
                        {load?.movementType?.name && <Grid item>
                            <div className={classes.externalID}><label>Move Type:</label> {load.movementType?.name}</div>
                        </Grid>}
                        <Grid item>
                            <div className={classes.externalID}><label>Asset ID:</label> {load?.externalId}</div>
                        </Grid>
                        <Grid item>
                            <div className={classes.externalID}><label>VRID:</label> {load?.id}</div>
                        </Grid>
                        <Authorize profile={permissionProfiles.LOAD.LOAD_LM_INFO}>
                            <Grid item>
                                <div className={classes.externalID}><label>LoadMate ID:</label> {load?.lmId}</div>
                            </Grid>
                        </Authorize>
                        <Authorize profile={permissionProfiles.LOAD.LOAD_LM_INFO}>
                            <Grid item>
                                <div className={classes.externalID}><label>Shipper Load ID:</label> {load?.shipperLoadId}</div>
                            </Grid>
                        </Authorize>
                        {((load?.clientName || load?.subClientName) && !hasOneClaim) && <Grid item>
                            <div className={classes.externalID}><label>Client:</label> {load?.subClientName ?? load?.clientName ?? ''}</div>
                        </Grid>}
                    </>}
                </Grid>

                <Grid className={classes.btns}>
                    {showFlag && <FlagAsset
                        iconOnly
                        selectedRows={[load?.id]}
                        allData={[{ ...load, vehicleId: load?.assets?.[0]?.id }]}
                        entityType={FlagEntityTypes.TRANSPORT}
                    />}
                    {loadGroup && !loadGroup.archived && <Authorize profile={permissionProfiles.LOAD.LOAD_LM_INFO}>
                        <Button variant="contained"
                            title={'Edit Load'}
                            onClick={editLoad}
                            className={classes.editButton}
                        >
                            Edit
                        </Button>
                    </Authorize>}
                    {onViewClick && <Button
                        variant="contained"
                        title={'View Load'}
                        onClick={() => onViewClick()}
                        className={classes.editButton}
                    >
                        View
                    </Button>}
                </Grid>
            </Grid>
            <UpdateDatePopup
                load={load}
                open={openUpdateDatePopup}
                onCancel={() => setOpenUpdateDatePopup(false)}
                onConfirm={updateschedule}
                isDeliveryDate={isDeliveryDate}
                isLoading={isLoading}
            />
            <EditLoadLeg
                open={openEditLoad}
                setOpen={setOpenEditLoad}
                loadIndex={loadIndex}
                loadGroup={loadGroup}
            />
            {
                showJourney && load?.loads?.length > 1 && <LoadGroupJourney
                    condensed
                    loadGroup={{
                        ...load,
                        loads: orderBy([
                            ...(load?.loads?.map(l => ({ ...l, type: groupLegTypes.TRANSPORT, legDate: l.pickupStartDate }) ?? [])),
                            ...(load?.activities?.map(l => ({ ...l, type: groupLegTypes.ACTIVITY, legDate: l.activityDate })) ?? [])
                        ], 'legDate')
                    }}
                    readonly />
            }
        </div >
    );
}

export default LoadCard


function LoadContacts({ contacts }) {

    const [anchorEl, setAnchorEl] = useState(false);
    const classes = loadCardStyle();

    useEffect(() => {

    }, [])

    return (
        <div>
            {/* <IconButton size='small' onClick={(e) => setAnchorEl(e.currentTarget)}>
                <PermContactCalendarOutlinedIcon />
            </IconButton> */}
            <Button style={{ padding: 0, fontSize: '0.8em' }} color='primary' onClick={(e) => setAnchorEl(e.currentTarget)}>
                POCs ({contacts?.length})
            </Button>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className={classes.contacts}>
                    {contacts.map(c => <div>{c.name} &nbsp; {c.email}</div>)}
                </div>
            </Popover>
        </div >
    );
}

function UpdateDatePopup({ load, open, isDeliveryDate, onConfirm, onCancel, isLoading }) {

    const [selectedDate, setSelectedDate] = useState(isDeliveryDate ? load.confirmedDeliveryDate : load.confirmedPickupDate);

    useEffect(() => {
        if (open) {
            setSelectedDate();
        } else {
            setSelectedDate(isDeliveryDate ? load.confirmedDeliveryDate : load.confirmedPickupDate);
        }
    }, [open])

    return (
        <ConfirmationPopup
            title={isDeliveryDate ? "Update Delivery Date" : "Update Pickup Date"}
            onCancel={(event) => { event.stopPropagation(); onCancel() }}
            onConfirm={(e) => {
                e.stopPropagation();
                onConfirm(selectedDate);
            }}
            open={open}
            loading={isLoading}
        >
            <div onClick={(event) => event.stopPropagation()}><DatePicker
                identifier={load.id}
                value={selectedDate}
                onSelect={setSelectedDate}
            />
            </div>
        </ConfirmationPopup>
    );
}