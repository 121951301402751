import { NoteEntityTypes } from "features/notes/notesConsts"

export const FlagEntityTypes = {
    ASSET: 1,
    ACTIVITY: 2,
    TRANSPORT: 3,
    ISSUE_TRACKER: 4,
    RESERVATION: 5
}

export const NoteTypeByFlagType = {
    [FlagEntityTypes.ASSET]: NoteEntityTypes.VEHICLE,
    [FlagEntityTypes.ACTIVITY]: NoteEntityTypes.ACTIVITY,
    [FlagEntityTypes.TRANSPORT]: NoteEntityTypes.TRANSPORT,
    [FlagEntityTypes.ISSUE_TRACKER]: NoteEntityTypes.ISSUE_TRACKER,
    [FlagEntityTypes.RESERVATION]: NoteEntityTypes.RESERVATION,
}

export const FlagPriorityList = [
    {
        id: 1,
        name: 'P1'
    },
    {
        id: 2,
        name: 'P2'
    },
    {
        id: 3,
        name: 'P3'
    }
]