import { useGetAllVendorsQuery } from "features/vendors/vendorSlice";
import { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import DropdownFilter from "components/DropdownFilter";
import CreateNewCarrier from "./CreateNewCarrier";
import { VendorContacts } from "./VendorContacts";
import { CarrierInfoStyle } from "./CarrierInfoStyle";
import { arrayToObject } from "utils/object-util";
import EditVendorDialog from "features/vendors/EditVendorDialog";

const ReservationCarrierInfo = ({ reservationDetails, setReservationDetails, onChangeValue, onChangePocField, displayError }) => {
    const classes = CarrierInfoStyle();

    let { data: vendors, error: vendorsError, isLoading: isLoadingVendors } = useGetAllVendorsQuery();
    vendors = vendors || [];
    
    const vendorLookup = arrayToObject(vendors);

    const [isNew, setIsNew] = useState(false);
    const [pocInfo, setPocInfo] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [vendorToEdit, setVendorToEdit] = useState();

    useEffect(() => {
        if(!!reservationDetails?.carrierInfo?.id)
        {
            setPocInfo(vendorLookup[reservationDetails?.carrierInfo?.id]?.pocInfo);
            setVendorToEdit(vendorLookup?.[reservationDetails?.carrierInfo?.id])
        }
    }, [reservationDetails?.carrierInfo?.id, reservationDetails])

    const onVendorTypeChange = (newVendor) => {
        setIsNew(newVendor);
        if(newVendor) {
            onChangeValue("carrierInfo", {'id': 0}, 'reservationDetails')
        }
        if(!newVendor)
        {
            setReservationDetails({ ...reservationDetails, ['carrierInfo']: undefined })
        }
    }

    return (
        <Grid container className={classes.carrierField} direction='row'>
            {!isNew ?
                <Grid container direction='column' item xs={6}>
                    <Grid item >
                        <div className={classes.labelCarrier}>
                            Carrier
                        </div>                
                        <DropdownFilter
                            id="id"
                            onSelect={(val) => onChangeValue("id", val.id, 'carrierInfo')}
                            elementType="dropdown"
                            values={vendors}
                            withTypeAhead
                            shrink={reservationDetails?.carrierInfo?.id}
                            value={reservationDetails?.carrierInfo?.id}
                        />
                        <Button
                            onClick={() => {setOpenDialog(true); setVendorToEdit(vendorLookup[reservationDetails?.carrierInfo?.id])}}
                            variant='outlined'
                            disabled={!reservationDetails?.carrierInfo?.id}
                            className={classes.button}
                        >
                            Edit Carrier
                        </Button>
                    </Grid>
                </Grid> :
                <Grid item xs={6} className={classes.carrierTitle}>
                    New Carrier
                </Grid>
            }

            <Grid item xs={5} className={classes.radioContainer}>
                <div className={classes.input}>
                    <label className={classes.radioLable}>
                        <input className={classes.radioButton} type="radio" checked={!isNew} onChange={() => onVendorTypeChange(!isNew)} />
                        Existing Carrier
                    </label>
                    <label className={classes.radioLable}>
                        <input className={classes.radioButton} type="radio" checked={isNew} onChange={() => onVendorTypeChange(!isNew)} />
                        New Carrier
                    </label>
                </div>
            </Grid>

            {(reservationDetails?.carrierInfo?.id && !!pocInfo?.length) ?
                <VendorContacts pocInfo={pocInfo} /> : null
            }

            {isNew && <CreateNewCarrier 
                reservationDetails={reservationDetails}
                onChangeValue={onChangeValue}
                onChangePocField={onChangePocField}
                isNew={isNew} 
                displayError={displayError}
            />}

            <EditVendorDialog
                open={openDialog}
                vendor={vendorToEdit}
                setVendor={setVendorToEdit}
                onClose={() => setOpenDialog(false)}
            />         
        </Grid>
    );
}

export default ReservationCarrierInfo;