import { Box, Chip, Grid, TextField } from "@material-ui/core";
import { Alert, Autocomplete, createFilterOptions } from "@mui/material";
import LoadingSpinner from "components/LoadingSpinner";
import { useGetVehiclesQuery } from "features/vehicles/vehicleSlice";
import { IconCar } from "icons";
import { useEffect, useState } from "react";
import { linkedAssetsStyle } from "./style";

const LinkedAssetsSelector = ({assets, setAssets, selectedClient,selectedSubClient, selectedLot, disabled}) => {
    const classes = linkedAssetsStyle();

    let { data: vehicles, error: vehicleError, isFetching: isFetchingVehicles, refetch: refetchVehicles } = useGetVehiclesQuery({ includeHistory: true, clientId: selectedClient ,clientAccountId:selectedSubClient}, { skip: !selectedClient });
    
    vehicles = vehicles || [];

    const [open, setOpen] = useState(false);
    const [filteredVehicles, setFilteredVehicles] = useState(!!vehicles.length ? vehicles?.filter(v => v.lotId == selectedLot) : []);

    useEffect(() => {
        if(selectedLot && !!vehicles.length) {
            setFilteredVehicles(vehicles?.filter(v => v.lotId == selectedLot));
        }
    }, [selectedClient,selectedSubClient, selectedLot, assets, vehicles])

    const handleChange = (newValue) => {
        setAssets(newValue);
    }

    const getOptionLabel = (option) => {
        return `${option.descriptor || ''} ${option.assetId || ''} ${option.licensePlate || ''}`;
    }

    const filterOptions = createFilterOptions({
        ignoreCase: true,
        limit: 30,
    });

    const renderOption = (props, option) => {
        return (
            <Box  {...props}>
                <Grid container xs={12} alignItems='center' justifyContent='space-between'>
                    <Grid item>
                        <Grid container alignItems='center'>
                            <Grid item className={classes.itemIcon}>
                                <IconCar width={20} height={20} />
                            </Grid>
                            <Grid item>
                                <Grid container direction='column'>
                                    {option?.descriptor ? <Grid item >
                                        <label className={classes.itemLabel}> VIN: </label>
                                        {option?.descriptor}
                                    </Grid> : <></>}
                                    {option?.assetId ? <Grid item >
                                        <label className={classes.itemLabel}> Asset ID: </label>
                                        {option?.assetId}
                                    </Grid> : <></>}
                                    {option?.licensePlate ? <Grid item >
                                        <label className={classes.itemLabel}> License Plate: </label>
                                        {option?.licensePlate}
                                    </Grid> : <></>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <>
        <Grid item xs={12} >
            <Autocomplete
                id="choose-asset"
                multiple
                autoComplete
                filterSelectedOptions
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                handleHomeEndKeys
                autoHighlight
                disabled={disabled}
                value={assets}
                options={filteredVehicles}
                loading={isFetchingVehicles}
                getOptionLabel={(option) => getOptionLabel(option)}
                filterOptions={filterOptions}
                isOptionEqualToValue={(option, value) => {
                    return option?.id == value?.id
                }}
                onChange={(event, newInputValue) => {
                    handleChange(newInputValue);
                }}
                renderOption={(props, option) => renderOption(props, option)}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={`${option.descriptor || ''} ${option.assetId || ''} ${option.licensePlate || ''}`}
                        {...getTagProps({ index })}
                        icon={<IconCar width={14} height={14} />}
                      />
                    ))
                }
                renderInput={(params) => (                    
                    <div>
                        <TextField {...params} 
                            variant='outlined'
                            fullWidth 
                            onFocus={() => setOpen(true)}
                            onBlur={() => setOpen(false)}    
                        />
                        <LoadingSpinner loading={isFetchingVehicles} />
                    </div>
                )}
                className={classes.autoComplete}
            />
        </Grid>
        {(!selectedClient || !selectedLot) && 
        <Grid xs={12} className={classes.alertWrapper}>
            <Alert severity='error' className={classes.alert} >
                {'Please select a lot and a client'}
            </Alert>
        </Grid>}
        </>
    );
}

export default LinkedAssetsSelector;