import { Grid, Paper } from "@material-ui/core";
import { mechanicalConfigStyles } from "../../style";
import AddRule from "../../AddRule";
import DataSearchBar from "components/DataSearchBar";
import LotMechanicalConfig from "../LotMechanicalConfig/LotMechanicalConfig";
import { useState } from "react";
import { Alert } from "@mui/material";

export const AutomationLogicTab = ({ mechanicalsConfigParams, search, setSearch, selectedLot, setSelectedLot, lots, clientToEdit }) => {
    const classes = mechanicalConfigStyles();

    const [mechanicalsRulesToEdit, setMechanicalsRulesToEdit] = useState([])

    return (
        <Grid container direction='column' spacing={2}>
            {!selectedLot &&
                <>
                    <Grid item>
                        <AddRule
                            clientId={clientToEdit?.id}
                            lotConfig={null}
                            clientLots={clientToEdit?.clientLots}
                            mechanicalsRulesToEdit={mechanicalsRulesToEdit}
                            setMechanicalsRulesToEdit={setMechanicalsRulesToEdit}
                            selectLot={true}
                        />
                    </Grid>
                    {!mechanicalsConfigParams?.useMechanicals && <Grid item><Alert severity="warning">The mechanicals programs is curretly turned off. Configured rules will not be triggered<br/>The program can be turned on in the "General" tab.</Alert></Grid>}
                    <Grid item><DataSearchBar onSearch={(val) => setSearch(val)} searchVal={search} /></Grid>
                    {lots?.map(l =>
                        <Grid key={l.id} item onClick={() => setSelectedLot(l)}>
                            <Paper className={classes.lotWrapper}>
                                <Grid container alignItems='center'>
                                    <Grid item xs={2} display="flex" className={classes.iconContainer}>
                                        <img src="/icons/icon-detailing.svg" alt="Transporter" />
                                    </Grid>
                                    <Grid item className={classes.lotName}>{l.name}</Grid>
                                </Grid>
                            </Paper>
                        </Grid>)
                    }</>
            }
            {selectedLot && <LotMechanicalConfig onClose={() => { setSelectedLot(); setMechanicalsRulesToEdit([]) }} lotConfig={selectedLot} clientId={clientToEdit?.id} mechanicalsRulesToEdit={mechanicalsRulesToEdit} setMechanicalsRulesToEdit={setMechanicalsRulesToEdit} />}
        </Grid>
    )
}
