import { Grid } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import { ReservationFormStyles } from "features/Reservation/NewReservation/Components/ReservationForm/ReservationFormStyles";
import PocFields from "./PocFields";

const VendorFields = ({ vendor, onChange, onChangePocField, displayError, isNew }) => {
    const classes = ReservationFormStyles();

    return (
        <Grid container spacing={2} style={{ marginTop: '1em' }} >
            {/* name and code */}
            <Grid item container direction='row' spacing={2} >
            <Grid item xs={6}>
                <Grid container direction="column">
                    <div className={classes.label}>
                        Name
                    </div>
                    <CustomInput
                        id="name"
                        elementType='input'
                        value={vendor?.name}
                        onChange={(val) => onChange("name", val, 'carrierInfo')}
                        required
                        touched
                    />
                    {isNew && displayError('carrierName')}
                </Grid>
            </Grid>

            <Grid item xs={6}>
                <div className={classes.label}>
                    SCAC 
                </div>
                <CustomInput
                    id="scac"
                    elementType='input'
                    value={vendor?.scac}
                    onChange={(val) => onChange("scac", val, 'carrierInfo')}
                />
            </Grid>
        </Grid>

        {/* //poc fields name email phone */}
        {Array.from({ length: Math.max(2, vendor?.pocInfo?.length || 0) })?.map((_, index) => (
            <PocFields
                key={index}
                poc={vendor?.pocInfo?.[index] || {}}
                onChange={(fieldName, fieldValue) => 
                    onChangePocField(fieldName, fieldValue, index)
                }
                displayError={(field) => displayError(`${field}${index}`)}
                isNew={isNew}
                label={index === 1}
            />
        ))}
    </Grid>
)}

export default VendorFields;