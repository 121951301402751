import React, { useState } from "react";
import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Card, Menu, MenuItem, Button, Grid } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import EditTransporterFormDialog from "../../../../transporter/EditTransporterFormDialog";
import BasicModal from "../../../../../components/BasicModal";
import DropdownFilter from "../../../../../components/DropdownFilter";
import configStyles from "../../configStyles";
import DeleteIcon from '@material-ui/icons/Delete';
import { useCreateTransporterMutation, useGetTransportersQuery } from "../../../../transporter/transporterSlice";
import { permissionProfiles } from "../../../../../components/Authorize/permissionProfiles";
import Authorize from "../../../../../components/Authorize";

const useStyles = configStyles;

const ActiveTransportersList = ({ transportConfigParams, activeTransporters, onConfigChange }) => {

    const classes = useStyles();

    let { data: transporters, error: transportersError, isLoading } = useGetTransportersQuery();
    transporters = transporters || [];

    const [anchorEl, setAnchorEl] = useState(null);
    const openAddMenu = Boolean(anchorEl);
    const [openNewTransporter, setOpenNewTransporter] = useState(false);
    const [openSelectTransporter, setOpenSelectTransporter] = useState(false);
    const [transporterIds, setTransporterIds] = useState([]);

    let [createTransporter] = useCreateTransporterMutation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const onAddNewClick = () => {
        handleClose();
        setOpenNewTransporter(true)
    }

    const onSelectExistingClick = () => {
        handleClose();
        setOpenSelectTransporter(true)
    }

    const onAddNewTransporter = async (data) => {
        setOpenNewTransporter(false)

        let result = await createTransporter(data);

        let transporterToAdd = { id: result.data, name: data.name, usdot: data.usdot } //add other fields?
        let updatedCTransporters = activeTransporters?.concat(transporterToAdd);

        onConfigChange(undefined, "clientTransporters", updatedCTransporters)
    }

    const handleCloseExisting = () => {
        setOpenSelectTransporter(false);
        setTransporterIds([]);
    }

    const onSelectTransporter = async () => {
        setOpenSelectTransporter(false)

        let transportersToAdd = [];
        transporterIds.forEach((id) => {
            if (!activeTransporters?.some(t => t.id === id)) {
                let t = transporters?.find(transporter => transporter.id == id)
                if (t != null)
                    transportersToAdd.push(t);
            }
        })
        let updatedCTransporters = activeTransporters?.concat(transportersToAdd)

        onConfigChange(undefined, "clientTransporters", updatedCTransporters);
        handleCloseExisting();
    }

    const onClickDelete = (id) => {
        let updatedCTransporters = activeTransporters?.filter(cTransporter => cTransporter.id != id)
        onConfigChange(undefined, "clientTransporters", updatedCTransporters);
    };

    //handle change of tranports multiSelect
    const handleChange = (event) => {
        setTransporterIds(event);
    }

    let filteredTransporters = [];
    transporters.map(transporter => {
        if (!(activeTransporters?.find(aTransporter => aTransporter.id == transporter.id)))
            filteredTransporters.push(transporter);
    })

    return (
        <>
            <Paper className={classes.card}>

                <Grid container>
                    <Grid item xs={9}>
                        <div className={classes.title}>Carriers</div>
                    </Grid>
                    <Grid item xs={3}>
                        <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_CHANGE}>
                            <div className={classes.addButton}>
                                <Button
                                    disabled={!transportConfigParams?.useTransport}
                                    id="basic-button"
                                    //color="secondary"
                                    variant="contained"
                                    aria-controls={openAddMenu ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openAddMenu ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    Add Carrier
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={openAddMenu}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={onAddNewClick}>Add new</MenuItem>
                                    <MenuItem onClick={onSelectExistingClick}>Select existing</MenuItem>
                                </Menu>
                            </div>

                            <EditTransporterFormDialog
                                label="Add New Transporter"
                                open={openNewTransporter}
                                setOpen={setOpenNewTransporter}
                                onSave={onAddNewTransporter}
                            />

                            <BasicModal
                                open={openSelectTransporter}
                                header={`Select Transporter`}
                                handleClose={handleCloseExisting}
                                component={
                                    <div className={classes.selectTransporter}>
                                        <DropdownFilter
                                            title={'Transporters'}
                                            values={filteredTransporters}
                                            onSelect={(val) => handleChange(val)}
                                            value={transporterIds}
                                            multiple={true}
                                            className={classes.multiSelect}
                                        />
                                        <div className={classes.selectActionButtons}>
                                            <Button onClick={handleCloseExisting} color="primary">
                                                Cancel
                                            </Button>
                                            <Button onClick={onSelectTransporter} color="primary" variant="contained">
                                                Save
                                            </Button>
                                        </div>
                                    </div>}
                            />
                        </Authorize>
                    </Grid>
                </Grid>
                <List>
                    {
                        activeTransporters?.map(activeTransporter =>
                            <Card className={classes.listItem}>
                                <ListItem key={activeTransporter.id} className={classes.spacing}>
                                    <ListItemText primary={activeTransporter.name} />
                                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_CHANGE}>
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="delete"
                                                disabled={!transportConfigParams?.useTransport}
                                                onClick={() => onClickDelete(activeTransporter.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </Authorize>
                                </ListItem>
                            </Card>
                        )
                    }
                </List>
            </Paper>
        </>
    )
}

export default ActiveTransportersList;
