import React, { useState } from "react";
import { BillStatusesStyle } from "./BillStatusesStyle";
import PCOInvoiceDetails from "../../../invoice/storage/components/PCOInvoice/PCOInvoiceDetails/PCOInvoiceDetails";
import { useGetInvoiceByVehicleIdQuery } from "../../../invoice/storage/storageInvoiceSlice.ts";
import { StorageInvoiceStatus, StorageInvoiceStatuses } from "../../../invoice/storage/storageInvoiceConsts";

const BillStatuses = ({ vehicleId, clientName }) => {
    const classes = BillStatusesStyle();
    const [showInvoiceDetails, setShowInvoiceDetails] = useState(false);

    const { data: invoiceByVehicle, isLoading: isLoadingInvoiceByVehicle } = useGetInvoiceByVehicleIdQuery(vehicleId);

    const handleDivClick = () => {
        setShowInvoiceDetails(true);
    };

    const onClose = () => {
        setShowInvoiceDetails(false);
    };

    if (!invoiceByVehicle || isLoadingInvoiceByVehicle) return null;

    const invoice = {
        ...invoiceByVehicle,
        ...invoiceByVehicle?.storageInvoiceVehicles?.[0],
        lotName: invoiceByVehicle?.lotStorageInvoiceDetails?.[0]?.name,
        numberOfLines: (invoiceByVehicle?.storageInvoiceActivities?.length || 0) + 1,
        totalCharge: invoiceByVehicle?.storageInvoiceDetails?.[0]?.totalFee || 0,
        customerName: clientName,
        statusId: invoiceByVehicle?.status ?? 0,
        storageInvoiceId: invoiceByVehicle?.storageInvoiceVehicles?.[0]?.storageInvoiceId || invoiceByVehicle?.id || 0,
    };

    let isCompleted =invoice.statusId === StorageInvoiceStatus.SENT_TO_QB;
    
    if (invoice.statusId === 0 || invoice.statusId === StorageInvoiceStatus.CANCELED) {
        return null;
    }

    const statusInfo = StorageInvoiceStatuses[invoice.statusId] || {};
    const shouldBlink = invoice.statusId !== StorageInvoiceStatus.SENT_TO_QB;

    return (
        <>
            <div
                className={`${classes.readyBadge} ${shouldBlink ? classes.blinkingBadge : ''}`}
                style={{ backgroundColor: statusInfo.color || '#ccc' }}
                onClick={handleDivClick}
            >
                {statusInfo.name || "Unknown Status"}
            </div>
            {showInvoiceDetails && !isCompleted && (
                <PCOInvoiceDetails invoice={invoice} onClose={onClose} />
            )}
        </>
    );
};

export default BillStatuses;
