import { Grid, Paper, TextField, Tooltip } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import { FormBuilderStyles } from "../style";
import { formTypeOptions, formTypes } from "../formBuilderConsts";
import EditBooleanConfigParameter from "features/clients/clientConfigs/EditBooleanConfigParameter/EditBooleanConfigParameter";

const FormHeader = ({ formType, form, onFormFieldChange, clientId }) => {

    const classes = FormBuilderStyles();
    const formTypeSetup = formTypeOptions.find(f => f.id == formType)

    const getFormTitle = () => {
        if (formType == formTypes.ConditionReport)
            return 'When set to "On" the custom form will be used as the condition report form for this client';
        return `When set to "On" the custom form will be used to check ${formType == formTypes.Checkout ? 'out' : 'in'} assets using the Parq mobile app`;
    }

    return (
        <Paper className={classes.headerSection}>
            <Grid alignItems="center" container justifyContent="space-between" spacing={1}>
                <Grid item xs={formTypeSetup?.configInfo ? 10 : 12}>
                    <TextField
                        style={{ width: '100%' }}
                        value={form?.name}
                        label=""
                        variant="outlined"
                        onChange={e => onFormFieldChange('name', e.target.value)}
                        required
                        touched
                        placeholder="Add form name..."
                    />
                </Grid>
                {formTypeSetup?.configInfo && <Grid item>
                    <Tooltip title={getFormTitle()}>
                        <div>
                            <EditBooleanConfigParameter
                                clientId={clientId}
                                label='Use custom form'
                                {...formTypeSetup?.configInfo}
                            />
                        </div>
                    </Tooltip>
                </Grid>}
            </Grid>
        </Paper>
    );
}
export default FormHeader; 