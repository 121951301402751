import CustomInput from "components/CustomInput"

export const TextComponent = ({ value, onChange, label }) => {

    let valueStr = value + "";
    try {
        valueStr = value ? JSON.parse(value) : null
    } catch (error) {
        console.log(`TextComponent Failed to format value ${value + ""}`)
    }

    return (
        <CustomInput
            elementType='input'
            value={valueStr}//JSON.parse(value) : null
            onChange={(val) => onChange(JSON.stringify(val), "values")}
            label={label}
        />
    )
}