import * as React from "react";

function SvgIconConditionReportOption(props, width, height) {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 6.75H10C9.49933 6.75 8.95415 6.73911 8.52405 6.47859C8.14991 6.25197 7.75 5.75825 7.75 4.5C7.75 3.24175 8.14991 2.74803 8.52405 2.52141C8.95415 2.26089 9.49933 2.25 10 2.25H14C14.5007 2.25 15.0459 2.26089 15.476 2.52141C15.8501 2.74803 16.25 3.24175 16.25 4.5C16.25 5.00067 16.2391 5.54585 15.9786 5.97595C15.752 6.35009 15.2582 6.75 14 6.75ZM10 2.75C9.99263 2.75 9.98526 2.75 9.97789 2.75C9.74875 2.74997 9.5213 2.74995 9.32776 2.77306C9.12478 2.79729 8.88727 2.85359 8.68491 3.0211C8.4758 3.1942 8.37254 3.42824 8.31846 3.66681C8.26592 3.89862 8.25 4.17834 8.25 4.5C8.25 4.82166 8.26592 5.10138 8.31846 5.33319C8.37254 5.57176 8.4758 5.8058 8.68491 5.9789C8.88727 6.14641 9.12478 6.20271 9.32776 6.22694C9.52129 6.25005 9.74874 6.25003 9.97788 6.25C9.98525 6.25 9.99263 6.25 10 6.25H14C14.3217 6.25 14.6014 6.23408 14.8332 6.18154C15.0718 6.12746 15.3058 6.0242 15.4789 5.81509C15.6464 5.61273 15.7027 5.37522 15.7269 5.17224C15.75 4.97871 15.75 4.75126 15.75 4.52212L15.75 4.5C15.75 4.17834 15.7341 3.89862 15.6815 3.66681C15.6275 3.42824 15.5242 3.1942 15.3151 3.0211C15.1127 2.85359 14.8752 2.79729 14.6722 2.77306C14.4787 2.74995 14.2513 2.74997 14.0221 2.75L14 2.75H10Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M8.01564 4.77101L8.01564 4.771L8.01188 4.77121C6.57314 4.85227 5.32541 5.10982 4.46643 6.01968C3.61211 6.9246 3.25 8.35935 3.25 10.5004V16.5004C3.25 18.3396 3.41917 19.8363 4.30453 20.8431C5.2052 21.8673 6.71786 22.2504 9 22.2504H15C17.2821 22.2504 18.7948 21.8673 19.6955 20.8431C20.5808 19.8363 20.75 18.3396 20.75 16.5004V10.5004C20.75 8.35935 20.3879 6.9246 19.5336 6.01968C18.6746 5.10982 17.4269 4.85227 15.9881 4.77121V4.7712L15.9844 4.77101C15.856 4.76475 15.7426 4.64487 15.7494 4.50478C15.7557 4.37677 15.875 4.26353 16.0146 4.26978C17.9754 4.37629 19.2419 4.89447 20.0363 5.83025C20.8421 6.77942 21.25 8.25896 21.25 10.4904V16.4904C21.25 18.5623 20.9632 20.0928 20.1003 21.1155C19.2543 22.1183 17.7482 22.7504 15 22.7504H9C6.25176 22.7504 4.74552 22.1183 3.89949 21.1165C3.03679 20.0951 2.75 18.5671 2.75 16.5004V10.5004C2.75 8.26362 3.15805 6.78177 3.96368 5.83275C4.75776 4.89733 6.02391 4.38137 7.98492 4.2798C8.12968 4.27303 8.24424 4.38136 8.25057 4.5142C8.25716 4.65266 8.15602 4.76417 8.01564 4.77101Z" fill="#0090FE" stroke="#0090FE" />
        </svg>
    );
}

export default SvgIconConditionReportOption;
