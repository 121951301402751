import * as React from "react";

function SvgIconCalendarCircle(props, width, height) {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99984 18.9582C5.33431 18.9582 1.5415 15.1654 1.5415 10.4998C1.5415 5.83431 5.33431 2.0415 9.99984 2.0415C14.6654 2.0415 18.4582 5.83431 18.4582 10.4998C18.4582 15.1654 14.6654 18.9582 9.99984 18.9582ZM9.99984 2.2915C5.47369 2.2915 1.7915 5.97369 1.7915 10.4998C1.7915 15.026 5.47369 18.7082 9.99984 18.7082C14.526 18.7082 18.2082 15.026 18.2082 10.4998C18.2082 5.97369 14.526 2.2915 9.99984 2.2915Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M8.2417 7.15006C8.21155 7.15006 8.18027 7.13778 8.15463 7.11214C8.12898 7.08649 8.1167 7.05521 8.1167 7.02506V5.3584C8.1167 5.32825 8.12898 5.29697 8.15463 5.27133C8.18027 5.24568 8.21155 5.2334 8.2417 5.2334C8.27185 5.2334 8.30313 5.24568 8.32877 5.27133C8.35441 5.29697 8.3667 5.32825 8.3667 5.3584V7.02506C8.3667 7.09494 8.31122 7.15006 8.2417 7.15006Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M11.7583 7.15006C11.7282 7.15006 11.6969 7.13778 11.6712 7.11214C11.6456 7.08649 11.6333 7.05521 11.6333 7.02506V5.3584C11.6333 5.32825 11.6456 5.29697 11.6712 5.27133C11.6969 5.24568 11.7282 5.2334 11.7583 5.2334C11.7884 5.2334 11.8197 5.24568 11.8454 5.27133C11.871 5.29697 11.8833 5.32825 11.8833 5.3584V7.02506C11.8833 7.05521 11.871 7.08649 11.8454 7.11214C11.8197 7.13778 11.7884 7.15006 11.7583 7.15006Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M13.7249 9.0752H6.2666C6.23645 9.0752 6.20517 9.06291 6.17953 9.03727C6.15389 9.01162 6.1416 8.98034 6.1416 8.9502C6.1416 8.92005 6.15389 8.88877 6.17953 8.86312C6.20517 8.83748 6.23645 8.8252 6.2666 8.8252H13.7249C13.7551 8.8252 13.7864 8.83748 13.812 8.86312C13.8377 8.88877 13.8499 8.92005 13.8499 8.9502C13.8499 9.02007 13.7945 9.0752 13.7249 9.0752Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M11.2498 15.3498H8.74984C6.73431 15.3498 5.2915 13.907 5.2915 11.8914V9.39144C5.2915 7.37591 6.73431 5.93311 8.74984 5.93311H11.2498C13.2654 5.93311 14.7082 7.37591 14.7082 9.39144V11.8914C14.7082 13.907 13.2654 15.3498 11.2498 15.3498ZM8.74984 6.18311C7.68077 6.18311 6.86139 6.57583 6.31549 7.20265C5.77931 7.81831 5.5415 8.61925 5.5415 9.39144V11.8914C5.5415 12.6636 5.77931 13.4646 6.31549 14.0802C6.86139 14.707 7.68077 15.0998 8.74984 15.0998H11.2498C12.3189 15.0998 13.1383 14.707 13.6842 14.0802C14.2204 13.4646 14.4582 12.6636 14.4582 11.8914V9.39144C14.4582 8.61925 14.2204 7.81831 13.6842 7.20265C13.1383 6.57583 12.3189 6.18311 11.2498 6.18311H8.74984Z" fill="#0090FE" stroke="#0090FE" />
        </svg>
    );
}

export default SvgIconCalendarCircle;
