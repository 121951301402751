import { Grid } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import PhoneInput from "components/PhoneInput";
import { ReservationFormStyles } from "features/Reservation/NewReservation/Components/ReservationForm/ReservationFormStyles";
import { IconLot } from "icons";
import { usStates } from "services/usStates";
import Validator from "services/validator";

const PocFields = ({ poc, onChange, displayError, isNew, label }) => {
    const classes = ReservationFormStyles();

    return (
        <>
            <Grid container className={classes.pocTitle}>{(label ? "Secondary " : "") + "POC Fields"}</Grid>
            <Grid item xs={4} className={classes.field}>
                <div className={classes.label}>
                    {(label ? "Secondary " : "") + "POC name"}
                </div>
                <CustomInput
                    id="name"
                    elementType='input'
                    value={poc?.name}
                    onChange={(val) => onChange("name", val)}
                />
            </Grid>
            <Grid item xs={6} className={classes.field}>
                <Grid container direction="column">
                    <div className={classes.label}>
                        {(label ? "Secondary " : "") + "POC email"}
                    </div>
                    <CustomInput
                        id="email"
                        elementType='input'
                        value={poc?.email}
                        valid={Validator.validateEmail(poc?.email)}
                        touched={poc?.email}
                        onChange={(val) => onChange("email", val)}
                    />
                    {isNew && displayError('pocEmail')}
                </Grid>
            </Grid>
            <Grid item xs={4} className={classes.field}>
                <Grid container direction="column">
                    <div className={classes.label}>
                        {(label ? "Secondary " : "") + 'POC phone number'}
                    </div>
                    <PhoneInput
                        value={poc?.phone}
                        valid={poc?.phone && Validator.validatePhone(poc?.phone)}
                        onChange={(val) => onChange("phone", val)} 
                    />
                    {isNew && displayError('pocPhone')}
                </Grid>
            </Grid>

            {/* address fields */}
            <Grid item xs={6} className={classes.field}>
                <div className={classes.label}>
                    {(label ? "Secondary " : "") + 'POC Address'}
                </div>
                <CustomInput
                    id="address"
                    elementType="input"
                    value={poc?.address}
                    icon={<IconLot />}
                    onChange={(val) => onChange("address", val)}
                    required
                />
            </Grid>                        
            <Grid item xs={4} className={classes.field}>
                <Grid container direction='column'>
                    <div className={classes.label}>
                        {(label ? "Secondary " : "") + 'POC City'}
                    </div>
                    <CustomInput
                        id="city"
                        elementType="input"
                        value={poc?.city}
                        icon={<IconLot />}
                        onChange={(val) => onChange("city", val)}
                        required
                    />
                    {isNew && displayError('city')}
                </Grid>
            </Grid>
            <Grid item xs={4} className={classes.field}>
                <Grid container direction='column'>
                    <div className={classes.label}>
                        {(label ? "Secondary " : "") + 'POC State'}
                    </div>
                    <CustomInput
                        id="state"
                        elementType="dropdown"
                        value={poc?.state}
                        values={[{"name": "", "id": ""}, ...usStates]}
                        icon={<IconLot />}
                        onChange={(val) => onChange("state", val)}
                    />
                    {isNew && displayError('state')}
                </Grid>
            </Grid>
                <Grid item xs={4} className={classes.field}>
                <Grid container direction='column'>
                    <div className={classes.label}>
                        {(label ? "Secondary " : "") + 'POC Zipcode'}
                    </div>
                    <CustomInput
                        id="zipcode"
                        elementType="input"
                        value={poc?.zipCode}
                        icon={<IconLot />}
                        onChange={(val) => onChange("zipCode", val)}
                        required
                    />
                    {isNew && displayError('zipcode')}
                </Grid>
            </Grid>
        </>
    )
}

export default PocFields;