import CustomInput from "components/CustomInput";
import MonthYearPicker from "components/MonthYearPicker/MonthYearPicker";
import { licensePlateTypesList } from "features/vehicles/vehicleConsts";
import moment from "moment";
import VehicleService from "services/vehicleService";
import { arrayToObject } from "utils/object-util";

export const activityStatuses = {
    ItemRequestedByClient: 1,
    ItemRequestedByVendor: 2,
    ItemInProgress: 3,
    ItemCompleted: 4,
    PendingApproval: 5,
    PendingInvoice: 6,
    Cancelled: 7,
    CompleteBilling: 8,
    Closed: 9,
    PendingClientApproval: 10,
    AutoRequested: 11,
    PricingApprovedByClient: 12,
}

export const activityStatusOrder = {
    [activityStatuses.AutoRequested]: 1,
    [activityStatuses.ItemRequestedByClient]: 1,
    [activityStatuses.ItemRequestedByVendor]: 1,
    [activityStatuses.PendingApproval]: 2,
    [activityStatuses.PendingClientApproval]: 3,
    [activityStatuses.PricingApprovedByClient]: 4,
    [activityStatuses.ItemInProgress]: 5,
    [activityStatuses.PendingInvoice]: 6,
    [activityStatuses.ItemCompleted]: 7,
    [activityStatuses.CompleteBilling]: 8,
    [activityStatuses.Closed]: 8,
}

export const activityStatusesList = [
    { id: activityStatuses.ItemRequestedByClient, name: 'Requested By Client', shortName: 'Requested' },
    { id: activityStatuses.ItemRequestedByVendor, name: 'Item Recommended by Technician', shortName: 'Recommended' },
    { id: activityStatuses.ItemInProgress, name: 'In Progress' },
    { id: activityStatuses.ItemCompleted, name: 'Completed', isClosed: true },
    { id: activityStatuses.PendingApproval, name: 'Pending Pricing Confirmation' },
    { id: activityStatuses.PendingInvoice, name: 'Complete & Pending Invoice' },
    { id: activityStatuses.Cancelled, name: 'Cancelled', isClosed: true },
    { id: activityStatuses.CompleteBilling, name: 'Complete Billing', isClosed: true },
    { id: activityStatuses.Closed, name: 'Closed', isClosed: true },
    { id: activityStatuses.PendingClientApproval, name: 'Pending Client Approval' },
    { id: activityStatuses.AutoRequested, name: 'Auto Requested' },
    { id: activityStatuses.PricingApprovedByClient, name: 'Pricing Approved By Client' },
]

export const activityStatusById = arrayToObject(activityStatusesList);

export const completeStatuses = [
    activityStatuses.PendingInvoice,
    activityStatuses.ItemCompleted,
    activityStatuses.CompleteBilling,
    activityStatuses.Closed];

export const activityColors = {
    [activityStatuses.PendingApproval]: '#F6B6CD',
    [activityStatuses.PendingClientApproval]: '#64E5C6',
    [activityStatuses.AutoRequested]: '#FC9154',
    [activityStatuses.ItemRequestedByClient]: '#FC9154',
    [activityStatuses.ItemRequestedByVendor]: '#FC9154',
    [activityStatuses.PricingApprovedByClient]: '#FCC354',
    [activityStatuses.ItemInProgress]: '#FFB240',
    [activityStatuses.PendingInvoice]: '#9bdc8a',
    [activityStatuses.ItemCompleted]: '#64E5C6',
    [activityStatuses.CompleteBilling]: '#9bdc8a',
    [activityStatuses.Closed]: '#9bdc8a',

}

export const activityCustomFieldMappingEnum = {
    LicensePlateNumber: 1,
    RegistrationExpiration: 2,
    LicensePlateState: 3,
    LicensePlateType: 4,
}

export const activityCustomFieldSetup = {
    [activityCustomFieldMappingEnum.LicensePlateNumber]: {
        mappedValue: (vehicle) => vehicle?.licensePlate
    },
    [activityCustomFieldMappingEnum.LicensePlateState]: {
        component: (customField, value, onChange) => <CustomInput
            id="ddlCustomFieldData"
            label={customField?.label}
            elementType="dropdown"
            value={value}
            onChange={(val) => onChange(customField, val)}
            values={[{ name: '', id: null }, ...VehicleService.getUsStates()]}
            touched={true}
        />,
        mappedValue: (vehicle) => vehicle?.licensePlateState
    },
    [activityCustomFieldMappingEnum.RegistrationExpiration]: {
        component: (customField, value, onChange) => <MonthYearPicker
            label={customField?.label}
            onChange={(value) => {
                const val = moment(value).format("YYYY-MM");
                onChange(customField, val);
            }}
            value={value} />,
        mappedValue: (vehicle) => vehicle?.registrationYear && vehicle?.registrationMonth ? moment(new Date(vehicle?.registrationYear, vehicle?.registrationMonth)).format("YYYY-MM") : ""
    },
    [activityCustomFieldMappingEnum.LicensePlateType]: {
        component: (customField, value, onChange) => <CustomInput
            id="licensePlateType"
            label={customField?.label}
            value={parseInt(value)}
            elementType="dropdown"
            onChange={(val) => onChange(customField, val)}
            values={licensePlateTypesList || []}
            showEmpty
        />,
        mappedValue: (vehicle) => vehicle?.licensePlateType?.toString()
    }
}