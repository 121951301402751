import { Button, Tooltip } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import NotificationProvider from "components/NotificationProvider";
import { activityStatusById, activityStatuses } from "features/activity/activityConsts";
import { useMoveToNextMutation } from "features/activity/activitySlice";
import usePrompt from "hooks/usePrompt";
import { useEffect } from "react";
import { arrayToObject } from "utils/object-util";
import { approveActivityStyle } from "./style";
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';

const UpdateActivityStatus = ({
    selectedRows, //Injected by the bulk actions
    activityLogs,
    bulk,
    updateStatusTo = activityStatuses.ItemInProgress,
    fromStatus = activityStatuses.ItemRequestedByClient,
}) => {

    const classes = approveActivityStyle();
    const [updateStatus, { isLoading }] = useMoveToNextMutation();
    const { triggerPrompt } = usePrompt();

    const activityLookup = arrayToObject(activityLogs);
    const selectedForStatus = selectedRows?.filter(r => activityLookup?.[r]?.activityStatus == fromStatus)

    useEffect(() => {

    }, [selectedRows]);

    const onSave = async () => {
        triggerPrompt({
            title: `Move to ${activityStatusById[updateStatusTo]?.name}`,
            content: <div>{`Are you sure you want to move ${selectedForStatus?.length ?? 0} activities to ${activityStatusById[updateStatusTo]?.name}?`}</div>,
            onConfirm: async () => {
                const res = await updateStatus({
                    activityLogIds: selectedForStatus,
                });
                if (res?.error) {
                    NotificationProvider.error(`An error occured while updating activities, please try again.`);
                    return;
                } else {
                    NotificationProvider.success('Successfully updated the activities');

                }
            },
        });
    };


    return (
        <Tooltip title={bulk ? `${selectedForStatus?.length ?? 0} out of ${selectedForStatus?.length ?? 0} selected row(s) are in status ${activityStatusById[fromStatus]?.name}` : ''}>
            <div className={bulk && classes.btn}>
                <Button startIcon={bulk ? undefined : <ExitToAppOutlinedIcon style={{ fontSize: '1.2em' }} />} variant={'text'} disabled={!selectedForStatus?.length || isLoading} onClick={onSave} color={bulk ? 'primary' : undefined}>
                    {bulk ? `Move to ${activityStatusById[updateStatusTo]?.name} (${selectedForStatus?.length})` : `Move to ${activityStatusById[updateStatusTo]?.name}`}
                </Button>
            </div>
        </Tooltip>
    )
}

export default UpdateActivityStatus;