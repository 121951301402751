import { Button, Checkbox, Dialog, DialogActions, DialogContent, Divider, FormControlLabel, Grid, Link, Tooltip } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AttachmentsView from "features/attachments/AttachmentsView";
import { AttachmentEntityTypes } from "features/attachments/attachmentsConsts";
import usePrompt from "hooks/usePrompt";
import moment from "moment";
import { useEffect, useState } from "react";
import CustomInput from "../../../../../components/CustomInput";
import DatePicker from "../../../../../components/DatePicker";
import NotificationProvider from "../../../../../components/NotificationProvider";
import FormatService from "../../../../../services/formatService";
import { useRefreshAttachmentsMutation, useSaveInvoiceMutation, useUpdateInvoiceStatusMutation } from "../../loadInvoiceSlice";
import InvoiceStatusDropdown from "../../../shared/InvoiceStatusDropdown";
import { AddService } from "./AddService";
import { EditWrapper } from "./EditWrapper";
import { invoiceDetailsStyle } from "./InvoiceDetailsStyle";
import { Service } from "./Service";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { InvoiceStatuses,InvoiceStatusList } from "features/invoice/loads/loadInvoiceConsts";
import { NoteEntityTypes } from "features/notes/notesConsts";

const InvoiceDetails = ({ invoice, onClose, isReadOnlyMode = false , IsStatusReadOnly = false }) => {

    let [saveInvoice, { isLoading: isSaving, isSuccess: saveSuccess }] = useSaveInvoiceMutation();
    let [refreshAttachments, { isLoading: isRefreshingAttachments, isSuccess: isRefreshingAttachmentsSuccess }] = useRefreshAttachmentsMutation();
    
    const editable = !isReadOnlyMode;
    const classes = invoiceDetailsStyle();
    const { triggerPrompt } = usePrompt();

    const invoiceFields = [
        {
            name: "Asset Id",
            key: "vin",
            component: () => <>{invoice?.vehicles?.[0]?.assetId || '-'}</>
        },
        {
            name: "QB Bill Number",
            key: "invoiceNumber",
            editable: editable,
            editComponent: () => <CustomInput
                label='Invoice Number'
                value={invoiceToEdit?.invoiceNumber}
                onChange={(val) => onFieldChange('invoiceNumber', val)}
                elementType="input"
            />
        },
        {
            name: "QB Invoice Number",
            key: "quickbooksInvoiceDocumentNumber",
        },
        {
            name: "Invoice Date",
            key: "issueDate",
            editable: editable,
            component: (date) => <>{FormatService.formatDate(date)}</>,
            editComponent: () => <DatePicker
                title="Date"
                value={moment(invoiceToEdit?.issueDate).format("YYYY-MM-DD")}
                onSelect={(val) => onFieldChange('issueDate', val)} />
        },
        {
            name: "Vendor",
            key: "quickBooksVendorId",
            editable: editable,
            component: (id) => <>{invoiceToEdit?.vendors?.find(t => t.id === id)?.displayName ?? '-'}</>,
            editComponent: () => <CustomInput
                value={invoiceToEdit?.quickBooksVendorId}
                onChange={(val) => onFieldChange('quickBooksVendorId', val)}
                elementType="dropdown"
                required={true}
                values={invoiceToEdit?.vendors?.map(i => ({ ...i, name: i.displayName }))}
            />
        },
        {
            name: "Customer",
            key: "quickBooksCustomerId",
            editable: editable,
            component: (id) => <> {invoiceToEdit?.customers?.find(t => t.id === id)?.fullyQualifiedName ?? '-'}</>,
            editComponent: () => <CustomInput
                value={invoiceToEdit?.quickBooksCustomerId}
                onChange={(val) => onFieldChange('quickBooksCustomerId', val)}
                elementType="dropdown"
                required={true}
                values={invoiceToEdit?.customers?.map(i => ({ ...i, name: i.fullyQualifiedName }))}
            />
        },
        {
            name: "Departure",
            key: "pickupLotName"
        },
        {
            name: "Arrival",
            key: "deliveryLotName"
        },
        {
            name: "Distance",
            key: "miles"
        },
        {
            name: "No Charge Client",
            key: "noChargeClient",
            editable: editable,
            component: (val) => <> {val == true ? 'True' : 'False'}</>,
            editComponent: () => <FormControlLabel
                control={<Checkbox
                    checked={invoiceToEdit?.noChargeClient}
                    onChange={(e, value) => onFieldChange("noChargeClient", value)}
                />}
            />
        },
        {
            name: "Send invoice PDF to",
            key: "sendInvoicePdfTo",
            editable: editable,
            editComponent: () => <CustomInput
                label='Send invoice PDF to'
                value={invoiceToEdit?.sendInvoicePdfTo}
                onChange={(val) => onFieldChange('sendInvoicePdfTo', val)}
                elementType="input"
            />
        },
    ];

    const allFields = [
        {
            fields: invoiceFields,
        },
    ]

    const [invoiceToEdit, setInvoiceToEdit] = useState();

    useEffect(() => {
        setInvoiceToEdit({ ...invoice })
    }, [invoice])

    const onInvoiceSave = async () => {
        const fields = invoiceFields.filter(f => f.editable)?.map(f => f.key);
        const invoiceToSave = {
            id: invoiceToEdit.id,

        }
        fields.map(f => {
            invoiceToSave[f] = invoiceToEdit[f];
        })
        const result = await saveInvoice(invoiceToSave);
        if (result?.error) {
            NotificationProvider.error(`Failed to update invoice`)
        } else {
            NotificationProvider.success(`Successfully updated invoice`);
        }

    }

    const onFieldChange = async (fieldName, value) => {
        setInvoiceToEdit({
            ...invoiceToEdit,
            [fieldName]: value
        })
    }

    return (
        <Dialog maxWidth='md' fullWidth open={!!invoice} onClose={() => {
            setInvoiceToEdit();
            onClose();
        }} aria-labelledby='dialog-title'>
            <DialogContent>
                <Grid container direction="column" spacing={2} alignItems='flex-end'>
                    <Link componenet={GetAppIcon}
                        href={invoiceToEdit?.invoiceDocumentUrl} target="_blank" color="primary" >
                        <Tooltip title='View PDF'><OpenInNewIcon /></Tooltip>
                    </Link>
                </Grid>
                <Grid container direction="column" spacing={2}>
                    {allFields?.map((group, groupIndex) => (
                        <Grid item className={classes.groupWrapper} key={groupIndex} >
                            <Grid container spacing={2} direction="column" >
                                {group.label && <Grid item className={classes.groupLabel} >{group.label}</Grid>}
                                {group.fields?.map((field, fieldIndex) =>
                                    <Grid item key={fieldIndex}>
                                        <Field invoice={invoiceToEdit} field={field} onSave={onInvoiceSave}  isReadOnlyMode={isReadOnlyMode}/>
                                    </Grid>)
                                } </Grid>
                        </Grid>
                    )
                    )}
                    <Grid item className={classes.services} >
                        <Services invoiceToEdit={invoiceToEdit} setInvoiceToEdit={setInvoiceToEdit}  isReadOnlyMode={isReadOnlyMode}/>
                    </Grid>
                    <Grid item >
                        <Grid container direction='column' spacing='5'>
                            <Grid item className={classes.totals}>
                                <Grid container alignContent='space-between' className={classes.totalSummary}>
                                    <Grid item xs={5} className={classes.label}>Total:</Grid>
                                    <Grid item xs={6} >${invoiceToEdit?.amount}</Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Divider />
                                <Grid container className={classes.groupLabel} justifyContent="space-between">
                                    <Grid item>
                                        Attachments
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            disabled={isRefreshingAttachments}
                                            onClick={async () => {
                                                triggerPrompt({
                                                    title: "Refresh Attachments",
                                                    content: "Are you sure you want to reload the attachments from LoadMate? This will remove the existing attachments.",
                                                    onConfirm: async () => {
                                                        await refreshAttachments(invoiceToEdit);
                                                    },
                                                });

                                            }}>
                                            Reload Attachments From LoadMate
                                        </Button>
                                    </Grid>
                                </Grid>
                                <AttachmentsView
                                    entityId={invoiceToEdit?.id}
                                    entityType={AttachmentEntityTypes.LOAD_INVOICE}
                                    addPermissionProfile={permissionProfiles.INVOICE.EDIT_TRANSPORTATION_INVOICE}
                                />
                                <Divider />
                            </Grid>
                            <Grid item className={classes.totals}>
                                <InvoiceStatusDropdown invoice={invoiceToEdit}
                                    invoiceId={invoiceToEdit?.id}
                                    isDisabled={IsStatusReadOnly}
                                    showConfirmationFor={InvoiceStatuses.REJECTED}
                                    noteEntityType={NoteEntityTypes.TRANSPORTINVOICES}
                                    confirmationTitle={"Reject"}
                                    invoiceStatusList={InvoiceStatusList}
                                    updateMutation={useUpdateInvoiceStatusMutation}/>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    )
}
export default InvoiceDetails;

const Field = ({ invoice, field, onSave , isReadOnlyMode = false }) => {
    const classes = invoiceDetailsStyle();
    const [saving, setSaving] = useState();

    const save = async () => {
        setSaving(true);
        await onSave();
        setSaving(false);
    }

    return (
        <Grid container >
            <Grid item xs={5} className={classes.label}>{field.name}</Grid>
            <Grid item xs={6} >
                <EditWrapper
                    editComponent={!invoice?.readonly && !isReadOnlyMode && field.editComponent && field.editComponent()}
                    readonlyComponent={(field.component ? field.component(invoice?.[field.key]) : invoice?.[field.key] ?? '-')}
                    onSave={save}
                    loading={saving}
                />
            </Grid>

        </Grid>
    )
}


const Services = ({ invoiceToEdit, setInvoiceToEdit,isReadOnlyMode = false }) => {
    const classes = invoiceDetailsStyle();

    return (
        <Grid container direction='column' spacing='2'>
            <Grid  ><Divider /></Grid>
            <Grid item className={classes.groupLabel} >Services</Grid>

            <Grid item xs={11} container className={classes.headers}>
                <Grid item xs={3}>Description</Grid>
                <Grid item xs={3}>Product/Service (invoice)</Grid>
                <Grid item xs={2}>Category (bill)</Grid>
                <Grid item xs={2} >Amount</Grid>
                <Grid item xs={1} >Pass Through</Grid>
            </Grid>

            {invoiceToEdit?.services?.map((service, serviceIndex) =>
                <Grid item key={serviceIndex}>
                    <Service service={service} invoice={invoiceToEdit} isReadOnlyMode={isReadOnlyMode} onDelete={(serviceId) => {
                        setInvoiceToEdit({
                            ...invoiceToEdit,
                            services: [
                                ...(invoiceToEdit.services?.filter(s => s.id !== serviceId) ?? []),
                            ]
                        })
                    }} />
                </Grid>)}
            {!invoiceToEdit?.readonly && !isReadOnlyMode &&  <Grid item>
                <AddService invoice={invoiceToEdit} onAdd={(newService) => {
                    if (newService) {
                        setInvoiceToEdit({
                            ...invoiceToEdit,
                            services: [
                                ...invoiceToEdit.services,
                                newService
                            ]
                        })
                    }
                }} />
            </Grid>}
            <Grid  ><Divider /></Grid>
        </Grid>
    )
}