import { Button, Checkbox, FormControlLabel, Grid, Paper, Typography } from "@material-ui/core";
import NotificationProvider from "components/NotificationProvider";
import { useState } from "react";
import { useAddVehicleMutation } from "../../../reservationSlice";
import LinkedVehicleSelector from "./LinkedVehicleSelector";
import { ReservationVehicleStyles } from "./ReservationVehicleStyles";
import { useGetPaveConfigByClientIdQuery } from "features/clients/clientConfigSlice";
import { reservationsPickupType } from "features/Reservation/reservationConsts";

export const AddVehicle = ({ reservation, setAdd, client,subClient, reservationId, vehiclesChosen }) => {

  const classes = ReservationVehicleStyles();
  const [assets, setAssets] = useState([]);
  const [generatePaveLink, setGeneratePaveLink] = useState();
  const [addVehicle, { isLoading: isAdding }] = useAddVehicleMutation();
  const [saving, setSaving] = useState(false);
  let { data: paveConfig, error: paveConfigError, isLoading: isPaveConfigLoading } = useGetPaveConfigByClientIdQuery({ clientId: client });

  const onSave = async () => {

    setSaving(true);
    let listReservationVehicles = assets?.map((a) => ({
      vehicleId: a.id,
      reservationId: reservationId,
    }));

    const body = { reservationVehicles: listReservationVehicles, generatePaveLink: generatePaveLink };

    const result = await addVehicle(body);
    if (result?.error) {
      NotificationProvider.error("Failed to add vehicle to reservation");
      setSaving(false);
    } else {
      NotificationProvider.success("The vehicle was added to the reservation");
      setSaving(false);
      setAdd(false);
    }
  };

  return (
    <Paper
      className={`${classes.listItemWrapper} ${classes.listItemWrapperNew}`}
    >
      <Grid container spacing={1} justifyContent="space-between" alignItems="center">
        <LinkedVehicleSelector
          assets={assets}
          setAssets={setAssets}
          selectedClient={client}
          selectedSubClient={subClient}
          vehiclesChosen={vehiclesChosen}
        ></LinkedVehicleSelector>
        {reservation?.pickupType == reservationsPickupType.TransportToDSP && paveConfig?.hasPaveConfig && <FormControlLabel
          control={<Checkbox
            onChange={(e, value) => setGeneratePaveLink(value)}
          />}
          label={<Typography fontSize={12} >Generate PAVE Link?</Typography>}
        />}
        <Grid item className={classes.btnWrapper}>
          <Button
            onClick={() => {
              setAdd(false);
            }}
            className={classes.button}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={onSave}
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={saving}
          >
            {saving ? "Saving..." : "Save"}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};
