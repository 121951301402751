import { Grid, Paper, Switch } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import CustomInput from "components/CustomInput";
import { calculateStorageInvoiceBy, storageInvoiceTypes, storageInvoiceTypesList } from "features/invoice/storage/storageInvoiceConsts";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { IconDollar, IconParkingLot, IconPercent, IconSlip } from "../../../../../icons";
import configStyles from "../../configStyles";
import LotsConfigStyle from "../LotsConfigStyle";
import {
    resetBulkUpdate,
} from "../lotConfigSlice";
import { TagInput } from "components/TagInput/TagInput";
import Validator from "services/validator";

const useStyles = LotsConfigStyle;
const useConfigStyles = configStyles;

export const clientLotConfigModel = {
    pricePerSpot: 0,
    overage: 0,
    numberOfSpots: 0,
    clientAlias: ''
}

const Pricing = ({
    clientToEdit,
    onConfigChange
}) => {
    const configClasses = useConfigStyles();
    const classes = useStyles();
    const configName = 'lotConfig';
    const lotConfigParams = clientToEdit?.config?.[configName];

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetBulkUpdate());
        }

    }, []);

    const priceField = (fieldName, label, icon) => (
        field(
            fieldName,
            label,
            <CustomInput
                value={lotConfigParams?.[fieldName]}
                onChange={(val) => onConfigChange(configName, fieldName, !!val.length ? parseInt(val) : 0)}
                icon={icon ? icon : <IconPercent />}
                elementType="input"
                numberOnly
            />, undefined, permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING
        )
    )

    const field = (fieldName, label, component, icon, profile) => (
        <Authorize profile={profile}>
            <Grid item className={configClasses.input}>
                <div className={configClasses.label}>{label} </div>
                <div className={configClasses.inputField}>
                    {component ??
                        <Authorize
                            profile={profile}
                            readonlyComponent={<div><b>{lotConfigParams?.[fieldName]}</b></div>}
                        >
                            <CustomInput
                                value={lotConfigParams?.[fieldName]}
                                onChange={(val) => onConfigChange(configName, fieldName, !!val.length ? parseInt(val) : 0)}
                                icon={icon}
                                elementType="input"
                                numberOnly
                            />
                        </Authorize>}
                </div>
            </Grid>
        </Authorize>
    )

    const isMonthly = lotConfigParams?.invoiceCycleType == storageInvoiceTypes.MONTHLY;
    return (
        <>
            <Paper className={configClasses.card}>
                <Grid container direction='column' spacing={1} xs={9}>
                    {field('invoiceCycleType', 'Automate Billing', <Authorize
                        profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>
                        <CustomInput
                            value={lotConfigParams?.invoiceCycleType}
                            onChange={(val) => onConfigChange(configName, 'invoiceCycleType', parseInt(val))}
                            elementType="dropdown"
                            showEmpty
                            values={storageInvoiceTypesList}
                        />
                    </Authorize>
                    )}
                    {field('baseNumberOfSpots', 'Base number of spots', undefined,
                        <IconSlip />, permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_FIELDS)}
                    {isMonthly && priceField('lotMonthlyRetainer', 'Monthly retainer', <IconDollar />)}
                    {priceField('lotTechFee', 'Tech fee')}
                    {priceField('lotDiscount', 'Discount')}
                    {priceField('checkinFee', 'Check-in fee', <IconDollar />)}
                    {priceField('checkinFeeDayCount', 'Check-in fee day count', <></>)}
                    {isMonthly && field('lotCrossMarketOverage', 'Cross market overage', undefined,
                        <IconParkingLot />, permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING)}
                    {isMonthly && field('correctToRetainer', 'Correct to retainer', <Authorize
                        profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>
                        <Switch
                            color="primary"
                            checked={lotConfigParams?.correctToRetainer}
                            onChange={(e) => onConfigChange(configName, 'correctToRetainer', e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        /></Authorize>, undefined, permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING)}

                    {field('otherClientOccupied', 'Show occupied lots by other clients', <Authorize
                        profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>
                        <Switch
                            color="primary"
                            checked={lotConfigParams?.otherClientOccupied != false}
                            onChange={(e) => onConfigChange(configName, 'otherClientOccupied', e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        /></Authorize>, undefined, permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING)}

                    {field('lotContactsHidden', 'Hide Lot Contacts from Client', <Authorize
                        profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_CONTACTs_HIDDEN}>
                        <Switch
                            color="primary"
                            checked={lotConfigParams?.lotContactsHidden}
                            onChange={(e) => onConfigChange(configName, 'lotContactsHidden', e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        /></Authorize>, undefined, permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_CONTACTs_HIDDEN)}

                    {field('daysUntilStorageReview', 'Days until storage review', undefined,
                        undefined, permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING)}
                    {field('seeFlag', 'Client can view flags', <Authorize
                        profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>
                        <Switch
                            color="primary"
                            checked={lotConfigParams?.seeFlags}
                            onChange={(e) => onConfigChange(configName, 'seeFlags', e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        /></Authorize>, undefined, permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING)}

                    {field('calculateStorageInvoiceBy', 'Calculate storage invoice by', <Authorize
                        profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>
                        <CustomInput
                            value={lotConfigParams?.calculateInvoiceBy}
                            onChange={(val) => onConfigChange(configName, 'calculateInvoiceBy', parseInt(val))}
                            elementType="dropdown"
                            showEmpty
                            values={calculateStorageInvoiceBy}
                        />
                    </Authorize>
                    )}
                    {field('invoiceEmails', 'Send invoice PDF to', <Authorize
                        profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>
                        <TagInput
                            placeholder={'Add email'}
                            tagString={lotConfigParams?.invoiceEmails ?? ""}
                            onChange={(val) => {
                                onConfigChange(configName, 'invoiceEmails', val)
                            }}
                            validateFunc={(tag) => {
                                const res = Validator.validateEmail(tag)
                                return !!res;
                            }}
                        />
                    </Authorize>
                    )}
                </Grid>
            </Paper>
            <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>
                <Paper className={configClasses.card}>
                    <div><b>Storage Reports</b></div>
                    <Grid container direction='column' spacing={1} xs={9}>
                        {field('usingAmzStorageReport', 'Include in Amazon storage report', <Authorize
                            profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>
                            <Switch
                                color="primary"
                                checked={lotConfigParams?.usingAmzStorageReport}
                                onChange={(e) => onConfigChange(configName, 'usingAmzStorageReport', e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            /></Authorize>, undefined, permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING)}

                        {field('runStorageReport', 'Run a daily storage report', <Authorize
                            profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>
                            <Switch
                                color="primary"
                                checked={lotConfigParams?.runStorageReport}
                                onChange={(e) => onConfigChange(configName, 'runStorageReport', e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            /></Authorize>, undefined, permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING)}

                        {lotConfigParams?.runStorageReport && field('allAssetsInDailyReport', 'Include all assets in the report', <Authorize
                            profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>
                            <Switch
                                color="primary"
                                checked={lotConfigParams?.allAssetsInDailyReport}
                                onChange={(e) => onConfigChange(configName, 'allAssetsInDailyReport', e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            /></Authorize>, undefined, permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING)}

                        {lotConfigParams?.runStorageReport && field('storageReportEmails', 'Send report to', <Authorize
                            profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>
                            <TagInput
                                placeholder={'Add email'}
                                tagString={lotConfigParams?.storageReportEmails ?? ""}
                                onChange={(val) => {
                                    onConfigChange(configName, 'storageReportEmails', val)
                                }}
                                validateFunc={(tag) => {
                                    const res = Validator.validateEmail(tag)
                                    return !!res;
                                }}
                            />
                        </Authorize>
                        )}
                    </Grid>
                </Paper>
            </Authorize>
        </>
    )
}
export default Pricing;