import { Grid, Link } from "@material-ui/core";
import { IssueTrackerLinksStyle } from "./style";
import { IconTransport, IconVehicle ,IconReservations } from "icons";
import { IconActivityDefault } from "icons/mechanicals";
import ReservationButton from 'components/ReservationButton/ReservationButton';
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";

export const IssueTrackerLinks = ({
    issue,
    showWorkorders,
    showAssets,
    showLoads,
    showReservation
}) => {
    const classes = IssueTrackerLinksStyle();

    return (<Grid container direction="column" >
        <div className={classes.linkedAssets}>
            {showAssets && issue?.linkedAssets?.map((asset, index) =>
                <Grid item container spacing={1} key={index} className={classes.noMarginBottom}>
                    <Grid item className={classes.iconWrapper}><IconVehicle /></Grid>
                    <Grid item><Link target='_blank' href={`/assets/detail/${asset?.id}`} className={classes.link}>{asset?.descriptor || asset?.assetId || asset?.id}</Link></Grid>
                </Grid >)}
        </div>
        {showWorkorders && issue?.linkedWorkOrders?.map((wo, index) =>
            <Grid item container spacing={1} className={classes.woLink} key={index}>
                <Grid item className={classes.iconWrapper}><IconActivityDefault /></Grid>
                <Grid item><Link className={classes.woLink} target='_blank' href={`/workorder/${wo?.id}`}>{wo?.id}</Link></Grid>
            </Grid>)}
        {showLoads && issue?.linkedLoads?.map((load, index) =>
            <Grid item container spacing={1} className={classes.loadLink} key={index}>
                <Grid item className={classes.iconWrapper}><IconTransport /></Grid>
                <Grid item><Link className={classes.loadLink} target='_blank' href={`/load/group/${load?.groupId}`}>{load?.id}</Link></Grid>
            </Grid>)}
        {showReservation && issue?.linkedReservations?.map((reservation, index) =>
        <Grid item container alignItems='center' spacing={1} className={classes.loadLink} key={index}>
            <Authorize profile={permissionProfiles.RESERVATIONS.VIEW}>
                <Grid item className={classes.iconWrapper}><IconReservations /></Grid>
                <Grid item><Link className={classes.loadLink} target='_blank' href={`/reservations/${reservation?.id}`}>{reservation.resId}</Link></Grid>
            </Authorize>
        </Grid>)}
    </Grid>
    )
}