
export const TextElement = ({ value, fontSize = '1em', parse = true }) => {
    
    let valueStr = value + "";
    try {
        valueStr = (value && parse ? JSON.parse(value ?? "") : "")?.toString()
    } catch (error) {
        console.log(`TextElement Failed to format value ${value + ""}`)
    }

    return (
        <div style={{ fontSize }}>{valueStr}</div>
    )
}