import CircularProgress from "@material-ui/core/CircularProgress";
import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { selectIsClient } from "../user/userSlice";
import ConditionReportPopup from "./ConditionReportPopup";
import EditAssetPopup from "./EditAssetPopup/EditAssetPopup";
import { useGetConfigurationByVehicleQuery } from "./configurationSlice";
import formStyles from "./formStyles";
import { permissionProfiles } from "components/Authorize/permissionProfiles";

const useStyles = formStyles;

const ParentEditAssetModal = ({ handleClose, asset, updateAsset, openPhotos }) => {

    const classes = useStyles();
    let isClient = useSelector(selectIsClient);
    const [selectedTab, setSelectedTab] = useState(0);

    let {
        data: configuration, error: configurationError,
        isLoading: isLoadingConfiguration
    } = useGetConfigurationByVehicleQuery(asset.id);

    useEffect(() => {
        setSelectedTab(0);
    }, [])


    if (isLoadingConfiguration) {
        return <CircularProgress className={classes.spinner} />;
    }

    let isConditionalReportEnable = configuration?.useConditionalReport && !(configuration?.useCustomConditionReport);

    const tabs = [
        {
            label: 'Basic Data',
            //component: <EditAssetPopup asset={asset} handleClose={() => handleClose(null)} updateAsset={updateAsset} openPhotos={openPhotos} />
        },
        {
            label: 'CR Data',
        }
    ];

    return (
        <div>
            <div style={{
                position: 'absolute',
                top: '0.5em',
                right: "4em"
            }}>
                {
                    isConditionalReportEnable &&
                    <TabsWrapper tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                }
            </div>
            {
                <div>

                    {selectedTab == 0 && <EditAssetPopup asset={asset} handleClose={() => handleClose(null)} updateAsset={updateAsset} openPhotos={openPhotos} />}
                    {selectedTab == 1 && <ConditionReportPopup asset={asset} handleClose={() => handleClose(null)}
                        updateAsset={updateAsset} />}
                </div>
            }

        </div>

    );
};

export default ParentEditAssetModal;
