import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import VendorFields from "./VendorFields";
import { Alert } from "@mui/material";
import { useState } from "react";
import { vendorsStyle } from "./vendorsStyle";
import { useCreateVendorMutation, useGetAllVendorsQuery, useUpdateVendorMutation } from "./vendorSlice";
import NotificationProvider from "components/NotificationProvider";
import Validator from "services/validator";

const EditVendorDialog = ({ open, vendor, setVendor, onClose, vendorId }) => {
    const classes = vendorsStyle();

    let { data: vendorFromApi, error: vendorError, isLoading: isLoadingVendor } = useGetAllVendorsQuery(vendorId, { skip: !vendorId || !!vendor });
    
    const vendorData = vendor ?? vendorFromApi?.[0] ?? [];

    const [errors, setErrors] = useState();
    const [valid, setValid] = useState(true);
    const [updateVendor, { isLoading: isUpdating }] = useUpdateVendorMutation();
    const [addVendor, { isLoading: isAdding }] = useCreateVendorMutation();

    const isSaving = isUpdating || isAdding;

    const onChange = (key, val) => {   
        setVendor({...vendorData, [key]: val});        

        setErrors({ ...errors, [key]: val ? undefined : errors[key] });
    }

    const onChangePocField = (key, val, pocIndex) => {
        const pocArray = vendorData?.pocInfo?.length 
            ? [...vendorData?.pocInfo] : [];

        pocArray[pocIndex] = { ...pocArray[pocIndex], [key]: val, 'isPrimary': pocIndex === 0 };

        setVendor({ ...vendorData, pocInfo: pocArray?.length ? pocArray : [{ [key]: val, 'isPrimary': pocIndex === 0 }] });
    }

    const displayError = (field, severity='error') => {
        return !!errors?.[field] && <Grid className={classes.alertWrapper}>
            <Alert severity={severity} >
                {errors?.[field]}
            </Alert>
        </Grid>
    }

    const onSave = async () => {
        if (!validate()) {
            return;
        }

        let result;
        if(vendorData?.id)
        {
            result = await updateVendor(vendorData);
        } else {
            result = await addVendor(vendorData);
        }

        if (result && !result.error) {
            NotificationProvider.success("Vendor saved successfully");
            onClose()
        } else {
            NotificationProvider.error("Failed to save vendor");
        }
    }

    const validate = () => {
        const msgs = {};
        let valid = true;

        const pocInfo = vendorData?.pocInfo;

        if (!vendorData?.name) { valid = false; msgs['carrierName'] = 'Carrier name is required' };
        
        pocInfo?.map((poc, index) => {
            if ((poc?.email!=null && poc?.email!="") && !Validator.validateEmail(poc?.email)) { valid = false; msgs[`pocEmail${index}`] = "Email invalid" };
            if (poc?.phone!=null && !Validator.validatePhone(poc?.phone)) { valid = false; msgs[`pocPhone${index}`] = "Phone invalid" };

            if(poc?.address)
            {
                if (!poc?.city) { valid = false; msgs[`city${index}`] = 'City is required' };
                if (!poc?.state) { valid = false; msgs[`state${index}`] = 'State is required' };
                if (!poc?.zipCode) { valid = false; msgs[`zipcode${index}`] = 'Zipcode is required' };
            }
        })

        setErrors(msgs);
        valid ? setValid(true) : setValid(false);
        
        return valid;
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth='md'>
            <DialogTitle>{`${vendorData?.id ? "Edit " : "Add "} Carrier`}</DialogTitle>
            <DialogContent>
                <VendorFields
                    vendor={vendorData} 
                    onChange={onChange} 
                    onChangePocField={onChangePocField} 
                    displayError={displayError} 
                    isNew={true} 
                />
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="space-between" direction='row-reverse'>
                    <Grid item={4}>
                        <Button 
                            onClick={onClose} 
                            color="primary" 
                            variant='outlined'
                            disabled={isSaving}
                            className={classes.button}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={onSave}
                            color="primary"
                            variant="contained"
                            disabled={isSaving}
                        >
                            {isSaving ? 'Saving...' : 'Save'}
                        </Button>
                    </Grid>
                    {!valid ? <Grid item xs={8}>
                        <Alert severity={'error'}>
                            Please correct all errors
                        </Alert>
                    </Grid>:<></>}
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default EditVendorDialog;