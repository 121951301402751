import { makeStyles } from "@material-ui/core";

export const CarrierInfoStyle = makeStyles((theme) => ({
    carrierField: {
        border: "solid 1px lightgrey",
        borderRadius: '5px',
        marginTop: 20,
        padding: '1em'
    },
    input: {
        margin: 16,
    },
    radioLable: {
        paddingRight: 10,
    },
    radioContainer: {
        paddingLeft: '1.5em',
        fontSize: 16,
    },
    radioButton: {
        margin: '0.5rem',
    },
    labelCarrier: {
        fontSize: '0.9em',
        color: '#8793A1'
    },
    card: {
        padding: '1.5em',
        height: '100%',
    },
    info: {
        marginTop: '1em'
    },
    title: {
        fontWeight: 500,
        fontSize: '1.2rem',
        color: theme.palette.primary.main,
    },
    icon: {
        marginRight: '.5em'
    },
    label: {
       '& label': {
            fontWeight: 'bold',
        }
    },
    section: {
        fontWeight: 'bold',
        padding: '1em 0 1em'
    },
    carrierTitle: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        margin: '1em 0em 1em',
        fontSize: 15
    },
    button: {
        marginTop: '1em'
    }
}));