import { Button, Grid, Paper } from '@material-ui/core';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import { IconMap, IconPhoto } from 'icons';
import { useSelector } from "react-redux";
import BasicModal from '../../../components/BasicModal';
import mobileBreakpoint from "../../../utils/mobileBreakpoint";
import { useGetClientsQuery } from "../../clients/clientSlice";
import { useGetLoadsQuery } from '../../loads/loadsSlice';
import { useGetLotsQuery } from '../../lots/lotSlice';
import { VehicleMap } from '../../maps/VehicleMap/VehicleMap';
import { selectIsAdmin } from "../../user/userSlice";
import ParentEditAssetModal from '../ParentEditAssetModal';
import {
    useGetActivityLogQuery,
    useGetVehicleDetailsQuery,
    useGetVehicleTypesQuery,
} from '../vehicleSlice';
import { VehicleAccess } from './VehicleAccess/VehicleAccess';
import VehicleActivityLog from './VehicleActivityLog/VehicleActivityLog';
import { vehicleDetailsPageStyle } from "./VehicleDetailsPageStyle";
import VehicleDetailsSummary from './VehicleDetailsSummary';
import { VehicleDocuments } from './VehicleDocuments/VehicleDocuments';
import { VehicleNotes } from './VehicleNotes';
import { VehicleTabs, vehicleTabTypes } from './VehicleTabs/VehicleTabs';
import { VehicleCustomFormList } from '../VehicleCustomForm/VehicleCustomFormList';
import { isAuthorized } from "../../../components/Authorize/authorizeUtil";
import { formTypes } from 'features/Forms/formBuilder/formBuilderConsts';

const VehicleDetailsPage = () => {
    const classes = vehicleDetailsPageStyle();
    const isMobile = useMediaQuery(mobileBreakpoint);
    const isAdmin = useSelector(selectIsAdmin);
    const [assetToEdit, setAssetToEdit] = useState(null);
    const [openPhotos, setOpenPhots] = useState(false);
    const [selectedTab, setSelectedTab] = useState(vehicleTabTypes.DETAILS);
    const seamlessToken = useSelector(state => state.seamlessToken.token);
    const user = useSelector(state => state.oidc.user);

    const hasToken = seamlessToken || !!user;
    const isSeamless = !!seamlessToken && !user;

    let { id } = useParams();

    let {
        data: vehicleDetails,
        error,
        isLoading: isLoadingVehicle,
    } = useGetVehicleDetailsQuery(id, { skip: !hasToken });
    let {
        data: lots,
        error: lotError,
        isLoading: isLoadingLots,
    } = useGetLotsQuery(null, { skip: !hasToken });
    let {
        data: vehicleTypes,
        error: vehicleTypesError,
        isLoading: isLoadingVehicleTypes
    } = useGetVehicleTypesQuery(null, { skip: !hasToken });
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null, { skip: !isAdmin });
    let { data: loads, error: loadListError, isLoading: isLoadingLoad } = useGetLoadsQuery({}, { skip: !hasToken });
    let { data: activityLogData, error: activityLogError, isLoading: isLoadingActivityLog, refetch: refetchActivityLog } = useGetActivityLogQuery(id, { skip: !vehicleDetails });

    clients = clients || [];
    vehicleTypes = vehicleTypes || [];

    if (isLoadingVehicle || isLoadingLots || isLoadingClients) {
        return <CircularProgress className={classes.spinner} />
    }

    if (!vehicleDetails) {
        return (
            <div className={classes.noAssetDiv}>
                <h2>This asset does not exist</h2>
            </div>
        )
    }

    const findLot = (lot_id) => {
        return lots?.find(l => l.id === lot_id) || {};
    }

    let client = clients?.find(c => c.clientAccounts.find(ca => ca.id === vehicleDetails?.customerId));
    let lot = findLot(vehicleDetails?.lotId);

    lot = lot || {};

    const saveActivity = (vehicleDetailsId, date) => {
        setAssetToEdit(null);
        setOpenPhots(false);
    };

    const onEdit = (openPhotos) => {
        setOpenPhots(openPhotos);
        setAssetToEdit(vehicleDetails);
    };

    const latestLoad = loads?.find(l => l.assets.find(a => a.id === vehicleDetails?.id));
    const canSeePhotos = isAuthorized(permissionProfiles.ASSETS.ASSETS_DETAIL_PAGE_VIEW_PHOTO);
    const mapSize = canSeePhotos ? 6 : 12;

    const tabComponents = {
        [vehicleTabTypes.ACCESS]: <VehicleAccess vehicleId={id} clientId={client?.id} />,
        [vehicleTabTypes.CHECK_IN]: <VehicleCustomFormList vehicle={vehicleDetails} formType={formTypes.Checkin} />,
        [vehicleTabTypes.CHECK_OUT]: <VehicleCustomFormList vehicle={vehicleDetails} formType={formTypes.Checkout} />,
        [vehicleTabTypes.CONDITION_REPORT]: <VehicleCustomFormList vehicle={vehicleDetails} formType={formTypes.ConditionReport} />,
        [vehicleTabTypes.DETAILS]: <>
            <Grid container spacing={2}>
                <Grid item md={mapSize}>
                    <Paper className={classes.card}>
                        <VehicleCardHeader title='Map' icon={<IconMap />} />
                        <div className={classes.sideBySide}>
                            <VehicleMap
                                vehicleDetails={vehicleDetails}
                                activityLogData={activityLogData ?? []}
                                latestLoad={latestLoad}
                            />
                        </div>
                    </Paper>
                </Grid>
                <Authorize profile={permissionProfiles.ASSETS.ASSETS_DETAIL_PAGE_VIEW_PHOTO}>
                    <Grid item md={6}>
                        <Paper className={classes.card}>
                            <VehicleCardHeader title='Photos & Documents' icon={<IconPhoto />} actions={
                                <>
                                    <Authorize profile={permissionProfiles.ASSETS.ASSETS_DETAIL_PAGE_EDIT_ADD_PHOTO}>
                                        <Button className={classes.editButton}
                                            color="primary"
                                            variant="outlined"
                                            onClick={() => onEdit(true)}
                                        >
                                            Edit
                                        </Button>
                                    </Authorize>
                                </>
                            } />
                            <div className={classes.sideBySide}>
                                <VehicleDocuments vehicleDetails={vehicleDetails} latestLoad={latestLoad}
                                    onEdit={onEdit} />
                            </div>
                        </Paper>
                    </Grid>
                </Authorize>
            </Grid>

            <VehicleDetailsSummary
                vehicleDetails={vehicleDetails}
                minimalSummary={selectedTab == vehicleTabTypes.ACCESS || selectedTab == vehicleTabTypes.CHECK_IN || selectedTab == vehicleTabTypes.CHECK_OUT || selectedTab == vehicleTabTypes.CONDITION_REPORT}
                onEdit={onEdit}
            />

            <VehicleNotes vehicleDetails={vehicleDetails} />

            <Authorize profile={permissionProfiles.ASSETS.ASSETS_DETAIL_PAGE_VIEW_ACTIVITY_LOG}>
                <VehicleActivityLog activityLogData={activityLogData} refetchActivityLog={refetchActivityLog} />
            </Authorize>

            <BasicModal
                open={assetToEdit}
                header={`Editing ${assetToEdit?.descriptor}`}
                wide={true}
                handleClose={() => setAssetToEdit(null)}
                component={<ParentEditAssetModal openPhotos={openPhotos} asset={assetToEdit} handleClose={() => setAssetToEdit(null)} updateAsset={saveActivity} />}
            />
        </>
    }

    return (
        <>
            {
                !isSeamless && <VehicleTabs selected={selectedTab} setSelected={setSelectedTab} vehicleId={vehicleDetails.id} vehicleDetails={vehicleDetails} />
            }
            {tabComponents[selectedTab]}
        </>
    );
};

export default VehicleDetailsPage;

export const VehicleCardHeader = ({ title, icon, actions }) => {
    const classes = vehicleDetailsPageStyle();
    return (
        <Grid className={classes.titleWrapper} container alignItems='center' justifyContent='space-between'>
            <Grid item>
                <Grid container alignItems='center'>
                    {icon}
                    <h2 className={classes.title}>{title}</h2>
                </Grid>
            </Grid>
            <Grid item>
                {actions}
            </Grid>
        </Grid>
    )
}
