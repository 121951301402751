import { makeStyles } from "@material-ui/core";

export const EditFormOutputStyle = makeStyles((theme) => ({
    questionTitle: {
        margin: '0.5rem 0',
        fontSize: 16
    },
    addPhotoContainer: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        cursor: 'pointer',
        height: 140,
        width: 140,
        '&:hover': {
            opacity: 0.8
        }
    },
    image: {
        cursor: 'pointer'
    },
    form: {
        padding: '1rem'
    },
    notesAndPhotos: {
        margin: '0.5rem 0'
    },
    question: {
        padding: '1rem',
        margin: '1rem 0'
    },
    required: {
        color: 'red'
    },
    reportContainer: {
        position: 'relative',
        paddingBottom: '7em',
    },
    reportActions: {
        padding: '1em',
        background: 'white',
        position: 'fixed',
        width: 'fit-content',
        bottom: '2em',
        left: '50%',
        zIndex: 1,
        marginTop: '1rem',
    },
}))