import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import { orderBy } from "lodash";
import { arrayToObject } from "utils/object-util";
import { excludedTypes, inspectionsPresentAndDates, storageTypes, vdFieldGroup, vehicle_detail_extended_type, vehicleDetailExtendedGroups, vehicleDetailExtendedMulti, vehicleDetailExtendedTypeLabels, vehicleDetailExtendedValues } from "../VehicleDetailExtended/VehicleDetailExtendedTypes";
import { useGetExtendedFieldTypesQuery } from "../vehicleSlice";
import MonthYearPicker from "components/MonthYearPicker/MonthYearPicker";
import moment from "moment";
import DropdownFilter from "components/DropdownFilter";
import { editAssetStyle } from "./style";

export const EditExtendedInfo = ({ vehicleDetails, extendedInfo = [], onChangeField }) => {

    const classes = editAssetStyle();

    let { data: fieldConfig, error: fieldsError, isLoading: isLoadingFields } = useGetExtendedFieldTypesQuery();

    const data = arrayToObject(extendedInfo, 'vehicleDetailExtendedTypeId');

    const vehicleExtendedComponent = (field) => {
        if (vehicleDetailExtendedMulti?.[field?.id]) {
            let val = [];
            try {
                val = JSON.parse(data[field.id]?.value ?? "[]")
            } catch (e) {
            }
            return <DropdownFilter
                multiple
                title={vehicleDetailExtendedTypeLabels?.[field.id]}
                showEmpty
                value={val}
                values={vehicleDetailExtendedValues?.[field.id]}
                onSelect={val => {
                    onChangeField(field.id, JSON.stringify(val))
                }}
            />
        }
        if (vehicleDetailExtendedValues?.[field?.id]) {
            return <DropdownFilter
                title={vehicleDetailExtendedTypeLabels?.[field.id]}
                showEmpty
                value={data[field.id]?.value}
                values={vehicleDetailExtendedValues?.[field.id]}
                onSelect={val => onChangeField(field.id, val)}
            />
        }
        switch (field?.id) {
            case vehicle_detail_extended_type.keys_handed_over:
            case vehicle_detail_extended_type.fobs_handed_over:
            case vehicle_detail_extended_type.tire_tread_reading:
            case vehicle_detail_extended_type.tire_tread_reading_back_left:
            case vehicle_detail_extended_type.tire_tread_reading_back_right:
            case vehicle_detail_extended_type.tire_tread_reading_front_left:
            case vehicle_detail_extended_type.tire_tread_reading_front_right:
            case vehicle_detail_extended_type.check_under_hood:
            case vehicle_detail_extended_type.PassengerSideAttachments:
            case vehicle_detail_extended_type.VehicleWeight:
            case vehicle_detail_extended_type.InteriorAttachments:
            case vehicle_detail_extended_type.ItemsAttachedToBody:
            case vehicle_detail_extended_type.CustomField1:
                return <CustomInput
                    label={field.name}
                    value={data[field.id]?.value}
                    elementType="input"
                    onChange={val => onChangeField(field.id, val)}
                />;
            // case vehicle_detail_extended_type.storage_type:
            //     return <DropdownFilter
            //         title='Select storage type'
            //         showEmpty
            //         value={data[field.id]?.value}
            //         values={storageTypes}
            //         onSelect={val => onChangeField(field.id, val)}
            //     />
            case vehicle_detail_extended_type.DOT_last_inspection_date:
            case vehicle_detail_extended_type.last_state_inspection_date:
                return <MonthYearPicker
                    label={field.name}
                    onChange={(value) => {
                        const val = moment(value).format("YYYY-MM");
                        onChangeField(field.id, val);
                    }}
                    value={data[field.id]?.value} />
            default:
                return undefined;
        }
    }


    const defaultComponent = (field) => {
        return <FormControlLabel
            label={field?.name}
            control={<Checkbox
                title={field?.name}
                checked={data[field?.id]?.value == "Yes" ? true : false}
                onChange={(e, value) => onChangeField(field?.id, value, true)}
            />}
        />
    }

    const getField = (f) => {
        const comp = vehicleExtendedComponent(f);
        return ({
            component: comp ?? defaultComponent(f),
            type: comp ? '1' : '2',
            field: f
        });

    }

    const filteredFields = fieldConfig?.filter(f => !excludedTypes.includes(f.id)) ?? [];

    const fieldWithComponent = orderBy(filteredFields?.map(f => {
        return getField(f);
    }), 'type');

    const fieldConfigLookup = arrayToObject(filteredFields);
    const vdExtendedDataGrouped = {};

    //const allFields = fieldWithComponent.filter(f => !inspectionsPresentAndDates.includes(f.field.id));
    //const inspections = inspectionsPresentAndDates.map(f => getField(fieldConfigLookup[f]));
    // fieldWithComponent.map(field => {
    //     vdExtendedDataGrouped[vdFieldGroup[field.field.id]?.group] = [
    //         ...(vdExtendedDataGrouped[vdFieldGroup[[field.field.id]]?.group] ?? []),
    //         field
    //     ]
    // })

    Object.values(vehicleDetailExtendedGroups).map(group => {
        group.fields?.map(field => {
            vdExtendedDataGrouped[vdFieldGroup[field]?.group] = [
                ...(vdExtendedDataGrouped[vdFieldGroup[[field]]?.group] ?? []),
                ...(fieldConfigLookup[field] ? [getField(fieldConfigLookup[field])] : [])
            ]
        })
    })


    return (
        <>
            {/* {inspections?.map(f => <Grid item xs={6} container>
                {f.component}
            </Grid>)} */}
            {/* {allFields?.map(f => <Grid item xs={6} container>
                {f.component}
            </Grid>)} */}
            {Object.keys(vdExtendedDataGrouped)?.map(groupKey => <>
                <Grid className={classes.extendedFieldGroup} item xs={12}>{vehicleDetailExtendedGroups[groupKey]?.label}</Grid>
                {vdExtendedDataGrouped[groupKey].map(field => <Grid item xs={6} container>
                    {field.component}
                </Grid>)}
            </>)
            }
        </>
    );
}