import { Grid } from "@material-ui/core";
import { EditFormOutput } from "features/Forms/FormOutput/EditFormOutput/EditFormOutput";
import FormOutput from "features/Forms/FormOutput/FormOutput";
import { formTypes } from "features/Forms/formBuilder/formBuilderConsts";
import { customFormStyle } from "./style";

export const VehicleCustomForm = ({ vehicle, formType, customFormResponse, mode, isLoading }) => {
    var classes = customFormStyle();

    return (
        <>
            {mode == 'download' &&
                <FormOutput
                    loading={isLoading}
                    fileName={`${formType == formTypes.Checkin ? "checkin" : "checkout"}-${vehicle?.descriptor}.pdf`}
                    headerContent={
                        <Grid container alignItems='center' justifyContent="space-between" style={{ padding: '1em' }}>
                            <h2 className={`${classes.title}`}>{`${formType == formTypes.Checkin ? "Check-In" : formType == formTypes.Checkout ? "Check-out" : "Condition Report"} For Asset: ${vehicle?.descriptor ?? vehicle?.assetId}`}</h2>
                        </Grid>
                    }
                    {...customFormResponse}
                />}
            {mode == 'edit' && <EditFormOutput customFormResponse={customFormResponse} lotId={vehicle.lotId} formType={formType}/>}
        </>
    )
}