import { getNoteInvalidateTag } from 'features/notes/notesSlice';
import { baseApi } from '../../app/baseApi';
import { baseQuery } from "../../app/baseQuery";
import { apiTags } from '../../app/store/apiTags';
import { issueTrackerLinkEntityType } from './issueTrackerConsts';
import { NoteEntityTypes } from 'features/notes/notesConsts';

const apiPrefix = 'issuesTracker';

const getCacheKey = (args) => {
    return `${args.issueId}`
}

export const issuesTrackerApi = baseApi.injectEndpoints({
    reducerPath: 'issuesTrackerApi',
    tagTypes: [apiTags.ISSUES_TRACKER],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAllIssuesTracker: builder.query({
            query: ({ includeIssueTrackerHistory, includeRecentHistory = false,  vehicleId, populateLinks = true }) => `${apiPrefix}?includeIssueTrackerHistory=${includeIssueTrackerHistory}&includeRecentHistory=${includeRecentHistory}${vehicleId ? `&vehicleId=${vehicleId}` : ''}&populateLinks=${populateLinks}`,
            providesTags: [apiTags.ISSUES_TRACKER],
            transformResponse: (response) => {
                response.map(row => {
                    row.openMoreThanTwoWeeksStr = row?.openMoreThanTwoWeeks ? 'yes' : 'no';
                    row.isCompleteStr = row?.isComplete ? 'yes' : 'no';
                    row.overdueStr = row?.overdue ? 'yes' : 'no';
                    row.isAssigned = !!row?.assignee ? 'yes' : 'no';
                    row.status = row.latestStatus?.status;
                    row.latestStatusId = row?.latestStatus?.status;
                    row.activityItemIds = row?.linkedWorkOrders?.map(wo => wo.activityItemId) ?? [];
                    row.linkedToType = [];
                    if (row.hasLinkedLoads) {
                        row.linkedToType.push(issueTrackerLinkEntityType.Load);
                    }
                    if (row.hasLinkedWorkOrders) {
                        row.linkedToType.push(issueTrackerLinkEntityType.Workorder);
                    }
                    if (row.hasLinkedAssets) {
                        row.linkedToType.push(issueTrackerLinkEntityType.Asset);
                    }
                    if (row.hasLinkedReservations) {
                        row.linkedToType.push(issueTrackerLinkEntityType.Reservation);
                    }
                })
                return response;
            }
        }),
        getAllIssuesTrackerForUser: builder.query({
            query: () => `${apiPrefix}/forUser?withNames=false`,
            providesTags: [`${apiTags.ISSUES_TRACKER}_tasks`],
            transformResponse: (response) => {
                response.map(row => {
                    row.createdDateObj = new Date(row.createdDate);
                    row.issueDateObj = new Date(row.issueDate);
                })
                return response;
            }
        }),
        getIssueTrackerDashboardData: builder.query({
            query: ({ startDate, endDate }) => `${apiPrefix}/dashboard?${startDate && endDate ? `startDate=${startDate}&endDate=${endDate}` : ''}`,
            providesTags: [`${apiTags.ISSUES_TRACKER}_dashboard`],
        }),
        getIssueTrackerById: builder.query({
            query: ({ id }) => `${apiPrefix}/${id}`,
            providesTags: (result, error, arg) => [{ type: apiTags.ISSUES_TRACKER, id: getCacheKey({ issueId: arg?.id }) }],
        }),
        getStatusIssueTracker: builder.query({
            query: () => `${apiPrefix}/status`,
        }),
        getIssueTrackerCategories: builder.query({
            query: () => `${apiPrefix}/categories`,
        }),
        getIssueTrackerPriorities: builder.query({
            query: () => `${apiPrefix}/priorities`,
        }),
        addIssueTracker: builder.mutation({
            query: (issueTracker) => ({
                url: `${apiPrefix}`,
                method: "POST",
                body: issueTracker
            }),
            invalidatesTags: [
                apiTags.ISSUES_TRACKER,
                `${apiTags.ISSUES_TRACKER}_tasks`
            ],
        }),
        updateIssueTracker: builder.mutation({
            query: (issueTracker) => ({
                url: `${apiPrefix}`,
                method: "PUT",
                body: issueTracker
            }),
            invalidatesTags: (result, error, arg) => [
                apiTags.ISSUES_TRACKER,
                `${apiTags.ISSUES_TRACKER}_tasks`,
                getNoteInvalidateTag(arg.latestStatusId, NoteEntityTypes.ISSUE_TRACKER_STATUS),
                { type: apiTags.ISSUES_TRACKER, id: getCacheKey({ issueId: arg?.id }) }
            ]
        }),
        linkTickets: builder.mutation({
            query: ({ issueId, linkedIssueId }) => ({
                url: `${apiPrefix}/link`,
                method: "POST",
                body: {
                    issueId,
                    linkedIssueId
                }
            }),
            invalidatesTags: (result, error, arg) => [
                apiTags.ISSUES_TRACKER, //??
                { type: apiTags.ISSUES_TRACKER, id: getCacheKey(arg?.issueId) }
            ]
        }),
        removeLinkedTicket: builder.mutation({
            query: ({ issueId, linkedIssueId }) => ({
                url: `${apiPrefix}/link/delete`,
                method: "DELETE",
                body: {
                    issueId,
                    linkedIssueId
                }
            }),
            invalidatesTags: (result, error, arg) => [
                apiTags.ISSUES_TRACKER, //??
                { type: apiTags.ISSUES_TRACKER, id: getCacheKey(arg?.issueId) }
            ]
        })
    }),
});

export const {
    useGetAllIssuesTrackerQuery,
    useGetIssueTrackerDashboardDataQuery,
    useGetStatusIssueTrackerQuery,
    useGetIssueTrackerCategoriesQuery,
    useGetIssueTrackerPrioritiesQuery,
    useAddIssueTrackerMutation,
    useUpdateIssueTrackerMutation,
    useGetIssueTrackerByIdQuery,
    useGetAllIssuesTrackerForUserQuery,
    useLinkTicketsMutation,
    useRemoveLinkedTicketMutation
} = issuesTrackerApi;