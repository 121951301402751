import { modes } from "../modes";
import { permissions } from "../permissions/permissions";
import { roles } from "../roles";

export const carrierPermissionProfile = {
    VIEW: {
        [modes.EDIT_PERMISSION]: [permissions.CARRIERS.VIEW_CARRIERS],
        [modes.EDIT]: [roles.OWNER]
    },
    EDIT: {
        [modes.EDIT_PERMISSION]: [permissions.CARRIERS.EDIT_CARRIERS],
        [modes.EDIT]: [roles.OWNER]
    }
}