import { modes } from "../modes";
import { permissions } from "../permissions/permissions";
import { roles } from "../roles";

export const loadPermissionProfiles = {
    LOAD_PAYMENTS: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.ACCOUNT_MANAGER]
    },
    LOAD_IMPORT_FIELDS: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.ACCOUNT_MANAGER]
    },
    LOAD_EDIT_DATES: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER]
    },
    LOAD_LM_INFO: {
        [modes.EDIT]: [roles.OWNER]
    },
    LOAD_ACTIONS: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER]
    },
    LOAD_INVOICE: {
        [modes.READONLY_PERMISSION]: [permissions.LOADS.INVOICE],
    },
    LOAD_BULK_ACTIONS: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER]
    },
}