import { Button, Grid } from "@material-ui/core";
import DataListHeader from "components/DataListHeader";
import DataTable from "components/DataTable/DataTable";
import LoadingSpinner from "components/LoadingSpinner";
import PageComponent from "components/Page/PageComponent";
import useSortOptions from "hooks/useSortOptions";
import { IconEdit } from "icons";
import { orderBy } from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterService from "services/filterService";
import { arrayToObject } from "utils/object-util";
import EditVendorDialog from "./EditVendorDialog";
import { setSearchVal } from "./vendorFiltersSlice";
import { useGetAllVendorsQuery } from "./vendorSlice";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";

const VendorsPage = ({}) => {
    
    const dispatch = useDispatch();
    const { searchVal } = useSelector(state => state.vendorFilters);
    const [sortOptions, sortData] = useSortOptions();
    const [vendorToEdit, setVendorToEdit] = useState();
    const [openDialog, setOpenDialog] = useState(false);

    let { data: vendors, error: vendorsError, isLoading: isLoadingVendors } = useGetAllVendorsQuery();
    vendors = vendors || [];

    const getAddress = (row, index) => {
        let address;
       
        if (index == 0) {
            address = [row?.address, row?.city, row?.state, row?.zipcode]?.filter(Boolean)?.join(', ');
        } else {
            address = [row?.secAddress, row?.secCity, row?.secState, row?.secZipcode]?.filter(Boolean)?.join(', ');
        }
        return address;
    }

    const columns = [
        { name: "Name", key: "name" },
        { name: "SCAC", key: 'scac' },        
        { name: "POC Name", key: 'pocName' },
        { name: "POC Phone", key: 'pocPhone' },
        { name: "POC Email", key: 'pocEmail' },
        { name: "POC Address", component: (row) => getAddress(row, 0), width: 200 },
        { name: "Secondary POC Name", key: 'secPocName' },
        { name: "Secondary POC Phone", key: 'secPocPhone' },
        { name: "Secondary POC Email", key: 'secPocEmail' },
        { name: "Secondary POC Address", component: (row) => getAddress(row, 1) },
    ]    
    
    const vendorLookup = arrayToObject(vendors);

    const rowActions = (rowId) => {
        const row = vendorLookup[rowId];
        return [
            {
                icon: <IconEdit />,
                onClick: () => { setVendorToEdit(row); setOpenDialog(true); },
                profile: permissionProfiles.CARRIERS.EDIT
            }
        ];
    }

    const onSearch = (val) => {
        dispatch(setSearchVal(val));
    };

    const mapExportData = row => {
        const vendors = {};
        columns?.map(col => {
            if(col.name)
            {
                vendors[col.name] = row?.[col.key]
            }
        });
        vendors['POC Address'] = getAddress(row, 0);
        vendors['Secondary POC Address'] =  getAddress(row, 1);
        return vendors;
    }

    let filteredVendors = FilterService.filter([], searchVal, vendors);

    return (
        <PageComponent
            header={<DataListHeader
                title={'Carriers'}
                data={filteredVendors}
                onSearch={onSearch}
                actionButtons={
                    <Authorize profile={permissionProfiles.CARRIERS.EDIT}>
                        <Grid container direction='row' spacing={1}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {setVendorToEdit({}); setOpenDialog(true)}}
                                >
                                    Create Carrier
                                </Button>
                            </Grid >
                        </Grid>
                    </Authorize>
                }
            />}
        >
            {isLoadingVendors ? <LoadingSpinner loading={isLoadingVendors} /> :
            <DataTable
                mapExportData={mapExportData}
                noItemsMessage={isLoadingVendors ? 'Loading...' : 'No carriers found'}
                allowColumnCustomization={true}
                columns={columns}
                rows={orderBy(
                    filteredVendors,
                    sortOptions.columnToSort,
                    sortOptions.sortDirection
                )}
                rowIdentifier='id'
                onSort={sortData}
                sortDirection={sortOptions.sortDirection}
                columnToSort={sortOptions.columnToSort}
                title={'Carriers'}
                actions={rowActions}
                
            />}
            {!!vendorToEdit && <EditVendorDialog
                open={openDialog}
                onClose={() => {setVendorToEdit(); setOpenDialog(false)}}
                vendor={vendorToEdit}
                setVendor={setVendorToEdit}
            />}
        </PageComponent>
    )
};

export default VendorsPage;