import { Grid, Paper, TextField } from "@material-ui/core";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { orderBy } from "lodash";
import { arrayMove, sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { FormBuilderStyles } from "../style";
import FormQuestions from "./FormQuestions";
import SectionActions from "../SectionActions/SectionActions";

const FormSections = ({
    form,
    onFormFieldChange,
}) => {

    const classes = FormBuilderStyles();

    const sectionsSorted = orderBy(form?.sections ?? [], 'order');
    const mappedFieldsUsed = sectionsSorted?.flatMap(a => a.questions)?.map(q => ({ field: q?.mappedToField, type: q?.mappedToFieldType }));

    const onSectionChange = (index, fieldName, value) => {
        sectionsSorted[index] = { ...sectionsSorted[index], [fieldName]: value };
        onFormFieldChange('sections', sectionsSorted);
    }

    const updateQuestions = (sectionIndex, newQuestionList) => {
        onSectionChange(sectionIndex, 'questions', newQuestionList.map((q, index) => ({ ...q, order: index })));
    }

    const onDeleteQuestion = (sectionIndex, index) => {
        const questions = [...sectionsSorted[sectionIndex].questions];
        questions.splice(index, 1);
        updateQuestions(sectionIndex, questions);
    }

    const onDuplicateQuestion = (sectionIndex, index) => {
        const question = sectionsSorted[sectionIndex].questions?.[index];
        const questionToAdd = { ...question, id: undefined, mappedToField: undefined };
        updateQuestions(sectionIndex, [...sectionsSorted[sectionIndex]?.questions, questionToAdd]);
    }

    const onAddQuestion = (sectionIndex, questionType) => {
        updateQuestions(sectionIndex, [...(sectionsSorted[sectionIndex].questions ?? []), { questionType: +questionType }]);
    }

    const onDeleteSection = (sectionIndex) => {
        const sections = [...sectionsSorted];
        sections.splice(sectionIndex, 1);
        onFormFieldChange('sections', sections.map((q, index) => ({ ...q, order: index })))
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        onFormFieldChange('sections', arrayMove(sectionsSorted, oldIndex, newIndex).map((q, index) => ({ ...q, order: index })))
    };

    return (
        <SortableContainer onSortEnd={onSortEnd} useDragHandle>
            {sectionsSorted.map((section, index) => (
                <SortableItem
                    index={index}
                    section={section}
                    sectionIndex={index}
                    form={form}
                    onDeleteQuestion={onDeleteQuestion}
                    onDuplicateQuestion={onDuplicateQuestion}
                    onSectionChange={onSectionChange}
                    onAddQuestion={onAddQuestion}
                    onDeleteSection={() => onDeleteSection(index)}
                    mappedFieldsUsed={mappedFieldsUsed}
                />
            ))}
        </SortableContainer>
    );
}
export default FormSections;


const SortableItem = sortableElement(({
    section,
    sectionIndex,
    onSectionChange,
    form,
    onAddQuestion,
    onDeleteQuestion,
    onDuplicateQuestion,
    onDeleteSection,
    mappedFieldsUsed
}) => {
    const classes = FormBuilderStyles();

    const DragHandle = sortableHandle(() => <div className={classes.dragHandler}>
        <DragIndicatorIcon className={classes.dragIconQuestion} color="secondary" />
    </div>);

    return <Grid item>
        {/* <div className={classes.sectiontTitle}>Section {sectionIndex + 1}</div> */}
        <Paper >
            <DragHandle />
            <div className={classes.sectionContainer}>
                <div className={classes.sectionName}>
                    <TextField
                        style={{ width: '100%' }}
                        value={section?.name}
                        label=""
                        variant="outlined"
                        placeholder="Add section title..."
                        onChange={e => onSectionChange(sectionIndex, 'name', e.target.value)}
                    />
                </div>
                <FormQuestions
                    form={form}
                    questions={section.questions}
                    onQuestionsChange={(newQuestions) => onSectionChange(sectionIndex, 'questions', newQuestions)}
                    onDeleteQuestion={(questionIndex) => onDeleteQuestion(sectionIndex, questionIndex)}
                    onDuplicateQuestion={(questionIndex) => onDuplicateQuestion(sectionIndex, questionIndex)}
                    mappedFieldsUsed={mappedFieldsUsed}
                //onChange={(question) => onQuestionChange(sectionIndex, section)}
                />
            </div>
            <SectionActions
                onDeleteSection={() => onDeleteSection(sectionIndex)}
                onAddQuestion={(questionType => onAddQuestion(sectionIndex, questionType))}
            />
        </Paper>
    </Grid>
});

const SortableContainer = sortableContainer(({ children }) => {
    const classes = FormBuilderStyles();
    return <Grid container direction="column" spacing={2} className={classes.questionsContainer}>
        {children}
    </Grid>;
});
