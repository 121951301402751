import VendorFields from "features/vendors/VendorFields";

const CreateNewCarrier = ({ reservationDetails, onChangeValue, onChangePocField, isNew, displayError }) => {

    return (
        <VendorFields 
            vendor={reservationDetails?.carrierInfo} 
            onChange={onChangeValue} 
            onChangePocField={onChangePocField}
            displayError={displayError}
            isNew={isNew}
        />
    );
}

export default CreateNewCarrier;