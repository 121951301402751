import { clientConfigTypes } from "features/clients/clientConfigsSetup";
import { excludedTypes, vehicle_detail_extended_type, vehicleDetailExtendedTypeLabels } from "features/vehicles/VehicleDetailExtended/VehicleDetailExtendedTypes";
import { licensePlateTypesList } from 'features/vehicles/vehicleConsts';
import { IconArrowCircle, IconDatePicker, IconImage, IconMessage, IconRadioButton, IconSign, IconToggle } from "icons";
import QuestionValues from "./FormQuestions/components/QuestionValues";

export const formTypes = {
    Checkin: 1,
    Checkout: 2,
    ConditionReport: 3
}

export const formStatuses = {
    Draft: 1,
    Published: 2
}

export const formStatusOptions = [
    {
        id: formStatuses.Draft,
        name: 'Draft'
    },
    {
        id: formStatuses.Published,
        name: 'Published'
    }
]

export const formTypeOptions = [
    {
        id: formTypes.Checkin,
        name: 'Check in',
        configInfo: {
            configType: clientConfigTypes.Checkin,
            parameterName: 'useCustomCheckin'
        }
    },
    {
        id: formTypes.Checkout,
        name: 'Check out',
        configInfo: {
            configType: clientConfigTypes.Checkout,
            parameterName: 'useCustomCheckOut'
        }
    },
    {
        id: formTypes.ConditionReport,
        name: 'Condition report',
        configInfo: {
            configType: clientConfigTypes.ConditionalReport,
            parameterName: 'useCustomConditionReport'
        }
    }
]

export const questionTypes = {
    Freetext: 1,
    Dropdown: 2,
    MultipleChoice: 3,
    Image: 4,
    Signature: 5,
    Date: 6,
    //Boolean: 7
}

export const questionTypeSetup = {
    [questionTypes.Freetext]: {
        name: 'Short answer',
        icon: <IconMessage />
    },
    [questionTypes.Dropdown]: {
        name: 'Dropdown',
        component: QuestionValues,
        icon: <IconArrowCircle />
    },
    [questionTypes.MultipleChoice]: {
        name: 'Multiple choice',
        component: QuestionValues,
        icon: <IconRadioButton />
    },
    [questionTypes.Image]: {
        name: 'Image',
        icon: <IconImage />
    },
    [questionTypes.Signature]: {
        name: 'Signature',
        icon: <IconSign />
    },
    [questionTypes.Date]: {
        name: 'Date',
        icon: <IconDatePicker />
    },
    // [questionTypes.Boolean]: {//TODO this is not supoprted in the mobile app
    //     name: 'Boolean',
    //     icon: <IconToggle />
    // }
}

export const emptyForm = (formType) => {
    return {
        formType,
        name: '',
        sections: [
            {
                name: '',
                order: 1,
                questions: []
            }
        ]
    }
}

export const vehicleDetailTypes = {
    vehicle: 1,
    vehicleDetail: 2,
    vehicleDetailExtended: 3,

}

export const vehicleFieldsToMap = {
    //vehicleType: 1,
    year: 2,
    make: 3,
    model: 4,
    color: 5,
    odometer: 6,
    assetId: 7,
    licensePlate: 8,
    licensePlateState: 9,
    licensePlateType: 10,
    registrationYearMonth: 11,
}

export const vehicleFieldsValues = {
    [vehicleFieldsToMap.licensePlateType]: licensePlateTypesList,
}

const vdFieldsToMapTo = Object.values(vehicle_detail_extended_type)?.filter(vde => !excludedTypes.includes(vde))?.map(vde => ({
    name: vehicleDetailExtendedTypeLabels[vde],
    id: {
        type: vehicleDetailTypes.vehicleDetailExtended,
        field: vde
    },
    key: `vd_${vde}`,
    questionType: questionTypes.Freetext
}));

export const vehicleFieldsToMapSetup = [
    {
        name: 'Year',
        id: {
            type: vehicleDetailTypes.vehicle,
            field: vehicleFieldsToMap.year
        },
        questionType: questionTypes.Freetext
    },
    {
        name: 'Make',
        id: {
            type: vehicleDetailTypes.vehicle,
            field: vehicleFieldsToMap.make
        },
        questionType: questionTypes.Freetext
    },
    {
        name: 'Model',
        id: {
            type: vehicleDetailTypes.vehicle,
            field: vehicleFieldsToMap.model
        },
        questionType: questionTypes.Freetext
    },
    {
        name: 'Color',
        id: {
            type: vehicleDetailTypes.vehicle,
            field: vehicleFieldsToMap.color
        },
        questionType: questionTypes.Freetext
    },
    {
        name: 'Odometer',
        id: {
            type: vehicleDetailTypes.vehicleDetail,
            field: vehicleFieldsToMap.odometer
        },
        questionType: questionTypes.Freetext
    },
    {
        name: 'Asset Id',
        id: {
            type: vehicleDetailTypes.vehicle,
            field: vehicleFieldsToMap.assetId
        },
        questionType: questionTypes.Freetext
    },
    {
        name: 'License Plate',
        id: {
            type: vehicleDetailTypes.vehicle,
            field: vehicleFieldsToMap.licensePlate
        },
        questionType: questionTypes.Freetext
    },
    {
        name: 'License Plate State',
        id: {
            type: vehicleDetailTypes.vehicle,
            field: vehicleFieldsToMap.licensePlateState
        },
        questionType: questionTypes.Freetext
    },
    {
        name: 'License Plate Type',
        id: {
            type: vehicleDetailTypes.vehicle,
            field: vehicleFieldsToMap.licensePlateType
        },
        questionType: questionTypes.Dropdown
    },
    {
        name: 'Registration Expiration',
        id: {
            type: vehicleDetailTypes.vehicle,
            field: vehicleFieldsToMap.registrationYearMonth
        },
        questionType: questionTypes.Date
    },
    ...vdFieldsToMapTo
]

