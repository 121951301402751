import * as React from "react";

function SvgIconArrowRightCircle(props, width, height) {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99984 18.9582C5.33431 18.9582 1.5415 15.1654 1.5415 10.4998C1.5415 5.83431 5.33431 2.0415 9.99984 2.0415C14.6654 2.0415 18.4582 5.83431 18.4582 10.4998C18.4582 15.1654 14.6654 18.9582 9.99984 18.9582ZM9.99984 2.2915C5.47369 2.2915 1.7915 5.97369 1.7915 10.4998C1.7915 15.026 5.47369 18.7082 9.99984 18.7082C14.526 18.7082 18.2082 15.026 18.2082 10.4998C18.2082 5.97369 14.526 2.2915 9.99984 2.2915Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M12.0835 10.625H7.0835C7.05335 10.625 7.02207 10.6127 6.99642 10.5871C6.97078 10.5614 6.9585 10.5301 6.9585 10.5C6.9585 10.4699 6.97078 10.4386 6.99642 10.4129C7.02207 10.3873 7.05335 10.375 7.0835 10.375H12.0835C12.1136 10.375 12.1449 10.3873 12.1706 10.4129C12.1962 10.4386 12.2085 10.4699 12.2085 10.5C12.2085 10.5301 12.1962 10.5614 12.1706 10.5871C12.1449 10.6127 12.1136 10.625 12.0835 10.625Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M12.3871 10.8534L12.7406 10.4999L12.3871 10.1463L10.3287 8.08798C10.3072 8.06646 10.2939 8.03572 10.2939 7.99987C10.2939 7.96402 10.3072 7.93328 10.3287 7.91176C10.3503 7.89023 10.381 7.87695 10.4169 7.87695C10.4527 7.87695 10.4834 7.89023 10.505 7.91176L10.8585 7.5582L10.505 7.91176L13.005 10.4118C13.0265 10.4333 13.0398 10.464 13.0398 10.4999C13.0398 10.5357 13.0265 10.5665 13.005 10.588L10.505 13.088C10.4903 13.1027 10.4762 13.1111 10.4634 13.1163C10.4501 13.1217 10.4345 13.1249 10.4169 13.1249C10.3992 13.1249 10.3837 13.1217 10.3703 13.1163C10.3575 13.1111 10.3434 13.1027 10.3287 13.088L9.98241 13.4343L10.3287 13.088C10.3072 13.0665 10.2939 13.0357 10.2939 12.9999C10.2939 12.964 10.3072 12.9333 10.3287 12.9118L12.3871 10.8534Z" fill="#0090FE" stroke="#0090FE" />
        </svg>
    );
}

export default SvgIconArrowRightCircle;
