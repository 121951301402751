import { loadPermissions } from "./loadPermissions";
import { vehiclePermissions } from './vehiclePermissions';
import { lotPermissions } from "./lotPermissions";
import { conditionReportPermissions } from "./ConditionReportPermissions";
import { activityPermissions } from "./activityPermissions";
import { pricingPermissions } from "./pricingPermissions";
import { userPermissions } from "./userPermissions";
import { formPermissions } from "./formPermissions";
import { accessPermissions } from "./accessPermissions";
import { notePermissions } from "./notePermissions";
import { issueTrackerPermissions } from "./issueTrackerPermissions";
import { storageInvoicePermissions } from "./storageInvoicePermissions";
import { reportsPermissions } from "./reportsPermissions";
import { reportingPermissions } from "./reportingPermissions";
import {userManagementPermissions} from "./userManagementPermissions";
import {dashboardPermissions} from "./dashboardPermissions";
import {inventoryPermissions} from "./inventoryPermissions";
import {inspectionsPermissions} from "./inspectionsPermissions";
import {configPermissions} from "./configPermissions";
import {taxRatePermissions} from "./taxRatePermissions"
import {invoicePermissions} from "./invoicePermissions";
import { reservationPermissions } from "./reservationPermissions";
import { carrierPermissions } from "./carrierPermissions";

export const permissions = {
    VEHICLE: vehiclePermissions,
    LOTS: lotPermissions,
    LOADS: loadPermissions,
    Condition_Report: conditionReportPermissions,
    ACTIVITIES: activityPermissions,
    PRICING: pricingPermissions,
    USER: userPermissions,
    USER_MANAGEMENT: userManagementPermissions,
    FORMS: formPermissions,
    ACCESS: accessPermissions,
    NOTES: notePermissions,
    ISSUE_TRACKER: issueTrackerPermissions,
    DASHBOARD: dashboardPermissions,
    STORAGE_INVOICES: storageInvoicePermissions,
    INSPECTION: inspectionsPermissions,
    REPORTS: reportsPermissions,
    INVENTORY: inventoryPermissions,
    REPORTING: reportingPermissions,
    CLIENT_CONFIG: configPermissions,
    TAX_RATE: taxRatePermissions,
    INVOICES: invoicePermissions,
    RESERVATIONS: reservationPermissions,
    CARRIERS: carrierPermissions
}