
export const licensePlateTypes = {
    PERMANENT: 1,
    TEMPORARY: 2,
    NO_PLATE: 3,
    HANDWRITTEN: 4
}

export const licensePlateTypesList = [
    { id: licensePlateTypes.PERMANENT, name: "Permanent" },
    { id: licensePlateTypes.TEMPORARY, name: "Temporary" },
    { id: licensePlateTypes.NO_PLATE, name: "No Plate" },
    { id: licensePlateTypes.HANDWRITTEN, name: "Handwritten" }
];

export const getLPTypeName = (typeId) => {
    const type = licensePlateTypesList.find(type => type.id === typeId);
    return type?.name;
};