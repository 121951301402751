import CustomInput from "components/CustomInput"

export const DropdownComponent = ({ value, values, onChange }) => {

    const getValueId = () => {
        let val = value ? JSON.parse(value ?? "")?.value : null;
        if (val) {
            return values?.find(v => v.name == val)?.id;
        }
        return "";
    }

    const getValueToSave = (val) => {
        if (val) {
            let selectedValue = values?.find(v => v.id.toString() == val);
            let valObj = { ...selectedValue, value: selectedValue?.name };
            return JSON.stringify(valObj);
        }
        return "";
    }


    return (
        <CustomInput
            elementType='dropdown'
            value={() => getValueId()}
            values={values}
            onChange={(val) => onChange(getValueToSave(val), "values")}
            showEmpty
        />
    )
}