import { Grid, Paper } from '@material-ui/core';
import DataSearchBar from 'components/DataSearchBar';
import TableFilters from 'components/DataTable/TableFilters';
import { ExpandFiltersPanel } from 'components/DataTable/TableFilters/ExpandFiltersPanel';
import { SelectedFilters } from 'components/DataTable/TableFilters/SelectedFilters/SelectedFilters';
import KanbanTable from 'components/KanbanTable/KanbanTable';
import LoadingSpinner from 'components/LoadingSpinner';
import PageComponent from "components/Page/PageComponent";
import { userPreferencesComponentIds } from 'components/UserPreferences/userPreferencesConsts';
import { useGetAllActivityItemsQuery } from 'features/activity/activitySlice';
import { useGetClientsQuery } from "features/clients/clientSlice";
import { LotTypeEnumList } from 'features/lots/LotsEnums';
import { useGetLotsQuery } from "features/lots/lotSlice";
import { selectIsAdmin } from "features/user/userSlice";
import { relationTypeEnumById } from "features/users/UsersEnums";
import { useGetAllRelationsQuery, useGetUsersNoFilteringQuery } from "features/users/usersSlice";
import { groupBy } from "lodash";
import moment from 'moment';
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterService, { fieldTypes } from "services/filterService";
import FormatService from "services/formatService";
import { arrayToObject } from "utils/object-util";
import { resetFilters, setFilters, setSearchVal } from '.././issuesTrackerFiltersSlice';
import WorkflowPageViewSelector from "../WorkflowPageViewSelector/WorkflowPageViewSelector";
import CreateIssueMenu from './../CreateIssueMenu';
import IssueTrackerView from "./../IssueTrackerView/IssueTrackerView";
import IssueViewSelector from './../IssueViewSelector/IssueViewSelector';
import { issueTrackerLinkTypeList, issueTrackerTypesList } from './../issueTrackerConsts';
import { useGetAllIssuesTrackerQuery, useGetIssueTrackerCategoriesQuery, useGetIssueTrackerPrioritiesQuery, useGetStatusIssueTrackerQuery } from "./../issuesTrackerSlice";
import { IssueTrackerKanbanStyle } from './IssueTrackerKanbanStyle';
import _, { first } from "lodash";

const IssueTrackerKanban = () => {
    const classes = IssueTrackerKanbanStyle();

    const dispatch = useDispatch();
    const isAdmin = useSelector(selectIsAdmin);
    const { searchVal, filters } = useSelector(state => state.issuesTrackerFilters);
    const { viewArchived } = useSelector(state => state.issuesTrackerFilters);
    const [selectedViewId, setSelectedViewId] = useState(0)

    let { data: issuesTrackerFromApi, error: issuesTrackerError, isFetching: isLoadingIssuesTracker } = useGetAllIssuesTrackerQuery({ includeIssueTrackerHistory: false, includeRecentHistory: true });
    let { data: statuses, error: statusIssuesTrackerError, isFetching: isLoadingStatusIssuesTracker } = useGetStatusIssueTrackerQuery();
    let { data: categories, error: categoriesError, isFetching: isLoadingCategories } = useGetIssueTrackerCategoriesQuery();
    let { data: priorities, error: prioritiesError, isFetching: isLoadingPriorities } = useGetIssueTrackerPrioritiesQuery();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null, { skip: !isAdmin });
    let { data: allRelations, error: allRelationsError, isLoading: isLoadingAllRelations } = useGetAllRelationsQuery();
    let { data: users, error: usersError, isLoading: isLoadingUsers } = useGetUsersNoFilteringQuery();
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();

    const [issuesTrackerToAdd, setIssueTrackerToAdd] = useState();
    const [issuesTrackerToEdit, setIssuesTrackerToEdit] = useState();
    const [columnsSorted, setColumnsSorted] = useState([]);

    const columnsWithKeys = statuses?.map(col => ({ ...col, key: "kanban_col_" + col?.id }));

    let regionalManagers = [... new Set(allRelations?.filter(relation => relation.relationType === relationTypeEnumById.REGIONAL_MANAGER))];
    const groupedToUserId = groupBy(regionalManagers, 'userId');
    const dropdownValues = Object.keys(groupedToUserId).map((key) => ({ id: key, name: groupedToUserId[key]?.[0].userName }));

    const clientAccounts = _.flatten(clients?.map(c => c.clientAccounts));

    const lotLookup = arrayToObject(lots);
    const categoryLookup = arrayToObject(categories);
    const priorityLookup = arrayToObject(priorities);
    const statusLookup = arrayToObject(statuses);
    const usersLookup = arrayToObject(users);
    const clientLookup = arrayToObject(clients);
    const clientAccountsLookup = arrayToObject(clientAccounts);

    


    const calculateTimeSpentOnLastStatus = (statuses) => {
        if (!statuses || statuses?.length < 1) return null;
        const currentDate = FormatService.formatDateNoConversion(moment());
        const currentStatusDate = FormatService.formatDateNoConversion(statuses?.[0]?.statusDate);
        const days = moment(currentDate).diff(moment(currentStatusDate), 'days');
        return days;
    };

    let issuesTracker = useMemo(() => issuesTrackerFromApi?.map(a => ({
        ...a,
        reporterName: usersLookup?.[a.reporter]?.name,
        assigneeName: usersLookup?.[a.assignee]?.name,
        lotName: lotLookup?.[a.lotId]?.name,
        lotType: lotLookup?.[a.lotId]?.type,
        clientName: clientLookup?.[a.clientId]?.name,
        clientAccountName: clientAccountsLookup?.[a.clientAccountId]?.name,
        categoryName: categoryLookup?.[a.category]?.name,
        priorityName: priorityLookup?.[a.priority]?.name,
        statusName: statusLookup?.[a.latestStatus?.status]?.name,
        status: a.latestStatus?.status,
        latestStatusId: a?.latestStatus?.status,
        regionalManager: regionalManagers?.filter(r => r.lotId === a.lotId)?.map(r => r.userId),
        openMoreThanTwoWeeksStr: a?.openMoreThanTwoWeeks ? 'yes' : 'no',
        isCompleteStr: a?.isComplete ? 'yes' : 'no',
        overdueStr: a?.overdue ? 'yes' : 'no',
        timeSpentOnLastStatus: calculateTimeSpentOnLastStatus(a.statuses)
    })), [issuesTrackerFromApi, lotLookup, usersLookup, clientLookup, categoryLookup, priorityLookup, statusLookup,clientAccountsLookup]);


    const issueLookup = useMemo(() => arrayToObject(issuesTracker ?? []), [issuesTrackerFromApi])

    useEffect(() => {
        if (issuesTrackerToEdit) {
            let updatedIssue = issueLookup?.[issuesTrackerToEdit?.id];
            if (updatedIssue) {
                setIssuesTrackerToEdit(updatedIssue);
            };
        }
    }, [isLoadingIssuesTracker])

    const yesNoValues = [{ id: 'yes', name: 'Yes' }, { id: 'no', name: 'No' }];

    let filterConfig = [
       // { type: fieldTypes.TEXT, size: 2, title: 'Client', key: 'clientId', values: clients },
        { type: fieldTypes.TEXT, size: 2, title: 'Client', key: 'clientAccountId', values: clientAccounts },
        { type: fieldTypes.LIST, size: 2, title: 'Status', key: 'latestStatusId', values: statuses },
        { type: fieldTypes.TEXT, size: 2, title: 'Type', key: 'issueType', values: issueTrackerTypesList },
        { type: fieldTypes.VALUE_LIST, size: 2, title: 'Linked To', key: 'linkedToType', values: issueTrackerLinkTypeList },
        { type: fieldTypes.VALUE_LIST, size: 2, title: 'Activity', key: 'activityItemIds', values: activityItems },
        { type: fieldTypes.LOT, size: 2, title: 'Lot', key: 'lotId', values: lots },
        { type: fieldTypes.TEXT, size: 2, title: 'Lot Type', key: 'lotType', values: LotTypeEnumList },
        { type: fieldTypes.VALUE_LIST, size: 2, title: 'Regional Manager', key: 'regionalManager', values: dropdownValues },
        { type: fieldTypes.TEXT, size: 2, title: 'Category', key: 'category', values: categories },
        { type: fieldTypes.LIST, size: 2, title: 'Priority', key: 'priority', values: priorities },
        { type: fieldTypes.DATERANGE, size: 2, title: 'Created Date', key: 'createdDate' },
        { type: fieldTypes.TEXT, size: 2, title: 'Assignee', key: 'assignee', values: users },
        { type: fieldTypes.TEXT, size: 2, title: 'Assigned', key: 'isAssigned', values: yesNoValues },
        { type: fieldTypes.TEXT, size: 2, title: 'Reporter', key: 'reporter', values: users },
        { type: fieldTypes.TEXT, size: 2, title: 'Open more than Two Weeks', key: 'openMoreThanTwoWeeksStr', values: [{ id: 'yes', name: 'Yes' }, { id: 'no', name: 'No' }] },
        { type: fieldTypes.TEXT, size: 2, title: 'Is Complete', key: 'isCompleteStr', values: [{ id: 'yes', name: 'Yes' }, { id: 'no', name: 'No' }] },
        { type: fieldTypes.DATERANGE, size: 2, title: 'Due Date', key: 'solutionEta' },
        { type: fieldTypes.TEXT, size: 2, title: 'Overdue', key: 'overdueStr', values: [{ id: 'yes', name: 'Yes' }, { id: 'no', name: 'No' }] },
    ];

    const searchIssuesTracker = value => {
        dispatch(setSearchVal(value.trim()));
    };

    const onDropFilter = (value, prop) => {
        dispatch(setFilters({ ...filters, [prop]: { value } }));
    };

    const onResetFilters = () => {
        dispatch(resetFilters());
    };

    const mapExportData = row => {
        const issues = {};
        issues['Issue Id'] = row?.['id'];
        issues['Assignee'] = row?.['assigneeName'] ?? row?.['assignee'];
        issues['Reporter'] = row?.['reporterName'] ?? row?.['reporter'];
        issues['Status'] = row?.['statusName'];
        issues['Priority'] = row?.['priorityName'];
        issues['Title'] = row?.['title'];
        issues['Lot'] = row?.['lotName'];
        issues['Asset'] = row?.linkedAssets?.map(a => a.descriptor || a.assetId)?.join(' ');
        issues['Load'] = row?.linkedLoads?.map(a => `${a?.groupId}`).join(' ');
        issues['Work order'] = row?.linkedWorkOrders?.map(a => `${a?.id}`).join(' ');
        issues['Client'] = row?.['clientName'];
        issues['Category'] = row?.['categoryName'];
        issues['Estimated Cost'] = row?.['cost'];
        issues['Reported Date'] = row?.['createdDate'];
        issues['Issue Date'] = row?.['issueDate'];
        issues['Completed Date'] = row?.['completedDate'];
        issues['Due Date'] = row?.['solutionEta'];
        issues['Problem Description'] = FormatService.cleanHtmlTags(row?.['description']);
        issues['Solution Description'] = FormatService.cleanHtmlTags(row?.['solutionDescription']);
        return issues;
    };

    let fieldsToExport = [
        { name: "Issue Id", key: "id" },
        { name: "Assignee", key: "assignee" },
        { name: "Reporter", key: "reporter" },
        { name: "Title", key: "title" },
        { name: "Status", key: "statusName" },
        { name: "Category", key: "categoryName" },
        { name: "Priority", key: "priority" },
        { name: "Lot", key: "lotId", values: lots },
        { name: 'Asset', width: 200, key: 'assetLinks' },
        { name: 'Work order', width: 200, key: 'woLinks' },
        { name: 'Load', width: 200, key: 'loadLinks' },
        { name: "Client", key: "clientAccountId", values: clientAccounts },
        { name: "Estimated Cost", key: "cost" },
        { name: "Reported Date", key: "createdDate" },
        { name: "Issue Date", key: "issueDate" },
        { name: "Completed Date", key: "completedDate" },
        { name: "Due Date", key: "solutionEta" },
        { name: "Problem Description", key: "description" },
        { name: "Solution Description", key: "solutionDescription" }
    ];

    const actionButtons = <Grid container direction='row' spacing={1}>
        <Grid item>
            <WorkflowPageViewSelector />
        </Grid >
        <Grid item>
            <CreateIssueMenu issuesTrackerToAdd={issuesTrackerToAdd} setIssueTrackerToAdd={setIssueTrackerToAdd} />
        </Grid>
    </Grid>;

    const isLoading = isLoadingAllRelations || isLoadingCategories || isLoadingClients || isLoadingIssuesTracker || isLoadingLots || isLoadingPriorities || isLoadingStatusIssuesTracker || isLoadingUsers;
    const filteredIssuesTracker = FilterService.filter(filters, searchVal, issuesTracker, filterConfig);

    const tableId = viewArchived ? userPreferencesComponentIds.ISSUE_TRACKER_KANBAN_ARCHIVED : userPreferencesComponentIds.ISSUE_TRACKER_KANBAN;

    return (
        <PageComponent>
            <LoadingSpinner loading={isLoading} />
            <div className={classes.listContainer}>
                <TableFilters
                    tableId={tableId}
                    selectedViewId={selectedViewId}
                    dropDownsConfig={filterConfig}
                    filters={filters}
                    onDropFilter={onDropFilter}
                    onResetFilters={onResetFilters}
                />

                <div className={classes.kanbanWrapper}>
                    <Paper className={classes.header}>
                        <Grid container direction="row" alignItems="center" justifyContent="space-between">
                            <Grid item
                                className={classes.titleContainer}
                                alignItems="center">
                                <ExpandFiltersPanel filtersState={filters} />
                                <h1 className={classes.title}>
                                    <Grid container spacing={2} alignItems='center'>
                                        <Grid item>
                                            Tickets ({filteredIssuesTracker?.filter(d => columnsSorted?.map(c => c.id)?.includes(d.latestStatusId))?.length ?? 0})
                                        </Grid>
                                        {/* <Grid item>
                                            {!issuesTrackerToEdit && <IssueViewSelector />}
                                        </Grid> */}
                                    </Grid>
                                </h1>
                            </Grid>
                            <Grid item
                                className={classes.actionButtonsContainer}
                                direction="row"
                                alignItems="center">
                                {actionButtons}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <DataSearchBar
                                onSearch={searchIssuesTracker}
                                searchVal={searchVal}
                            />
                        </Grid>
                        <SelectedFilters
                            filters={filters}
                            filterConfig={filterConfig}
                            onClearFilter={(filterKey) => onDropFilter(null, filterKey)}
                            tableId={tableId}
                            selectedViewId={selectedViewId}
                        />
                    </Paper>
                    <div>
                        <KanbanTable
                            columns={columnsWithKeys}
                            data={filteredIssuesTracker}
                            mapExportData={mapExportData}
                            fieldsToExport={fieldsToExport}
                            filters={filters}
                            tableId={tableId}
                            setIssuesTrackerToEdit={setIssuesTrackerToEdit}
                            columnsSorted={columnsSorted}
                            setColumnsSorted={setColumnsSorted}
                        />
                    </div>

                </div>

            </div>

            {issuesTrackerToEdit && <IssueTrackerView
                issueTracker={issuesTrackerToEdit}
                withDialog
                onClose={() => setIssuesTrackerToEdit()}
            />}
        </PageComponent>
    );
}
export default IssueTrackerKanban;