import { GasLevelField } from "./fields/GasLevelField"
import { MilesField } from "./fields/MilesField"
import { MonthsField } from "./fields/MonthsField"
import { TreadDepthField } from "./fields/TreadDepthField"
import { VehicleField } from "./fields/VehicleField"
import { WeeksField } from "./fields/WeeksField"
import { VehicleTypeField } from "./fields/VehicleTypeField"

export const ruleFieldType = {
    MONTHS: 1,
    MILES: 2,
    VEHICLE_FIELD: 3,
    GAS_LEVEL: 4,
    TREAD_DEPTH: 5,
    VEHICLE_TYPE: 6,
    WEEKS:7,
}

export const ruleFieldTypeConfig = {
    [ruleFieldType.MONTHS]: {
        name: 'Months',
        component: (ruleParam, onChange) => <MonthsField ruleParam={ruleParam} onChange={onChange} />
    },
    [ruleFieldType.WEEKS]: {
        name: 'Weeks',
        component: (ruleParam, onChange) => <WeeksField ruleParam={ruleParam} onChange={onChange} />
    },
    [ruleFieldType.MILES]: {
        name: 'Miles',
        component: (ruleParam, onChange) => <MilesField ruleParam={ruleParam} onChange={onChange} />
    },
    [ruleFieldType.VEHICLE_FIELD]: {
        name: 'Field',
        hasSubType: true,
        component: (ruleParam, onChange, paramSubType) => <VehicleField ruleParam={ruleParam} onChange={onChange} paramSubType={paramSubType}/>
    },
    [ruleFieldType.GAS_LEVEL]: {
        name: 'Fuel Level',
        hasSubType: true,
        component: (ruleParam, onChange) => <GasLevelField ruleParam={ruleParam} onChange={onChange} />
    },
    [ruleFieldType.TREAD_DEPTH]: {
        name: 'Tread Depth',
        hasSubType: true,
        component: (ruleParam, onChange) => <TreadDepthField ruleParam={ruleParam} onChange={onChange} />
    }
}