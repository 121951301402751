import { makeStyles } from "@material-ui/core";

export const vendorsStyle = makeStyles((theme) => ({
    alertWrapper: {
        margin: '0.1rem 0 0.5rem'
    },
    button: { 
        marginRight: '1.2em' 
    }
}));
