import { Grid, Tooltip } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import DropZoneInDialog from "components/DropZoneInDialog";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import LoadingSpinner from "components/LoadingSpinner";
import NotificationProvider from "components/NotificationProvider";
import { NoteEntityTypes } from "features/notes/notesConsts";
import FadeInImage from "features/vehicles/components/FadeInImage";
import { IconAdd, IconAddCircle, IconPhoto } from "icons";
import { orderBy } from "lodash";
import FormatService from "services/formatService";
const { AttachmentsStyle } = require("./AttachmentsStyle");
const { useGetEntityAttachmentsQuery, useDeleteAttachmentMutation, useAddAttachmentMutation } = require("./attachmentSlice");

const AttachmentsView = ({
    attachmentsSent,
    entityId,
    entityType,
    showTitle = false,
    allowAdd = true,
    addBtnStyle = 'outlined',
    useIconButton,
    addPermissionProfile = permissionProfiles.ACTIVITY.EDIT_ACTIVITY_ATTACHMENTS,
    permissionDeleteImage,
    label = 'Attachment',
    noteType
}) => {
    const classes = AttachmentsStyle();

    let { data: attachmentsFromApi, error: attachmentsError, isFetching: isLoadingAttachments } = useGetEntityAttachmentsQuery({ entityId: entityId, entityType: entityType }, { skip: !entityId || !!attachmentsSent });

    const [deleteAttachment] = useDeleteAttachmentMutation();
    const [addAttachment, { isLoading: uploadingAttachment }] = useAddAttachmentMutation();

    let attachments = attachmentsSent ?? attachmentsFromApi ?? [];

    attachments = attachments.map(i => ({
        ...i,
        createdDate: FormatService.formatDate(i.createdDate)
    }))
    attachments = orderBy(attachments, 'createdDate', ['asc']);

    let groupedAttachmentsByDate = {};

    attachments.forEach(img => {
        if (!groupedAttachmentsByDate[img.createdDate]) groupedAttachmentsByDate[img.createdDate] = [];
        groupedAttachmentsByDate[img.createdDate].push(img);
    });

    let attachmentsDates = Object.keys(groupedAttachmentsByDate);

    const onDelete = async (img) => {
        const attachmentToDelete = { ...img, createdDate: new Date(img.createdDate) }
        const res = await deleteAttachment(attachmentToDelete);
        if (res.error) {
            NotificationProvider.error("Failed to delete the attachment");
        } else {
            NotificationProvider.success("Successfully deleted the attachment");
        }
    }

    const uploadAttachment = async (file) => {
        let fileBase64 = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
            fileBase64 = reader.result.toString().replace(/^data:(.*,)?/, '');
            const result = await addAttachment({
                "fileName": file.name,
                "fileType": file.type,
                "filedata": fileBase64,
                "entityId": entityId,
                "entityType": entityType,
            })
            if (result.error) {
                NotificationProvider.error(`Failed to upload the ${label}`);
            } else {
                NotificationProvider.success(`Successfully uploaded the  ${label}`);
            }
        };
    };

    return (
        <Grid container>
            <Grid container direction='row' justifyContent={showTitle ? "space-between" : "flex-end"}>
                {showTitle && <Grid item className={classes.icon} spacing={2}>
                    <IconPhoto />
                    <div className={classes.title}>{`${label}(${attachments?.length ?? 0})`}</div>
                </Grid>}
                {allowAdd && <Authorize profile={addPermissionProfile}>
                    <Grid item>
                        <DropZoneInDialog
                            labelDialog={`Add ${label}`}
                            buttonLabel={useIconButton ? <Tooltip title={`Add ${label}`}><IconAddCircle /></Tooltip> : `Add ${label}`}
                            btnStyle={addBtnStyle}
                            onSave={uploadAttachment}
                            formContent={
                                <div>
                                </div>
                            }
                            accept = {{
                                'image/jpeg': ['.jpg', '.jpeg'],
                                'application/pdf': ['.pdf'],
                                'image/png': ['.png']
                            }}
                            actionButtonLabel="Upload"
                        />
                        <LoadingSpinner loading={uploadingAttachment} />
                    </Grid>
                </Authorize>}
            </Grid>

            {attachmentsDates?.map((imageDate) =>
                <Grid container direction="column">
                    <Grid item>
                        <div>Date: {!!imageDate ? imageDate : 'Unknown'}</div>
                    </Grid>
                    <Grid container>
                        {groupedAttachmentsByDate[imageDate]?.map((img, i) =>
                            <Grid item key={i}>
                                <FadeInImage
                                    img={img}
                                    alt={`${label} ${i}`}
                                    onDelete={onDelete}
                                    existingImages={attachments}
                                    showCarousel={true}
                                    imagesForCarousel={groupedAttachmentsByDate}
                                    noteType={noteType}
                                    permissionDeleteImage={permissionDeleteImage}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
            {(!isLoadingAttachments && !(!!attachments?.length)) && <EmptyPlaceholder text={`No ${label}s Found`} />}
        </Grid>
    )
}

export default AttachmentsView;