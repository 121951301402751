import { arrayToObject } from "utils/object-util";

export const issueTrackerStatuses = {
    NotStarted: 1,
    InternalEvaluation: 2,
    InProcurement: 3,
    RemedyScheduled: 4,
    SourcingVendors: 5,
    RODScheduled: 6,
    RODComplete: 7,
    ContractDiscussions: 8,
    Deprioritized: 9,
    Duplicate: 10,
    Complete: 11,
    Closed: 12,
}

export const issueTrackerStatusGroups = {
    'NotStarted': [issueTrackerStatuses.NotStarted],
    'Done': [issueTrackerStatuses.Duplicate, issueTrackerStatuses.Complete, issueTrackerStatuses.Closed]
}

export const getStatusColor = (status) => {
    let borderColor = '#BF8F00';
    let backgroundColor = '#FFF2CC';

    if (issueTrackerStatusGroups.NotStarted.includes(status)) {
        borderColor = '#E64165';
        backgroundColor = '#FAD9E0'
    }
    if (issueTrackerStatusGroups.Done.includes(status)) {
        borderColor = '#5C9E5A';
        backgroundColor = '#E5F6E4'
    }

    return { borderColor, backgroundColor, color: borderColor };
}

export const completedStatuses = [
    issueTrackerStatuses.Duplicate, issueTrackerStatuses.Complete, issueTrackerStatuses.Closed
]

export const issueTrackerPriorities = {
    One: 1,
    Two: 2,
    Three: 3,
    Four: 4,
    Five: 5
}

export const issueTrackerTypes = {
    REGULAR: 1,
    PHONE_CALL: 2,
    LOT_TASK: 3
}

export const issueTrackerTypesList = [
    {
        name: 'Ticket',
        id: issueTrackerTypes.REGULAR
    },
    {
        name: 'Phone Call',
        id: issueTrackerTypes.PHONE_CALL
    },
    {
        name: 'Lot Task',
        id: issueTrackerTypes.LOT_TASK
    }
]

export const issueTrackerLinkEntityType = {
    Ticket: 1,
    Asset: 2,
    Workorder: 3,
    Load: 4,
    Reservation:5
}

export const issueTrackerLinkEntityTypeList = [
    {
        id: issueTrackerLinkEntityType.Asset,
        name: 'Asset'
    },
    {
        id: issueTrackerLinkEntityType.Workorder,
        name: 'Activity'
    },
    {
        id: issueTrackerLinkEntityType.Load,
        name: 'Load'
    },
    {
        id: issueTrackerLinkEntityType.Reservation,
        name: 'Reservation'
    }
]


export const issueTrackerLinkType = {
    ENTITY: 1
};


export const issueTrackerLinkTypeList = [
    {
        id: issueTrackerLinkType.ENTITY,
        name: 'Entity'
    }
]

export const issueTrackerLinkTypesForTickets = issueTrackerLinkTypeList.filter(a => a.forEntityType == issueTrackerLinkEntityType.Ticket);

export const issueTrackerLinkTypesForTicketsLookup = {
    [issueTrackerLinkType.ENTITY]: 'Entity',
}
