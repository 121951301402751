import {makeStyles} from "@material-ui/core/styles";

export const BillStatusesStyle = makeStyles((theme) => ({
    readyBadge: {
        borderRadius: '20px',
        textAlign: 'center',
        fontSize: '0.8em',
        padding: '0px 15px',
        color: 'white',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        width: 'fit-content',
    },
    blinkingBadge: {
        animation: '$blink 1.5s infinite',
    },
    '@keyframes blink': {
        '0%': { opacity: 1 },
        '50%': { opacity: 0.5 },
        '100%': { opacity: 1 },
    },
}));
