import { Checkbox, FormControlLabel, Grid, Paper, Switch } from '@material-ui/core';
import React from 'react';
import Authorize from '../../../../components/Authorize';
import { permissionProfiles } from '../../../../components/Authorize/permissionProfiles';
import ContractHeaderStyle from './ContractHeaderStyle';

const useStyles = ContractHeaderStyle;

const ContractHeader = ({ label, flag, onChange }) => {

    const classes = useStyles();

    return (
        <div className={classes.contractHeader}>
            <div className={classes.title}>{label}</div>
            {onChange && <div className={classes.input}>
                <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_FIELD_EDIT}>
                    <FormControlLabel
                        control={<Switch
                            color="primary"
                            checked={flag}
                            onChange={onChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />}
                        labelPlacement={"end"}
                        label={flag ? 'Enabled' : 'Disabled'}
                        title={flag ? 'The feature is on. Click to turn off.' : 'The feature is off. Click to turn on.'}
                    />
                </Authorize>
            </div>}
        </div>
    );
};

export default ContractHeader;


