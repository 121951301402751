import { Chip, FormControl, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Clear } from '@material-ui/icons';
import { Autocomplete, createFilterOptions } from '@mui/material';
import { arrayToObject } from 'utils/object-util';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        width: '100%',
        position: 'relative',
        '& fieldset': {
            border: 'none'
        }
    },
    clearIcon: {
        position: 'absolute',
        right: '1.8em',
        top: '50%',
        transform: 'translateY(-50%)',
        '& svg': {
            fontSize: '1em'
        }
    },
    select: {
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer'
    },
    inputMulti: {
        padding: '12px',
        minHeight: '2em',
        whiteSpace: 'normal'
    },
    input: {
        border: 0,
        color: theme.palette.common.darkText,
        backgroundColor: theme.palette.background.default,
    },
    colorInput: {
        backgroundColor: theme.palette.action.focus,
    },
    label: {
        color: theme.palette.text.secondary
    },
    errorLabel: {
        color: theme.palette.error.main
    },
    selectMenu: {
        maxHeight: '50vh',
        '& option': {
            cursor: 'pointer',

        },
        '& option:hover': {
            background: `${theme.palette.background.default} !important`
        }
    },
    option: {
        padding: '0.2em  0.5em'

    },
    autoCompleteInput: {
        backgroundColor: theme.palette.background.default,
        borderRadius: '4px',
        padding: '0.5rem 0 0.7rem 0.5rem',

        '& .MuiOutlinedInput-root': {
            padding: '0 !important',
        },
        '&.Mui-disabled': {
            opacity: 0.5,
        },
        '& fieldset': {
            border: 'none',
        }
    },
    newAutoCompleteInput: {
        color: theme.palette.common.darkText,
        border: '1px solid lightGrey',
        borderRadius: '4px',
        padding: '0.5rem 0 0.5rem 0.5rem',

        '&.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root': {
            padding: '0 !important',
        },
        '&.MuiAutocomplete-inputRoot': {
            padding: '0 !important',
        },
        '&.Mui-disabled': {
            opacity: 0.5,
        },
        '& fieldset': {
            border: 'none'
        }
    },
}));

const selectMenuStyle = {
    backgroundColor: '#f7f6fb',
    border: 'none',
    color: 'rgba(0, 0, 0, 0.38)',
}

const DropdownFilter = ({
    title,
    identifier,
    value,
    values,
    onSelect,
    valid,
    touched,
    disabled,
    showEmpty,
    required,
    classStyles,
    multiple,
    colorInput,
    renderOption,
    variant = 'outlined',
    shrink,
    withTypeAhead,
    newAutoCompleteStyle,
    combinedData
}) => {

    const classes = useStyles();

    const [filterValue, setValue] = React.useState(value)
    const [valueObj, setValueObj] = React.useState(value)

    useEffect(() => {
        setValue(value);
        let obj = values?.find(s => s.id === value);
        setValueObj(obj);
    }, [value]);

    const handleChange = (event) => {
        let selectedVal = event.target.value;
        if (multiple && selectedVal?.includes('')) {
            selectedVal = [];
        }
        onSelect(selectedVal, identifier);
    };

    const clear = () => {
        onSelect(multiple ? [] : '', identifier);
    };

    const handleDelete = (event, valueToRemove) => {
        let newValue = [...value];
        newValue?.splice(value.indexOf(valueToRemove), 1);
        onSelect(newValue, identifier);
    };

    const renderValue = (selected) => {
        return <div>
            {selected?.map && selected?.map((value) => (
                <Chip
                    key={value}
                    label={values?.find(v => v.id == value)?.name}
                    clickable
                    deleteIcon={
                        <Clear
                            onMouseDown={(event) => event.stopPropagation()}
                        />
                    }
                    className={classes.chip}
                    onDelete={(e) => {
                        handleDelete(e, value)
                    }
                    }
                />
            ))}
        </div>
    };

    const renderOptionInternal = (value, key) => {
        return (<MenuItem value={value.id}>
            {renderOption(value)}
        </MenuItem>)
    }

    const filterOptions = createFilterOptions({
        ignoreCase: true,
        limit: 100,
    });

    return (
        <FormControl variant={variant} className={classStyles === undefined ? classes.formControl : classStyles}>
            <InputLabel
                shrink={shrink || value?.length}
                required={required}
                className={`${classes.label} ${!valid && touched && classes.errorLabel}`}
                htmlFor="outlined-age-native-simple"
            >
                {title}
            </InputLabel>
            {withTypeAhead ? (
                <Autocomplete
                    filterOptions={filterOptions}
                    autoComplete
                    disabled={disabled}
                    popupIcon={disabled ? <></> : <ExpandMoreIcon />}
                    disableClearable={!showEmpty}
                    className={newAutoCompleteStyle ? classes.newAutoCompleteInput : classes.autoCompleteInput}
                    value={valueObj ?? {}}
                    onChange={(e, val) => {
                        onSelect(val, identifier);
                    }}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    handleHomeEndKeys
                    options={showEmpty ? [{}, ...(values ?? [])] : [...(values ?? [])]}
                    getOptionLabel={(option) => option?.name ?? ''}
                    ListboxProps={{
                        className: classes.selectMenu,
                    }}
                    renderOption={(props, option) => (
                        <li {...props} >
                            {renderOption ? renderOption(option) : <Typography className={classes.option}>{option.name}</Typography>}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            value={valueObj}
                            variant={variant}
                            {...params}
                            inputProps={{ ...params.inputProps }}
                        />
                    )}
                />
            ) : (
                <Select
                    native={multiple || renderOption ? false : true}
                    multiple={multiple}
                    className={classes.select}
                    variant={variant}
                    value={filterValue}
                    onChange={handleChange}
                    label={title}
                    inputProps={{
                        name: { title },
                        id: 'outlined-age-native-simple',
                        className: `${multiple ? classes.inputMulti : classes.input} ${colorInput ? classes.colorInput : ''}`,
                    }}
                    MenuProps={{
                        className: classes.selectMenu,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null,
                    }}
                    error={!valid && touched}
                    disabled={disabled}
                    IconComponent={disabled ? empty : ExpandMoreIcon}
                    renderValue={multiple ? renderValue : undefined}
                >
                    {showEmpty && <option className={classes.option} style={selectMenuStyle} aria-label="None" value="" />}
                    {combinedData ? (
                        combinedData?.map((group, index) => (
                            <optgroup key={index} label={group.label}>
                                {group?.options?.map((option) => (
                                    <option key={option.value.id} value={JSON.stringify(option.value)} className={classes.option}>
                                        {option.label}
                                    </option>
                                ))}
                            </optgroup>
                        ))
                    ) : (
                        values?.map((value, key) => (
                            renderOption ? renderOptionInternal(value, key) :
                                <option id={key} key={key} style={selectMenuStyle} value={value.id} className={classes.option}>{value.name}</option>
                        ))
                    )}
                </Select>
            )}
            {multiple && <IconButton size='small' className={classes.clearIcon} onClick={clear}><Clear /></IconButton>}
        </FormControl>
    )
}

export default DropdownFilter

const empty = () => (<></>);