import AttachmentsView from "features/attachments/AttachmentsView";
import { AttachmentEntityTypes } from "features/attachments/attachmentsConsts";
import { ReservationDocumentsStyle } from "./ReservationDocumentsStyle";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { useAddDocReservationMutation, useDeleteDocReservationMutation } from "../../../reservationSlice";
import NotificationProvider from "components/NotificationProvider";
import DocumentForReservation from "./DocumentForReservation"
import React, { useState } from 'react';
import Authorize from "components/Authorize";

const ReservationDocuments = ({ reservation }) => {
    const classes = ReservationDocumentsStyle();
    const [addAttachment, { isLoading: uploadingAttachment }] = useAddDocReservationMutation();
    const [deleteAttachment, { isLoading: deleteAttachmentLoad }] = useDeleteDocReservationMutation();

    const uploadAttachment = async (type, file, backup) => {
        let fileBase64 = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
            fileBase64 = reader.result.toString().replace(/^data:(.*,)?/, '');
            const result = await addAttachment({
                reservationId: reservation?.id,
                fileType: file.type,
                filedata: fileBase64,
                docName: type,
                "fileName": file.name,
                reservation: reservation,
                backup: backup
            })
            if (result.error) {
                NotificationProvider.error(`Failed to upload the ${label}`);
            } else {
                NotificationProvider.success(`Successfully uploaded the  ${label}`);
            }
        };
    };

    const onDelete = async (type, img) => {
        const body = {
            id: reservation?.id,
            agreementDocUrl: reservation.agreementDocUrl,
            achDocUrl: reservation.achDocUrl,
            coiDocUrl: reservation.coiDocUrl
        }
        body[type] = null;
        const res = await deleteAttachment(body);
        if (res.error) {
            NotificationProvider.error("Failed to delete the attachment");
        } else {
            NotificationProvider.success("Successfully deleted the attachment");
        }
    }
    const label = "Attachment";
    return (
        <div className={classes.documentsWrapper}>
            <DocumentForReservation onSave={(file, backup) => uploadAttachment("coi", file, backup)} onDelete={(img) => onDelete("coiDocUrl", img)} typeName="COI" uploadingAttachment={uploadingAttachment} img={{ url: reservation.coiDocUrl, id: reservation?.id, "docName": "COI" }} selectFile={reservation.coiDocUrl != null}></DocumentForReservation>
            <Authorize profile={permissionProfiles.RESERVATIONS.VIEW_BANKING_INFO}
                readonlyComponent={<DocumentForReservation onSave={(file) => uploadAttachment("ach", file)} onDelete={(img) => onDelete("achDocUrl", img)} typeName="ACH Authorization Form" uploadingAttachment={uploadingAttachment} img={{ url: reservation.achDocUrl, id: reservation?.id, "docName": "ACH Authorization Form" }} selectFile={reservation.achDocUrl != null} confirmBackup={false} permissionViewEditFile={false}></DocumentForReservation>}>
                <DocumentForReservation onSave={(file) => uploadAttachment("ach", file)} onDelete={(img) => onDelete("achDocUrl", img)} typeName="ACH Authorization Form" uploadingAttachment={uploadingAttachment} img={{ url: reservation.achDocUrl, id: reservation?.id, "docName": "ACH Authorization Form" }} selectFile={reservation.achDocUrl != null} confirmBackup={false}></DocumentForReservation>
            </Authorize>
            <DocumentForReservation onSave={(file, backup) => uploadAttachment("agreement", file, backup)} onDelete={(img) => onDelete("agreementDocUrl", img)} typeName="Rental Agreement" uploadingAttachment={uploadingAttachment} img={{ url: reservation.agreementDocUrl, id: reservation?.id, "docName": "Rental Agreement" }} selectFile={reservation.agreementDocUrl != null}></DocumentForReservation>
            <div className={classes.boxTitle}>
                {'Documents'}
            </div>
            <AttachmentsView
                entityId={reservation?.id}
                entityType={AttachmentEntityTypes.RESERVATION}
                label='Document'
                addPermissionProfile={permissionProfiles.RESERVATIONS.EDIT}
                permissionDeleteImage={permissionProfiles.RESERVATIONS.EDIT}
            />
        </div>
    )
};

export default ReservationDocuments;
