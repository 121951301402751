import { Grid } from '@material-ui/core';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import DataTable from "components/DataTable/DataTable";
import LoadingSpinner from 'components/LoadingSpinner';
import PageComponent from "components/Page/PageComponent";
import { userPreferencesComponentIds } from 'components/UserPreferences/userPreferencesConsts';
import { useGetAllActivityItemsQuery } from 'features/activity/activitySlice';
import { LotTypeEnumList } from 'features/lots/LotsEnums';
import { relationTypeEnumById } from "features/users/UsersEnums";
import { useGetAllRelationsQuery, useGetUsersNoFilteringQuery } from "features/users/usersSlice";
import useSortOptions from "hooks/useSortOptions";
import _, { groupBy, orderBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterService, { fieldTypes } from "services/filterService";
import FormatService from "services/formatService";
import { arrayToObject } from "utils/object-util";
import { useGetClientsQuery } from "../clients/clientSlice";
import { useGetLotsQuery } from "../lots/lotSlice";
import { selectIsAdmin, selectUserClientId } from "../user/userSlice";
import CreateIssueMenu from './CreateIssueMenu';
import { IssueTrackerDateSelector } from './IssueTrackerDateSelector/IssueTrackerDateSelector';
import { IssueTrackerDescriptionField } from './IssueTrackerDescriptionField/IssueTrackerDescriptionField';
import { IssueTrackerLinks } from './IssueTrackerLinks/IssueTrackerLinks';
import { IssueTrackerUser } from './IssueTrackerUser/IssueTrackerUser';
import IssueTrackerView from "./IssueTrackerView/IssueTrackerView";
import IssueViewSelector from './IssueViewSelector/IssueViewSelector';
import { LotSelector } from './LotSelector/LotSelector';
import { PrioritySelector } from './PrioritySelector/PrioritySelector';
import { StatusSelector } from './StatusSelector/StatusSelector';
import WorkflowPageViewSelector from './WorkflowPageViewSelector/WorkflowPageViewSelector';
import { issueTrackerLinkEntityTypeList, issueTrackerTypesList } from './issueTrackerConsts';
import { resetFilters, setFilters, setSearchVal } from './issuesTrackerFiltersSlice';
import { useGetAllIssuesTrackerQuery, useGetIssueTrackerCategoriesQuery, useGetIssueTrackerPrioritiesQuery, useGetStatusIssueTrackerQuery } from "./issuesTrackerSlice";
import { IssueTrackerStyles } from "./style";

const IssuesTrackerList = ({ vehicleId, embeddedView = false }) => {
    const classes = IssueTrackerStyles();
    const isAdmin = useSelector(selectIsAdmin);
    let loggedInClientClaim = useSelector(selectUserClientId);

    const { viewArchived } = useSelector(state => state.issuesTrackerFilters);

    let { data: issuesTrackerFromApi, error: issuesTrackerError, isFetching: isLoadingIssuesTracker } = useGetAllIssuesTrackerQuery({ includeIssueTrackerHistory: viewArchived, vehicleId: vehicleId ? vehicleId : null });
    let { data: statuses, error: statusIssuesTrackerError, isFetching: isLoadingStatusIssuesTracker } = useGetStatusIssueTrackerQuery();
    let { data: categories, error: categoriesError, isFetching: isLoadingCategories } = useGetIssueTrackerCategoriesQuery();
    let { data: priorities, error: prioritiesError, isFetching: isLoadingPriorities } = useGetIssueTrackerPrioritiesQuery();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let { data: allRelations, error: allRelationsError, isLoading: isLoadingAllRelations } = useGetAllRelationsQuery();
    let { data: users, error: usersError, isLoading: isLoadingUsers } = useGetUsersNoFilteringQuery();
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();

    const [sortOptions, sortData] = useSortOptions();
    const [issuesTrackerToEdit, setIssuesTrackerToEdit] = useState();
    const [issuesTrackerToAdd, setIssueTrackerToAdd] = useState();
    const { searchVal, filters } = useSelector(state => state.issuesTrackerFilters);
    const dispatch = useDispatch();

  
    const clientAccounts = _.flatten(clients?.map(c => c.clientAccounts));

    let regionalManagers = [... new Set(allRelations?.filter(relation => relation.relationType === relationTypeEnumById.REGIONAL_MANAGER))];
    const groupedToUserId = groupBy(regionalManagers, 'userId');
    const dropdownValues = Object.keys(groupedToUserId).map((key) => ({ id: key, name: groupedToUserId[key]?.[0].userName }));

    const lotLookup = arrayToObject(lots);
    const categoryLookup = arrayToObject(categories);
    const priorityLookup = arrayToObject(priorities);
    const statusLookup = arrayToObject(statuses);
    const usersLookup = arrayToObject(users);
    const clientLookup = arrayToObject(clients);
    const clientAccountsLookup = arrayToObject(clientAccounts);

    let issuesTracker = useMemo(() => issuesTrackerFromApi?.map(a => ({
        ...a,
        reporterName: usersLookup?.[a.reporter]?.name,
        assigneeName: usersLookup?.[a.assignee]?.name,
        lotName: lotLookup?.[a.lotId]?.name,
        lotType: lotLookup?.[a.lotId]?.type,
        clientName: clientLookup?.[a.clientId]?.name,
        clientAccountName: clientAccountsLookup?.[a.clientAccountId]?.name,
        categoryName: categoryLookup?.[a.category]?.name,
        priorityName: priorityLookup?.[a.priority]?.name,
        statusName: statusLookup?.[a.latestStatus?.status]?.name,
        regionalManager: regionalManagers?.filter(r => r.lotId === a.lotId)?.map(r => r.userId),
    })), [issuesTrackerFromApi, lotLookup, usersLookup, clientLookup, categoryLookup, priorityLookup, statusLookup,clientAccountsLookup]);

    const issueLookup = useMemo(() => arrayToObject(issuesTracker ?? []), [issuesTrackerFromApi])

    const onRowClick = (row) => {
        setIssuesTrackerToEdit(row.rowData)
    }

    let issueTrackerColumns = [
        // {
        //     name: '', width: 50, component: (row) => {
        //         return <FlagAsset
        //             iconOnly
        //             selectedRows={[row?.id]}
        //             allData={[row]}
        //             entityType={FlagEntityTypes.ISSUE_TRACKER}
        //         />
        //     }
        // },
        { name: "Issue Id", key: "id", width: 90, viewWhenSideBySide: true, component: (row) => `#${row.id}` },
        { name: "Assignee", key: "assignee", component: (row) => <IssueTrackerUser iconOnly userId={row?.assignee} />, width: 90, viewWhenSideBySide: true },
        { name: "Reporter", key: "reporter", component: (row) => <IssueTrackerUser iconOnly userId={row?.reporter} />, width: 90 },
        { name: "Title", key: "title", viewWhenSideBySide: true },
        { name: "Status", key: "statusName", component: (row) => <StatusSelector readonly status={row?.status} />, viewWhenSideBySide: true },
        { name: "Category", key: "categoryName" },
        { name: "Priority", key: "priority", component: (row) => <PrioritySelector readonly priority={row?.priority} />, viewWhenSideBySide: true },
        { name: "Lot", key: "lotId", values: lots, component: (row) => <LotSelector lotName={row?.lotName} /> },
        { name: 'Asset', width: 200, key: 'assetLinks', component: (row) => <IssueTrackerLinks issue={row} showAssets /> },
        { name: 'Work order', width: 200, key: 'woLinks', component: (row) => <IssueTrackerLinks issue={row} showWorkorders /> },
        { name: 'Load', width: 200, key: 'loadLinks', component: (row) => <IssueTrackerLinks issue={row} showLoads /> },
        { name: 'Reservation', width: 200, key: 'ReservationLinks', component: (row) => <IssueTrackerLinks issue={row} showReservation /> },
        ...((typeof loggedInClientClaim === "string" && loggedInClientClaim !== "") ? [] : [{ name: "Client", key: "clientId", values: clients }]),
        !(!!loggedInClientClaim?.length) ? { name: "Sub Client", key: "clientAccountId", values: clientAccounts } : false,        
        { name: "Estimated Cost", key: "cost", component: (row) => FormatService.formatCurrency(row?.cost) },
        { name: "Reported Date", key: "createdDate", sortKey: 'createdDate', component: (row) => <IssueTrackerDateSelector issue={row} date={row?.createdDate} readonly field='createdDate' /> },
        { name: "Issue Date", key: "issueDate", sortKey: 'issueDate', component: (row) => <IssueTrackerDateSelector issue={row} date={row?.issueDate} readonly field='issueDate' /> },
        { name: "Completed Date", key: "completedDate", sortKey: 'completedDate', component: (row) => <IssueTrackerDateSelector issue={row} date={row?.completedDate} readonly field='completedDate' /> },
        { name: "Due Date", key: "solutionEta", sortKey: 'solutionEta', component: (row) => <IssueTrackerDateSelector issue={row} date={row?.solutionEta} readonly field='solutionEta' /> },
        { name: "Problem Description", key: "description", component: (row) => <IssueTrackerDescriptionField withTooltip readonly content={row?.description} /> },
        { name: "Solution Description", key: "solutionDescription", component: (row) => <IssueTrackerDescriptionField withTooltip readonly content={row?.solutionDescription} /> },
    ].filter(Boolean);

    // if (issuesTrackerToEdit) {
    //     issueTrackerColumns = issueTrackerColumns?.filter(col => col.viewWhenSideBySide)
    // }
    const yesNoValues = [{ id: 'yes', name: 'Yes' }, { id: 'no', name: 'No' }];

    let filterConfig = [
        ...((typeof loggedInClientClaim === "string" && loggedInClientClaim !== "") ? [] : [{ type: fieldTypes.TEXT, size: 2, title: 'Client', key: 'clientAccountId', values: clientAccounts }]),
        { type: fieldTypes.LIST, size: 2, title: 'Status', key: 'latestStatusId', values: statuses },
        { type: fieldTypes.TEXT, size: 2, title: 'Type', key: 'issueType', values: issueTrackerTypesList },
        { type: fieldTypes.VALUE_LIST, size: 2, title: 'Linked To', key: 'linkedToType', values: issueTrackerLinkEntityTypeList },
        { type: fieldTypes.VALUE_LIST, size: 2, title: 'Activity', key: 'activityItemIds', values: activityItems },
        { type: fieldTypes.LOT, size: 2, title: 'Lot', key: 'lotId', values: lots },
        { type: fieldTypes.TEXT, size: 2, title: 'Lot Type', key: 'lotType', values: LotTypeEnumList },
        { type: fieldTypes.VALUE_LIST, size: 2, title: 'Regional Manager', key: 'regionalManager', values: dropdownValues },
        { type: fieldTypes.TEXT, size: 2, title: 'Category', key: 'category', values: categories },
        { type: fieldTypes.LIST, size: 2, title: 'Priority', key: 'priority', values: priorities },
        { type: fieldTypes.DATERANGE, size: 2, title: 'Created Date', key: 'createdDate' },
        { type: fieldTypes.TEXT, size: 2, title: 'Assignee', key: 'assignee', values: users },
        { type: fieldTypes.TEXT, size: 2, title: 'Assigned', key: 'isAssigned', values: yesNoValues },
        { type: fieldTypes.TEXT, size: 2, title: 'Reporter', key: 'reporter', values: users },
        { type: fieldTypes.TEXT, size: 2, title: 'Open more than Two Weeks', key: 'openMoreThanTwoWeeksStr', values: yesNoValues },
        { type: fieldTypes.TEXT, size: 2, title: 'Is Complete', key: 'isCompleteStr', values: yesNoValues },
        { type: fieldTypes.DATERANGE, size: 2, title: 'Due Date', key: 'solutionEta' },
        { type: fieldTypes.TEXT, size: 2, title: 'Overdue', key: 'overdueStr', values: yesNoValues },
    ];

    const searchIssuesTracker = value => {
        dispatch(setSearchVal(value.trim()));
    };

    const onDropFilter = (value, prop) => {
        dispatch(setFilters({ ...filters, [prop]: { value } }));
    };



    const onResetFilters = () => {
        dispatch(resetFilters());
    };

    useEffect(() => {
        if (issuesTrackerToEdit) {
            let updatedIssue = issueLookup?.[issuesTrackerToEdit?.id];
            if (updatedIssue) {
                setIssuesTrackerToEdit(updatedIssue);
            };
        }
    }, [isLoadingIssuesTracker])

    const mapExportData = row => {
        const issues = {};
        issueTrackerColumns?.map(col => {
            if (col.name) {
                issues[col.name] = row?.[col.key]
            }
        });
        issues['Lot'] = row?.['lotName'];
        issues['Assignee'] = row?.['assigneeName'] ?? row?.['assignee'];
        issues['Reporter'] = row?.['reporterName'] ?? row?.['reporter'];
        issues['Priority'] = row?.['priorityName'];
        issues['Client'] = row?.['clientName'];
        issues['Sub Client'] = row?.['clientAccountName'];
        issues['Problem Description'] = FormatService.cleanHtmlTags(row?.['description']);
        issues['Solution Description'] = FormatService.cleanHtmlTags(row?.['solutionDescription']);
        issues['Asset'] = row?.linkedAssets?.map(a => a.descriptor || a.assetId)?.join(' ');
        issues['Work order'] = row?.linkedWorkOrders?.map(a => `${window.location.origin}/workorder/${a?.id}`).join('; ');
        issues['Load'] = row?.linkedLoads?.map(a => `${window.location.origin}/load/group/${a?.groupId}`).join('; ');
        issues['Reservation'] = row?.linkedReservations?.map(r => `${window.location.origin}/reservations/${r?.id}` ).join('; ');
        return issues;
    };

    const filteredIssuesTracker = FilterService.filter(filters, searchVal, issuesTracker, filterConfig);

    const getRowClassName = (rowIndex) => {
        if (filteredIssuesTracker?.[rowIndex]?.overdue) {
            return classes.overdue;
        }
        return '';
    }

    const content = <>
        <LoadingSpinner loading={isLoadingIssuesTracker} />
        {/* <Grid container direction="row" spacing={2} className={classes.dashboardWrapper}> */}
        {/* <IssueTrackerDashboard
                populateFilters={(filters) => {
                    populateMultipleFilters(filters);
                    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
                }}
                issues={issuesTracker}
                issuesTrackerToAdd={issuesTrackerToAdd}
                setIssueTrackerToAdd={setIssueTrackerToAdd}
            /> */}
        {/* <Grid item xs={12}> */}
        {/* <div style={{ minHeight: '700px' }}> */}
        {/* <PanelGroup autoSaveId="example" direction="horizontal">
                <Panel minSize={10} defaultSizePercentage={25} > */}
        <DataTable
            getRowClassName={getRowClassName}
            mapExportData={embeddedView ? false : mapExportData}
            noItemsMessage={isLoadingIssuesTracker ? '' : embeddedView ? 'There are no open tickets' : 'There are no active tickets'}
            tableId={userPreferencesComponentIds.ISSUE_TRACKER}
            allowColumnCustomization={embeddedView ? false : true}
            withFilterPanel={embeddedView ? false : true}
            dropDownsConfig={filterConfig}
            filters={filters}
            onDropFilter={onDropFilter}
            onSearch={embeddedView ? null : searchIssuesTracker}
            searchVal={searchVal}
            onResetFilters={onResetFilters}
            columns={issueTrackerColumns}
            rows={orderBy(
                filteredIssuesTracker,
                sortOptions.columnToSort,
                sortOptions.sortDirection
            )}
            rowIdentifier='id'
            onSort={sortData}
            sortDirection={sortOptions.sortDirection}
            columnToSort={sortOptions.columnToSort}
            title={<Grid container spacing={2}>
                <Grid item>Tickets ({filteredIssuesTracker?.length ?? 0})</Grid>
                <Grid item><IssueViewSelector /></Grid>
            </Grid>}
            onRowClick={onRowClick}
            actionButtons={
                <Grid container direction='row' spacing={1}>
                    <Grid item>
                        <WorkflowPageViewSelector />
                    </Grid >
                    <Grid item>
                        <CreateIssueMenu issuesTrackerToAdd={issuesTrackerToAdd} setIssueTrackerToAdd={setIssueTrackerToAdd} />
                    </Grid>
                </Grid>
            }
        />
        {/* </Panel> */}
        {/* {issuesTrackerToEdit && <>
                    <PanelResizeHandle className={classes.resizer} />
                    <Panel minSize={30}>
                        <Paper className={classes.rightSide}>
                            <IssueTrackerView
                                issueTracker={issuesTrackerToEdit}
                                onClose={() => setIssuesTrackerToEdit()}
                            />
                        </Paper>
                    </Panel></>} */}
        {/* </PanelGroup>
        </div> */}
        {issuesTrackerToEdit && <IssueTrackerView
            issueTracker={issuesTrackerToEdit}
            withDialog
            onClose={() => setIssuesTrackerToEdit()}
        />}
    </>

    return (
        <Authorize profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_VIEW}>
            {!embeddedView ? <PageComponent>
                {content}
            </PageComponent >
                : <>{content}</>
            }
        </Authorize >
    );
};

export default IssuesTrackerList;