import { assetsPermissionProfiles } from "./permissionProfiles/assetsPermissionProfile"
import { clientConfigPermissionProfile } from "./permissionProfiles/clientConfigPermissionProfile"
import { dashboardPermissionProfiles } from "./permissionProfiles/dashboardPermissionProfiles"
import { loadPermissionProfiles } from "./permissionProfiles/loadPermissionProfiles"
import { lotPermissionProfiles } from "./permissionProfiles/lotPermissionProfile"
import { SubscriptionPermissionProfiles } from "./permissionProfiles/subscriptionPermissionProfiles"
import { tasksPermissionProfile } from "./permissionProfiles/tasksPermissionProfile"
import { userPermissionProfiles } from "./permissionProfiles/userPermissionProfiles"
import { transporterPermissionProfiles } from "./permissionProfiles/transporterPermissionProfiles"
import { invoicePermissionProfile } from "./permissionProfiles/invoicePermissionProfile"
import { inspectionPermissionProfile } from "./permissionProfiles/inspectionPermissionProfile"
import { auditPermissionProfile } from "./permissionProfiles/auditPermissionProfile"
import { issueTrackerPermissionProfile } from "./permissionProfiles/issueTrackerPermissionProfile"
import { activityPermissionProfile } from "./permissionProfiles/activityPermissionProfile"
import { mechanicalsPermissionProfile } from "./permissionProfiles/mechanicalsPermissionProfile"
import { conditionReportPermissionProfile } from "./permissionProfiles/conditionReportPermissionProfile"
import { mobilePermissionProfiles } from "./permissionProfiles/mobilePermissionProfiles"
import { notesPermissionProfiles } from "./permissionProfiles/notesPermissionProfiles"
import { pricingPermissionProfile } from "./permissionProfiles/pricingPermissionProfile"
import { mechanicalTaskPermissionProfiles } from "./permissionProfiles/mechanicalTaskPermissionProfiles";
import { accessPermissionProfile } from "./permissionProfiles/accessPermissionProfile"
import { inventoryPermissionProfile } from "./permissionProfiles/inventoryPermissionProfile"
import { formPermissionProfiles } from "./permissionProfiles/formPermissionProfiles"
import { reportsPermissionProfile } from "./permissionProfiles/reportsPermissionProfile"
import { reportingPermissionProfile } from "./permissionProfiles/reportingPermissionProfile"
import {userManagementPermissionProfiles} from "./permissionProfiles/userManagementPermissionProfiles";
import { taxRatePermissionProfile } from "./permissionProfiles/taxRatePermissionProfile"
import { reservationPermissionProfile } from "./permissionProfiles/reservationPermissionProfile"
import { carrierPermissionProfile } from "./permissionProfiles/carrierPermissionProfile"


export const permissionProfiles = {
    ASSETS: { ...assetsPermissionProfiles },
    LOAD: { ...loadPermissionProfiles },
    SUBSCRIPTION: { ...SubscriptionPermissionProfiles },
    USER: { ...userPermissionProfiles },
    USER_MANAGEMENT: { ...userManagementPermissionProfiles },
    LOT: { ...lotPermissionProfiles },
    CLIENT_CONFIG: { ...clientConfigPermissionProfile },
    TASKS: { ...tasksPermissionProfile },
    DASHBOARD: { ...dashboardPermissionProfiles },
    TRANSPORTER: { ...transporterPermissionProfiles },
    INVOICE: { ...invoicePermissionProfile },
    INSPECTION: { ...inspectionPermissionProfile },
    AUDIT: { ...auditPermissionProfile },
    ISSUETRACKER: { ...issueTrackerPermissionProfile },
    ACTIVITY: { ...activityPermissionProfile },
    MECHANICALS_CONFIG: { ...mechanicalsPermissionProfile },
    MECHANICALS_TASKS: { ...mechanicalTaskPermissionProfiles },
    CONDITION_REPORT: { ...conditionReportPermissionProfile },
    MOBILE: { ...mobilePermissionProfiles },
    NOTES: { ...notesPermissionProfiles },
    PRICING: { ...pricingPermissionProfile },
    ACCESS: { ...accessPermissionProfile },
    INVENTORY: { ...inventoryPermissionProfile},
    FORMS: { ...formPermissionProfiles},
    REPORTS: { ...reportsPermissionProfile },
    REPORTING: { ...reportingPermissionProfile },
    TAX_RATE: { ...taxRatePermissionProfile },
    RESERVATIONS: { ...reservationPermissionProfile },
    CARRIERS: { ...carrierPermissionProfile }
}