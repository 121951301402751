import { Button, Grid } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import NotificationProvider from 'components/NotificationProvider';
import { reservationsStatuses } from "../reservationConsts";
import { useSendDocumentationRequestMutation } from '../reservationSlice';
import { DocumentationRequestStyle } from "./style";

export const DocumentationRequest = ({ reservation }) => {
    const classes = DocumentationRequestStyle();

    const [sendDocumentationRequest, { isLoading: isSendingRequest }] = useSendDocumentationRequestMutation();

    const handleDocumentationRequest = async () => {
        let res = await sendDocumentationRequest({ reservationId: reservation?.id } ?? 0);
        if (!res?.error) {
            NotificationProvider.success('Documentation request has been sent successfully');
        } else {
            NotificationProvider.error('Failed to send request');
        }
    }

    const pocEmails = reservation?.carrierInfo?.pocInfo?.map(p => p.email);

    return (
        <>
            {reservation.status == reservationsStatuses.WaitingDocuments &&
                <Authorize profile={permissionProfiles.RESERVATIONS.EDIT}>
                    <Grid item className={classes.button}>
                        <Button
                            color='primary'
                            variant="outlined"
                            onClick={handleDocumentationRequest}
                            disabled={!(!!pocEmails?.length) || isSendingRequest}
                        >
                            Send Documentation Request
                        </Button>
                    </Grid>
                </Authorize>
            }
        </>
    )
}